@import "~@/assets/sass/_function";

.BookSelect-MhlMtN99 {
  .BookSelect-title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #3a3446;
      line-height: 28px;
    }
    .chooseNum {
      color: #3a3446;
      font-size: 14px;
    }
  }
  .bookListContent {
    display: flex;
    align-items: flex-end;
    .bookList {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      .bookItem {
        display: flex;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .bookName {
          width: 642px;
          height: 32px;
          background-color: #ffffff;
          border-radius: 4px;
          border: solid 1px #d9d9d9;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
          text-align: center;
          line-height: 30px;
          @include ellipsis-lines(1);
        }
        .bookDelete {
          display: flex;
          align-items: center;
          cursor: pointer;
          .ic-shanchu {
            color: #4e50b8;
            font-size: 14px;
            margin-right: 5px;
          }
          .delete {
            color: #4e50b8;
            text-decoration: underline;
          }
        }
      }
    }
    .bookAdd {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 32px;
      .ic-jiahao {
        color: #4e50b8;
        font-size: 14px;
        margin-right: 5px;
        background-color: #fff;
      }
      span {
        color: #4e50b8;
        text-decoration: underline;
      }
    }
  }
  .foldBtn {
    display: flex;
    color: #4e50b8;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;
    .wtdc-icon {
      font-size: 14px;
      margin-left: 6px;
      color: #4e50b8;
    }
  }
}
