@import "~@/assets/sass/_function";

.BookCategory {
  display: flex;
  font-family: PingFangSC-Regular, PingFang SC;
  margin-bottom: 30px;
  .category-title {
    font-size: 15px;
    font-weight: 400;
    color: #3a3346;
    flex-shrink: 0;
  }
  .category-content {
    flex-grow: 1;
    &-tips {
      font-size: 14px;
      font-weight: 400;
      color: #80798d;
      margin-bottom: 12px;
    }
    &-input {
      width: 349px;
      height: 31px;
      border-radius: 4px;
      border: 1px solid #dddddd;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      color: #b3b0b9;
      &-img {
        width: 11px;
        height: 6px;
      }
    }
    &-select {
      display: inline-flex;
      align-items: center;
      width: 100%;
      height: 406px;
      margin-top: 10px;
      border-radius: 4px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      .select-class {
        display: flex;
        flex-direction: column;
        flex: 1;
        flex-shrink: 0;
        align-self: stretch;
        border-right: 1px solid $grayE;

        &-title {
          padding: 5px 20px;
          margin-bottom: 8px;
          color: #a7a7af;
          background: rgba(240, 242, 245, 0.6);
        }
        &-list {
          //padding: 0 20px;
          flex: 1;
        }
        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 15px 8px 20px;
          white-space: nowrap;
          cursor: pointer;

          &-label {
            flex: 1;
            word-break: break-word;
            white-space: normal;
          }

          .ic-right {
            margin-left: 5px;
            font-size: 10px;
          }

          &:hover {
            color: $colorPrimary;
            //background-color: rgba($colorPrimary, 0.2);
          }
        }
        &-item__active {
          color: $colorPrimary;
        }
        &-empty {
          //position: absolute;
          padding: 8px 20px;
          color: $gray9;
        }
      }
    }
  }
}
