@import "~@/assets/sass/_function";

.ServiceType {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  position: relative;
  .btn {
    border-radius: 4px;
  }
  .service-type {
    &-box {
      width: 350px;
      height: 31px;
      border-radius: 4px;
      border: 1px solid #dddddd;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      cursor: pointer;
      &-placeholder {
        color: #999999;
      }
      &-list {
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-right: 10px;
        &-item {
          flex-shrink: 0;
          span {
            &::after {
              content: "，";
            }
            &:last-child {
              &::after {
                content: "";
              }
            }
          }
        }
      }
      &-img {
        width: 11px;
        height: 6px;
      }
    }
    &-modal {
      position: absolute;
      left: 0;
      top: 35px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      z-index: 100;
      height: 0;
      transition: all 0.3s;
      &.show {
        height: 285px;
      }
      .service-modal-head {
        height: 50px;
        background: #f6f5f9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        .reset-btn {
          color: #4e50b8;
          cursor: pointer;
        }
      }
      .service-modal-content {
        height: 235px;
        padding: 20px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          background-color: #fff;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #ccc;
          border-radius: 5px;
        }
        .ant-checkbox-group {
          .ant-checkbox-group-item {
            min-width: 120px;
          }
        }
        .ant-radio-group {
          .ant-radio-wrapper {
            min-width: 120px;
          }
        }
      }
    }
  }
}
