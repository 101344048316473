@import "~@/assets/sass/_function";
.TimeTable {
  background-color: red;
  .wtdc-form-item-children-wrapper {
    width: 320px;
    .wtdc-calendar-picker {
      width: 320px !important;
    }
  }
  .wtdc-form-item:nth-child(4),
  .wtdc-form-item:nth-child(10) {
    .wtdc-form-item-label {
      padding-right: 5px;
      .wtdc-form-item-label-text {
        width: 74px;
        display: inline-block;
        line-height: 17px;
      }
    }
  }
  .wtdc-form-item:nth-child(2),
  .wtdc-form-item:nth-child(3) {
    display: inline-block;
  }
  .wtdc-form-item:nth-child(2) {
    margin-right: 20px;
  }
  .wtdc-form-button {
    margin-left: 80px;
  }
}
