@import "~@/assets/sass/_function";

.ManageList-IsC2strr {
  height: 100%;
  .th-example {
    width: 30%;
    min-width: 350px;
  }
  .th-bookName {
    width: 20%;
    min-width: 150px;
  }
  .th-exampleLabel {
    min-width: 90px;
  }
  .th-createUser {
    min-width: 90px;
  }
  .td-example {
    position: relative;
  }
  .resource-info {
    display: flex;
    align-items: center;
    .wtdc-icon {
      margin-right: 14px;
      font-size: 36px;
    }
    .resource-name {
      color: #3a3446;
      font-weight: bold;
    }
  }
  .th-createTime {
    min-width: 150px;
  }
  .label-box {
    width: 160px;
    // padding: 6px;
  }
  .label-box:hover {
    background-color: rgba(78, 80, 184, 0.1);
  }
  .ErpNumber-n3Oqk6N {
    .book--isRedTask {
      left: 10px;
    }
  }
}

.SearchBar-lO454_F {
  .search-group {
    width: calc(100% - 125px);
  }
}
.selector-drop-down {
  left: 75px;
}
