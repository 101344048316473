@import "~@/assets/sass/_function";

.Bookscatter-iUKQoFNN {
  height: 100%;
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  > .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 25px;
  }
  .scatterInfoLess {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 200px;
    span {
      font-size: 16px;
      position: relative;
    }
  }
  .Bookscatter-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .analysisRes {
    width: 100%;
    background-color: rgba(78, 80, 184, 0.1);
    border-radius: 2px;
    border: solid 1px rgba(78, 80, 184, 0.8);
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
    .title {
      color: #4e50b8;
      font-weight: bold;
      white-space: nowrap;
      line-height: 50px;
    }
  }
  .scatterChart {
    flex: 1;
  }
}
