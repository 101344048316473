@import "~@/assets/sass/_function";

.PhonePreview {
  position: relative;
  &-phone {
    width: 216px;
    height: 440px;
    &:before {
      content: "";
      background: url(https://oss.5rs.me/oss/uploadfe/png/ad14b2b21c22f2770f46740ccb22de37.png) no-repeat 0 0/ 100% 100%;
      height: 100%;
      position: absolute;
      width: 216px;
      top: -0;
      left: 0;
      z-index: 1;
      pointer-events: none;
    }
    .close-btn {
      display: none;
      width: 20px;
      height: 20px;
      background: url('https://oss.5rs.me/oss/uploadfe/png/eff7bbbfdb3bc2f52803706b0c2daf89.png') no-repeat 0 0/ 100% 100%;

    }
  }
  &-screen {
    //background-color: $bg-color;
    position: relative;
    border-radius: 0 0 25px 25px;
    width: 100%;
    height: 100%;
    background: rgb(242, 242, 242);
    border-radius: 30px;
    //overflow: hidden;
    .bar {
      padding: 0 5px;
      display: flex;
      justify-content: space-between;
      .time {
        font-size: 12px;
        font-weight: 600;
      }
      .icon .ic {
        font-size: 12px;
        margin-right: 2px;
      }
    }
    .preview-image {
      width: 100%;
      height: 100%;
    }
    .no-image {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-content {
    padding: 9px 8px;
    height: 100%;
  }
  &.dark {
    .PhonePreview {
      &-phone {
        &:before {
          background-image: url(https://oss.5rs.me/oss/uploadfe/png/0cc894890cc08663d56f1b07659e562c.png);
        }
      }
    }
  }
  &.fullScreen {
    .PhonePreview {
      &-layer {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 100;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.4);
      }
      &-phone {
        position: fixed;
        transform: translate(-50%, -50%);
        width: 325px;
        height: 655px;
        z-index: 100;
        left: 50%;
        top: 50%;
        padding: 0;
        &:before {
          width: 100%;
          top: 0px;
          left: 0px;
        }
        .close-btn {
          display: block;
          position: absolute;
          top: -20px;
          right: -32px;
          font-size: 24px;
          color: $white;
          cursor: pointer;
          width: 20px;
          height: 20px;
          background: url('https://oss.5rs.me/oss/uploadfe/png/eff7bbbfdb3bc2f52803706b0c2daf89.png') no-repeat 0 0/ 100% 100%;

        }
      }
      &-screen {
        border-radius: 60px;
      }
      &-content {
        border-radius: 60px;
        padding: 30px 12px 12px 15px;
        &.IMAGE {
          padding-top: 12px;
          overflow: hidden;
        }
      }
    }
  }
}
