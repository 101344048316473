@import "~@/assets/sass/_function";

.ImportTips-56V2Glp {
  .dengpaotishi {
    display: block;
    font-size: 65px;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin: 30px auto 0;
  }
  .tips {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    line-height: 1.5;
    text-align: center;
    margin: 30px 0 20px;
  }
  .downloadContent {
    display: flex;
    cursor: pointer;
    color: #4e50b8;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    .xiazai {
      color: #4e50b8;
      font-size: 13px;
      margin-right: 8px;
    }
    .book-example {
      text-decoration: underline;
      font-size: 14px;
    }
  }
}
