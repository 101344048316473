.newQualityItems {
  font-size: 15px;
  .qualityLevel {
    display: flex;
    align-items: center;
    height: 50px;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 10;
    .required-tip {
      color: red;
    }
    .required-res,
    .quality-score {
      margin-left: 10px;
    }
    .qualityLevel-btn {
      margin-left: 10px;
      color: #4e50b8;
      cursor: pointer;
    }
  }
  .newQualityList {
    margin-top: 10px;
    padding: 10px;
    background-color: rgba(63, 81, 181, 0.03);
    h4 {
      margin-bottom: 10px;
      font-weight: bold;
    }
    .qualityItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      padding: 5px 0;
      &-item {
        margin-right: 40px;
        width: 400px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .fit {
          margin-left: 5px;
          flex-shrink: 0;
        }
      }
      .wtdc-form-item {
        height: 52px;
        margin-left: 80px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }
      &:last-child {
        border: none;
      }
    }
  }
}
