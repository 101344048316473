@import "~@/assets/sass/_function";

.ClientPreview {
  cursor: auto;
  .close-model {
    position: fixed;
    top: -5px;
    right: -60px;
    width: 29px;
    height: 30px;
    border: 2px solid #f8f8f8;
    cursor: pointer;
    border-radius: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 23px;
    color: #f8f8f8;
    .ic-close {
      font-size: 14px;
      position: absolute;
      @include center-translate;
    }
  }
  .Iphone {
    .iphone-main {
      width: 340px;
      padding-top: 70px;
      .iphone-content {
        height: auto;
        .media-content {
          padding: 0;
          .preview-tip {
            @include center-translate(both);
            width: 128px;
            font-size: 16px;
            text-align: center;
            line-height: 24px;
            color: $black;
          }
        }
      }
      .center {
        line-height: 1;
      }
    }
  }
}
