@import "~@/assets/sass/_function";
.TemplateManage {
  height: 100%;
  .btn {
    border-radius: 4px;
    &-ic {
      font-size: 15px;
    }
  }
  .Empty-box {
    padding-top: 200px;
  }
  &-minH {
    min-height: 800px;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
  }
  &-box {
    height: calc(100% - 52px);
  }
}
