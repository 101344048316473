@import "~@/assets/sass/_function";

.UploadOk {
  height: 100%;
  padding-top: 10px;
  .upload-head {
    text-align: center;
    margin-bottom: 45px;
    &-img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    &-tips {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .upload-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-text {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .ant-btn {
      display: flex;
      align-items: center;
      border-radius: 4px;
    }
  }
  .upload-content {
    min-height: 58vh;
    &-table {
      table tr th.ant-table-selection-column,
      table tr td.ant-table-selection-column {
        padding-left: 30px;
      }
    }
  }
  .upload-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0px -2px 18px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    position: sticky;
    left: 0;
    bottom: 0;
    &-btn {
      width: 130px;
      border-radius: 4px;
    }
  }
}
