@import "~@/assets/sass/_function";

.BookEdition {
  display: flex;
  .edition-title {
    font-size: 15px;
    font-weight: 400;
    color: #3a3346;
    flex-shrink: 0;
  }
  .edition-content {
    flex-grow: 1;
    &-tips {
      font-size: 14px;
      font-weight: 400;
      color: #80798d;
      margin-bottom: 12px;
    }
    &-select {
      display: flex;
      align-items: center;
    }
  }
  .edition-select {
    flex: 1;
    margin-left: 10px;
    margin-right: 20px;
    .label {
      width: auto;
      min-width: 56px;
      margin-right: 10px;
      border: none;
      background-color: transparent;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .wtdc-select {
      width: 0.1vw;
    }
    .ant-select-arrow {
      span {
        width: 11px;
        height: 11px;
        background: url("https://oss.5rs.me/oss/uploadfe/png/e38190ab5fba873497c0f3d786470086.png") no-repeat center
          center;
        background-size: 100% auto;
        svg {
          display: none;
        }
      }
    }
    &.ant-select-open {
      .ant-select-arrow {
        span {
          svg {
            display: block;
          }
        }
      }
    }
  }
}
