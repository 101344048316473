@import "~@/assets/sass/_function";

.ExportData-Qu49nlQ {
  .export-btn {
    .wtdc-icon-daoru1 {
      transform: rotate(180deg);
      line-height: 38px;
    }
  }
}

.ExportLayer-SSV6s8S {
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
  .form-wrap {
    padding: 10px 0 100px 37px;
  }
  .wtdc-form-item-children-wrapper {
    display: inline-block;
  }
  .wtdc-form-item-label {
    margin-right: 20px;
  }
  .wtdc-form-item {
    margin-bottom: 30px;
  }
  .Tooltip {
    .wtdc-icon {
      color: #b3b0b9 !important;
      &:hover {
        color: #3f51b5 !important;
      }
    }
  }
}

.Tooltip-wttetjs {
  z-index: 10;
}
