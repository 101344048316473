@import "~@/assets/sass/_function";

.InspectOrder-5AXnnC {
  padding: 24px;
  .bread-back {
    width: 71px;
    height: 32px;
    background: #f6f5f9;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-icon {
      transform: rotateZ(90deg);
      transform-origin: 50% 50%;
      position: relative;
    }
  }
  .inspect-table {
    width: 100%;
    border: 1px solid #000;
  }

  .underline {
    color: $colorPrimary;
    cursor: pointer;
    text-decoration: underline;
  }

  .border-none {
    border: transparent;
  }

  td {
    border: 1px solid #000;
    background-color: #fff;
  }

  .commit-letter {
    padding: 5px;
    text-align: left;
    &-title {
      line-height: 30px;
      font-size: 16px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }
    &-list {
      margin-bottom: 30px;
      font-size: 14px;
      li {
        margin: 5px 0;
      }
    }
    &-context {
      font-size: 14px;
      text-indent: 28px;
    }
  }

  .wtdc-form-item-error-message {
    display: none;
  }

  .wtdc-form-item {
    height: 54px;
    margin: 0;
    .wtdc-form-item-label {
      display: none;
    }
    .wtdc-form-item-children-wrapper,
    .wtdc-input,
    .wtdc-input-number-input-wrap,
    .wtdc-input-number,
    .wtdc-calendar-picker,
    .wtdc-select {
      width: 100% !important;
      height: 100%;
    }
    &.date-mt {
      .wtdc-form-item-children-wrapper {
        padding-top: 13px;
      }
    }

    .wtdc-calendar-input {
      height: 54px;
    }
    .wtdc-select-selection-item,
    .wtdc-radio-group,
    .wtdc-select-selection-placeholder {
      line-height: 54px;
    }
    .wtdc-input-number-handler-wrap {
      display: none;
      .wtdc-input-number {
        border-color: transparent !important;
        background-color: transparent !important;
      }
    }
    .wtdc-input-affix-wrapper,
    .wtdc-input-number,
    .wtdc-select-selector,
    .wtdc-select-disabled,
    .wtdc-input-disabled {
      border-color: transparent !important;
      background-color: transparent !important;
    }
    .wtdc-radio-disabled {
      &.wtdc-radio-checked {
        .wtdc-radio-symbol {
          background-color: #4e50b8 !important;
        }
      }
    }
    input {
      height: 100%;
      width: 100%;
      border: transparent;
      box-shadow: none;
      text-align: center;
      background-color: transparent;
    }
  }

  .themBg {
    color: $gray3;
    text-align: center;
    //line-height: 54px;
    background: rgba($colorPrimary, 0.6);
  }

  .inspect-title {
    color: white;
    background: $colorPrimary;
    font-size: 30px;
  }

  .inspect-subtitle {
    height: 54px;
    color: $white;
    background: rgba($colorPrimary, 0.6);
    font-size: 23px;
  }

  .cell-item {
    padding: 0 20px;
    line-height: normal;
    text-align: left;
  }

  .padding-item {
    padding: 15px 20px;
  }

  .text-left {
    text-align: left;
  }
  .explanation-input {
    display: inline-flex;
    max-width: 20%;
    vertical-align: middle;
    border-bottom: 1px solid $gray3;
    .wtdc-calendar-picker-icon {
      visibility: hidden;
    }
    &.count-input {
      width: 10%;
    }
    .input-item,
    .wtdc-calendar-input {
      height: 27px;
    }
  }

  .big-difference {
    height: auto;
  }

  .attach-file {
    pointer-events: auto;
    cursor: pointer;
  }

  .supplement-input {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    margin: 0 12px;
    text-align: center;
    border-bottom: 1px solid $gray3;
  }
  .update-file-box {
    padding-top: 20px;
    display: flex;
    align-items: center;
    .ResourcePickerDialog {
      width: 370px;
      .upload-flie__content {
        padding: 10px;
        height: 100px;
        .wtdc-icon {
          display: none;
        }
      }
    }
  }
}
