.showMessage-9SmA999 {
  padding: 30px 50px;
  overflow: hidden;
  font-size: 16px;
  .ant-table-thead {
    .ant-table-cell {
      font-weight: bolder;
    }
  }
}
