@import "~@/assets/sass/_function";

.ChangeMarketer-TGq7G7q {
  // .tips {
  //   padding-left: 120px;
  //   margin-top: 10px;
  //   color: #80798d;
  // }
  .modal-content {
    padding: 20px;
    height: 218px;
    .wtdc-form-item {
      text-align: center;
      .wtdc-form-item-children-wrapper {
        text-align: left;
        margin-left: 20px;
      }
    }
    .page-block-tips {
      white-space: nowrap;
      width: 480px;
      padding: 7px 10px;
      margin-bottom: 50px;
      background-color: rgba(78, 80, 184, 0.1);
      border-radius: 4px;
      .ic-tishi {
        margin-right: 8px;
        color: #4e50b8;
        font-size: 16px;
      }
    }
  }
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }

  // .wtdc-form-item-label {
  //   padding-right: 20px;
  // }
  // .modal-content-scroll {
  //   overflow: initial !important;
  // }
  // .modal-content-scroll > div:first-child {
  //   overflow: initial !important;
  // }
}
