@import "~@/assets/sass/_function";

.repeatConfirmModal {
  .modal-content {
    padding-top: 20px;
    .confirm-modal-content-icon,
    .confirm-modal-subtitle {
      display: none;
    }
    .confirm-modal-content {
      .ant-alert-message,
      .ant-alert-description {
        font-size: 14px;
        text-align: left;
        .highlight {
          color: blue;
        }
      }
      .error-color {
        color: red;
      }
    }
  }
  .SearchTable {
    .wtdc-antd-table {
      padding-right: 0;
      .ant-table-container::after {
        box-shadow: none;
      }
      .ant-table-body {
        overflow: visible !important;
        .diff {
          color: red;
        }
      }
    }
  }
  .icon_rays7 {
    width: fit-content;
    background-color: rgba(78, 80, 184, 0.1);
    border-radius: 2px;
    font-size: 12px;
    color: #4e50b8;
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
  }
}
.wtdc-modal-confirm {
  .confirm-modal-subtitle {
    font-size: 18px;
    font-weight: 700;
  }
}
