@import "~@/assets/sass/_function";

.BatchUploadFile-TGq7G7q {
  .upload {
    margin-bottom: 30px;
    min-height: 65px;
    display: flex;
    &__title {
      font-size: 15px;
      color: #3a3346;
      margin-right: 20px;
      display: inline-block;
      vertical-align: top;
      width: 75px;
    }
    .ResourcePickerDialog-lwUXrNw{
      height: auto;
    }
    &:first-child {
      height: auto;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .modal-content {
    padding: 30px;
    height: 635px;
    .type-select {
      .wtdc-form-item-children-wrapper {
        margin-left: -20px;
      }
      &.disableSampleRadio {
        pointer-events: none;
        cursor: default;
        opacity: 0.6;
      }
    }
  }
  .change-modal {
    // height:100%;
    // overflow: auto;
    padding-bottom: 30px;
  }
  .wtdc-form-item-label {
    padding-right: 20px;
  }
  // .modal-content-scroll {
  //   overflow: initial !important;
  // }
  // .modal-content-scroll > div:first-child {
  //   overflow: initial !important;
  // }
}
