@import "~@/assets/sass/_function";

.ManageList-CommonFilter {
  margin-left: 20px;
}
.warnTypeClose {
  .wtdc-select-clear {
    position: absolute;
    right: 28px;
    top: 6px;
  }
}
.ManageList-5oZsuHH {
  .underline {
    text-decoration: underline;
  }

  .search-wrapper-left {
    flex-wrap: wrap;
    .wtdc-form-item {
      margin-bottom: 20px;
    }

    .project-status {
      .status-form {
        display: flex;
        &__content {
          width: 200px;
        }
        &__title {
          width: 70px;
          line-height: 32px;
        }
      }
    }
  }
  .search-right-box {
    justify-content: center;
    align-items: start;
    .ManageList-CommonFilter {
      margin-top: 5px;
    }
    .FieldSelect-AfaRN6cc {
      margin-top: 5px;
    }
    .view-port {
      .wtdc-icon {
        line-height: 32px;
      }
    }
  }

  .table-time {
    color: #3a3446;
    background: none;
    margin-bottom: 6px;
  }

  .edit-block {
    margin-left: -6px;
    margin-top: 5px;
    max-width: 200px;
  }

  .th-projectCustomLabel {
    min-width: 150px;
  }

  .td-erpNumber {
    min-width: 230px;
  }

  .td-projectCustomLabel {
    .label-box {
      width: 160px;
      // padding: 6px;
    }

    .label-box:hover {
      background-color: rgba(78, 80, 184, 0.1);
    }
  }

  .td-priorityLeve,
  .th-priorityLevel {
    width: 120px !important;
  }

  .td-toProjectTime {
    white-space: nowrap;
  }

  .td-manageInfo {
    width: 15%;
    min-width: 200px;

    > div > div {
      display: flex;
      align-items: baseline;

      .info-name {
        white-space: nowrap;
      }
    }
  }

  .td-erpNumber {
    position: relative;
  }

  .setTime {
    position: relative;
    z-index: 9;
  }

  .th-orderSheetCreateTime {
    .th-container {
      min-width: 90px;
    }
  }

  .td:last-of-type {
    width: 1px !important;
    white-space: nowrap;
    text-align: left;
  }

  .tips-btn {
    font-size: 14px;
    color: #b3b0b9;
    margin-left: 5px;
    cursor: pointer;
    margin-top: -2px;

    &:hover {
      color: rgb(78, 80, 183);
    }
  }

  .ImportOrderSheet-Qu49nlQ {
    .import-btn {
      margin-left: 16px;
    }
  }

  table {
    thead {
      background-color: #f6f5f9;

      th {
        color: $text-color-dark;
        font-weight: bold !important;
        padding-left: 5px;
        &:first-of-type {
          padding-left: 10px;
          margin: 0;
        }

        &:last-of-type {
          padding-left: 11px;
        }
      }
    }

    .selector-operations-wrapper {
      padding-left: 0 !important;
      // left: 40px !important;
    }

    &.selector-th .wtdc-checkbox {
      margin: 0;
    }

    .batchSwitch {
      width: initial;

      .selector-operations {
        padding-right: 30px !important;
      }

      .selector-operation-button {
        white-space: nowrap;
      }
    }
  }

  colgroup {
    .selector-col {
      width: 50px;
      min-width: 50px;
    }
  }

  tbody {
    tr {
      transition: all 0.5s;

      &:hover {
        background-color: rgba(78, 80, 184, 0.05);
      }
    }

    td {
      padding: 10px 5px;

      &:first-of-type {
        padding: 15px 10px 15px 10px;
      }

      &:last-of-type {
        padding-right: 30px;
      }
    }
  }
}

.tooltip-dT0MfPG {
  .tool-tip-inner {
    padding: 20px;
  }

  .more-btn {
    cursor: pointer;
    font-size: 14px;
    color: #666;

    &:hover {
      color: #3f51b5;
    }

    &:nth-child(2) {
      margin-top: 10px;
    }

    &:nth-child(3) {
      margin-top: 10px;
    }

    &:nth-child(4) {
      margin-top: 10px;
    }
  }
}

.modify-modal_SMhS22UU {
  .wtdc-form-item-error-message {
    visibility: hidden;
  }
}

.SearchBar-geehzVa {
  position: relative;

  .isBeaconBook {
    .wtdc-icon-dengpao {
      color: #fea246;
      font-size: 14px;
      position: relative;
      top: -1px;
      margin-right: 3px;
    }
  }

  .search-group {
    width: 100%;
    .book-head {
      display: flex;
      align-items: center;
      height: 32px;
      padding-left: 10px;
      border-radius: 6px;
      background-color: rgba(#4e50b8, 0.1);

      .ic-close {
        line-height: 1;
        margin-top: 2px;
      }

      &-item {
        cursor: pointer;
      }

      &--last {
        position: relative;
        margin-left: 12px;
        padding-left: 12px;

        &::before {
          position: absolute;
          content: "";
          width: 1px;
          height: 50%;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          background-color: rgba(#4e50b8, 0.5);
        }
      }
    }

    .selectedSeries-content {
      margin-bottom: 20px;

      .series-title {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          color: #4e50b8;
        }
      }

      .groupName {
        // color: #4e50b8;
        margin-left: 5px;
      }
    }

    .search-wrapper {
      display: flex;

      &-left {
        flex: 1;
      }
      .select-wrapper {
        display: inline-block;
      }
    }

    .export-btn {
      background-color: #fff;
      border: 1px solid #ddd;

      &:hover {
        color: #4e50b8;
        background-color: #fff;
        border-color: #4e50b8;
      }
    }
  }

  .view-port {
    .wtdc-icon-yanjing3 {
      position: relative;
      top: 2px;
    }
    .view-type-flex {
      line-height: 25px;
      .tips-btn {
        display: none;
      }
    }
  }
}

.modify-modal_SMhS22UU {
  margin-top: 24px;

  .wtdc-form-item-label {
    margin-right: 10px;
  }

  .level-item {
    display: inline-flex;
    color: white;
    border-radius: 2px;
    width: 120px;
    height: 30px;
    padding: 0 10px 0 15px;
    transition: width 0.1s;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .ic-gou {
      display: none;
    }

    &:first-child {
      color: #3a3446;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      width: 130px;
    }

    &.active {
      width: 150px;

      .ic-gou {
        display: block;
      }
    }
  }
}

.modify-modal_SMhS22UU {
  .modify-modal_save-btn {
    margin-left: 100px !important;
  }
}

.filter-end {
  display: inline-block;
  background-color: #f6f6fc;
  border-radius: 15px;
  padding: 4px 11px 4px 10px;

  .filter-num {
    color: #f44e23;
  }

  .wtdc-icon {
    font-size: 11px;
    margin-left: 6px;
    vertical-align: baseline;
  }
}

.show {
  display: block;
}

.export-box {
  position: relative;

  .order-btn {
    position: relative;
    z-index: 1;
    background-color: white;
    border-color: #ddd;
    color: #3a3446;

    &:hover {
      color: #4e50b8;
      border-color: #4e50b8;
    }

    &.active {
      color: #4e50b8;
      border-color: #4e50b8;
    }
  }

  .wtdc-trigger {
    width: 138px;
    position: absolute;
    top: 32px;
  }

  .export-btn {
    position: absolute;
    top: 10px;
    z-index: 9;
    width: 138px;
    background-color: #ffffff;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 20px;
    color: #3a3446;

    li {
      cursor: pointer;
      height: 20px;
    }

    li:hover {
      color: #4e50b8;
    }
  }

  .export-btn.fold {
    transform: scale(0);
  }
}

.disabledDeletRedTask {
  .confirm-modal-subtitle {
    font-weight: bold;
    color: #333;
    font-size: 18px;
    margin-top: 18px;
  }

  .tips {
    display: flex;
    justify-content: center;
    align-items: center;

    .wtdc-icon {
      margin-right: 8px;
      color: red;
    }
  }
}

.deleteRedTask {
  .confirm-modal-subtitle {
    font-weight: bold;
    color: #333;
    font-size: 18px;
    margin-top: 18px;
  }
}

.table-operation-btn-group {
  min-width: 110px;
  .table-sync-btn {
    color: #4e50b8;
    cursor: pointer;
  }
  .table-operation-btn {
    position: relative;
    color: $primary-color;
    background: none;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 0 6px;
    cursor: pointer;
    transition: all 0.3s;

    > .Link {
      color: $primary-color;

      &:hover {
        color: darken($primary-color, 8%);
      }
    }

    &:hover {
      color: darken($primary-color, 8%);
    }

    &:last-of-type:after {
      content: none;
    }
  }
}
.after-line {
  &:after {
    content: "" !important;
    position: absolute;
    display: inline-block;
    width: 1px;
    height: 16px;
    background-color: #4e50b8;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.book-head {
  &-tip {
    &-title {
      font-weight: bold;
    }
  }
}

.icon-svg {
  svg {
    margin-bottom: -3px;
  }
}
.taskTip {
  display: flex;
  align-items: center;
  //justify-content: center;
}

.view-type-flex {
  @include center-flex(y);
  line-height: 32px;
  .tips-btn {
    height: 20px;
    @include center-flex();
  }
}
