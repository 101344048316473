@import "~@/assets/sass/_function";

.PriorityLevel-Zvpndtdd {
  .level {
    &-block {
      color: white;
      font-size: 12px;
      line-height: 20px;
      padding: 0 5px;
      border-radius: 2px;
      white-space: nowrap;
      text-align: center;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.modify-modal_1CR1Rtzz {
  &-contains {
    .modify-modal {
      &-container {
        .modify-modal-title {
          top: 12px;
          font-weight: 600;
          color: #3f3a4c;
        }
        .ic-shanchuguanbi {
          padding-top: 17px;
          opacity: 0.5;
          font-size: 12px;
        }
      }
    }
  }
  margin-top: 24px;
  .level {
    &-desc {
      color: #666666;
      margin: 22px 0 11px;
    }
    &-item {
      display: inline-flex;
      white-space: nowrap;
      color: white;
      border-radius: 2px;
      width: 120px;
      height: 30px;
      padding: 0 10px 0 15px;
      transition: width 0.1s;
      margin-bottom: 15px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .ic-gou {
        display: none;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        width: 154px;
      }

      &.active {
        width: 154px;
        .ic-gou {
          display: block;
        }
      }
    }
  }
}
