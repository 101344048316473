@import "~@/assets/sass/_function";

.UploadProgress-TGq7G7q {
  .upload-wait {
    color: #80798d;
  }
  .upload-fail {
    color: #b3b0b9;
  }
  .upload-success {
    color: #3a3446;
  }
  .th-title {
    font-weight: bold;
  }
}
