@import "~@/assets/sass/_function";

.ScanQuality-TGq7G7q {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 40px;
    .qrCode-content {
      position: relative;
      width: 188px;
      height: 188px;
      .bg-corner {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: rgba(78, 80, 184, 0.7);
        border-radius: 2px;
      }
      .left-top {
        left: 0;
        top: 0;
      }
      .right-top {
        right: 0;
        top: 0;
      }
      .left-bottom {
        left: 0;
        bottom: 0;
      }
      .right-bottom {
        right: 0;
        bottom: 0;
      }
      .img-content {
        position: absolute;
        left: 9px;
        top: 9px;
        width: 170px;
        height: 170px;
        background-color: #ffffff;
        border-radius: 4px;
        border: solid 2px #4e50b8;
        z-index: 1;
        padding: 10px;
      }
    }
    .bold {
      font-weight: bold;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 15px;
    }
    .tips {
      color: #80798d;
    }
  }
}
