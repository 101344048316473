.subform-custom-item {
  margin-top: 30px;
  .config-upload {
    width: 600px !important;
  }
  .form-addon {
    margin-left: 16px;
  }
}

.preview-tip {
  .modal-content {
    max-height: 630px !important;
  }
}

.batch-configure-modal {
  .modal-footer {
    position: relative;
    .wtdc-checkbox {
      position: absolute;
      left: 30px;
      top: 15px;
    }
  }
}

.modal-content {
  .orderType-form {
    display: flex;
  }
}
