@import "~@/assets/sass/_function";

.BatchFieldChange-TGq7G7q {
  .upload {
    width: 100%;
    display: flex;
    padding-bottom: 20px;
    .ResourcePickerDialog-lwUXrNw {
      &.pt10 {
        padding-top: 10px;
      }
      .resource-list__item--progress {
        margin-right: 5px;
      }
      .resource-list__item-title {
        white-space: normal;
        overflow: visible;
        @include ellipsis-lines(0);
      }
    }
    &-lab {
      flex-shrink: 0;
      margin-right: 20px;
      font-size: 15px;
      margin-top: 6px;
      position: relative;
      &::after {
        position: absolute;
        top: 5px;
        content: "*";
        font-size: 15px;
        line-height: 1;
        color: #ff3668;
      }
    }
    &-content {
      flex: 1;
      padding-right: 20px;
      .disableSampleRadio {
        pointer-events: none;
        cursor: default;
        opacity: 0.6;
      }
      .warn-tips {
        color: red;
        margin: 5px 0 0 0;
      }
      .wtdc-form-item {
        margin-bottom: 10px !important;
      }
      .wtdc-form-item-label {
        width: 0;
        padding-right: 0;
        .wtdc-form-item-label-text {
          &::after {
            display: none;
          }
        }
      }
      .ResourcePickerDialog-lwUXrNw {
        width: 100% !important;
        .tips {
          padding-left: 0;
          margin-top: 0;
        }
        .resource-list {
          width: 100%;
          .resource-list__item-title {
            width: auto;
          }
        }
      }
    }
  }
  .tips {
    padding-left: 120px;
    margin-top: 10px;
    color: #80798d;
  }
  .modal-content {
    padding-top: 30px;
    // height: 300px;
    .change-select-box {
      display: flex;
      align-items: center;
      .change-modal-select {
        margin-bottom: 0;
      }
      &-btn {
        margin-left: 20px;
        color: #4e50b8;
        cursor: pointer;
      }
    }
    .pdcLabel {
      margin-bottom: 30px !important;
      .ant-cascader {
        width: 240px;
      }
    }
  }
  .wtdc-form-item-label {
    padding-right: 20px;
    width: 110px;
  }
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
  .updatedContent {
    font-size: 15px;
    display: flex;
    margin-bottom: 20px;
    .label {
      flex-shrink: 0;
      .warning {
        color: red;
      }
    }
    .content {
      margin-left: 15px;
      .modify-btn {
        margin-left: 15px;
        color: #4e50b8;
        cursor: pointer;
      }
    }
  }
  .hadAdviserInfoText {
    line-height: 32px;
    color: $warning-color;
  }
  .SelectProgramTypeInfo {
    margin-right: 20px;
    min-height: 310px;
    .grayBg {
      width: 100%;
    }
    .wtdc-input,
    .wtdc-select {
      max-width: 520px !important;
    }
    .grayBg .wtdc-form-item-label {
      width: auto;
    }
    .wtdc-checkbox-group {
      width: 490px !important;
    }
  }
}
