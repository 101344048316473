@import "@/assets/sass/function";

.AudioPlayer {
  width: 714px;
  height: 148px;
  background-color: $white;
  border-radius: 8px;
  padding: 24px 20px;
  position: relative;
  display: flex;
justify-content: center;
align-items: center;
  .ant-slider {
    &-handle {
      background-color: #006aff;
      border-style: none;
      width: 11px;
      height: 11px;
      margin-top: -4px;
    }
    &-rail {
      background: rgb(71, 71, 71, 0.2);
    }
    &-track {
      background: #006aff !important;
    }
  }
  .close-btn {
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    right: 12px;
    top: 12px;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    background: url('https://oss.5rs.me/oss/uploadfe/png/eff7bbbfdb3bc2f52803706b0c2daf89.png') no-repeat 0 0/ 100% 100%;

  }
  .cover {
    flex: 0 0 100px;
    height: 100px;
    display: flex;
justify-content: center;
align-items: center;
    padding-right: 20px;
    img {
      width: 89px;
      height: 89px;
      object-fit: cover;
      border-radius: 50px;
    }
    &.playing {
      img {
        animation: rotate 10s linear infinite;
      }

      @keyframes rotate {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
  .controls {
    flex: 1;
    height: 100%;
    .audio-title {
      line-height: 24px;
      color: $text-color;
      margin-bottom: 16px;
    }
    .slider {
      height: 18px;
      @include center-flex(y);
      .ant-slider {
        flex: 1;
      }
    }
    .btns {
      @include center-flex(y);
      margin-top: 16px;
      height: 24px;
      .ic {
        font-size: 24px;
        cursor: pointer;
      }
      .btn-left {
        flex: 1;
      }
      .btn-right {
        @include center-flex(y);

        .ant-slider {
          width: 60px;
          margin-left: 9px;
        }
      }
    }
  }
}
