@import "~@/assets/sass/_function";

.ImportLayer-56V2Glp {
  .modal-container {
    min-width: 425px;
  }
  .book-example {
    text-decoration: underline;
    color: #4e50b8;
    cursor: pointer;
  }
  .wtdc-form-item {
    display: flex;
    //align-items: center;
    line-height: 30px;
    &-label {
      flex: 0 0 100px;
    }
    &-error-message {
      position: absolute;
    }
  }
  .publisher{
    margin-bottom: 10px;
  }
  .tips {
    width: 412px;
    margin: 0 0 20px 100px;
  }
  .wtdc-select-single{
    height: auto;
  }
  .wtdc-radio-group-item {
    width: 156px;
    padding: 0 10px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    color: #333;
    margin-right: 90px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .wtdc-radio-title {
      margin-left: 0;
    }
    .wtdc-radio-symbol {
      float: right;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      background-color: rgba(225, 222, 236, 0.4);
      border-radius: 2px;
    }
  }
  .tips-btn {
    font-size: 14px;
    color: #b3b0b9;
    cursor: pointer;
    margin-top: -2px;
    &:hover {
      color: rgb(78, 80, 183);
    }
  }
}
.Tooltip-ZmVpGsee {
  z-index: 5;
  .tips-title {
    font-size: 14px;
    color: #3a3446;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .tips-content {
    font-size: 14px;
    color: #3a3446;
  }
}
