@import "~@/assets/sass/_function";
.Myself-asouu19 {
  .myself-overview-box {
    .today-retreat {
      position: absolute;
      left: 25px;
      bottom: 5px;
      .my-todo {
        color: $primary-color;
        cursor: pointer;
      }
    }
    .my-todo-over {
      text-align: center;
      margin-top: 5px;
      .my-todo-content {
        line-height: 22px;
        font-size: 14px;
        color: #323233;
        margin-top: -28px;
        position: relative;
      }
    }

    .work-box {
      height: 400px;
    }
  }

  .flow-box {
    .work-box {
      height: 449px;
    }
  }

  // 盒子样式
  .work-box {
    background: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    .box-header {
      padding-right: 20px;
      height: 55px;
      border-bottom: 1px solid #e9e9e9;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .box-title {
      position: relative;
      font-size: 16px;
      line-height: 27px;
      color: #333;
      padding-left: 36px;
      cursor: pointer;
      .title-item {
        margin: 0 7px;
        white-space: nowrap;
      }
      &::before {
        position: absolute;
        content: "";
        width: 4px;
        height: 14px;
        left: 26px;
        top: 6px;
        background-color: $primary-color;
      }
      .active-title {
        color: $colorPrimary;
        font-weight: bold;
      }
    }
    .box-content {
      flex: 1;
    }
  }

  .myself-box {
    flex: 1;
    min-width: 420px;
    max-width: 622px;
    .chart-wrapper {
      display: flex;
      justify-content: center;
      padding: 25px 25px 5px;
      min-height: 310px;
      .work-order-config {
        text-align: center;
        margin-top: 72px;
        .tips {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 24px;
        }
        .to-detail {
          padding: 5px 10px;
          background-color: $primary-color;
          color: #fff;
          border: 0;
          border-radius: 4px;
        }
      }
      .work-order-config-all {
        position: absolute;
        left: 30px;
        bottom: 0;
      }
      .lengendScroll {
        position: relative;
        margin-top: 20px;
        .lengendTabs {
          top: -35px;
          left: 0;
          display: flex;
          align-items: flex-end;
          position: absolute;
          li {
            margin-right: 5px;
            cursor: pointer;
            color: #999;
            &.active {
              font-weight: 600;
              font-size: 16px;
              color: #4e50b8;
            }
            &:first-child {
              margin-right: 30px;
            }
          }
        }
        width: 50%;
        background-color: #f6f5f9;
        //width: 340px;
        height: 250px;
        padding: 15px;
        border-radius: 5px;
        .lengend-item {
          color: #3a3446;
          font-size: 15px;
          line-height: 1;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 32px;
          padding-right: 15px;

          .legend-diot {
            position: relative;
            top: 1px;
            width: 13px;
            height: 13px;
            border-radius: 2px;
            margin-right: 8px;
            flex-shrink: 0;
          }
          .legend-right-box {
            margin-left: 5px;
            flex-shrink: 0;
          }
          .legend-text {
            @include ellipsis-lines(1);
          }
        }
      }
      .work-handover {
        max-height: 300px;
        width: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        .handover-item {
          display: flex;
          justify-content: space-between;
          > div {
            display: flex;
          }
          .message {
            display: flex;
          }
          .name {
            display: block;
            max-width: 84px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .projects {
          cursor: pointer;
          margin: 0 5px;
          color: #4e50b8;
        }
        .handle-btns {
          > span {
            margin-right: 10px;
            color: #4e50b8;
            cursor: pointer;
          }
        }
        .status {
          display: block;
          min-width: 76px;
          text-align: left;
          color: #6c6d79;
        }
      }
    }
  }

  .more-box {
    width: 238px;
    .more-list {
      height: 100%;
    }
    .more-item {
      width: 100%;
      padding-left: 36px;
      margin-bottom: 35px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      color: #333333;
      justify-content: flex-start;
      align-items: center;
      &-icon {
        width: 50px;
        height: 50px;
        background: #4e50b8;
        border-radius: 50%;
        margin-right: 15px;
        font-size: 20px;
      }
    }
  }

  .danger-box {
    margin: 0 20px;
    flex: 1.5;
    min-width: 650px;
  }

  // 流程
  .WorkFlow-sjduo1 {
    position: relative;
    height: 100%;
    margin: 0 50px;
    .work-flow-title {
      top: 50px;
      font-size: 16px;
      font-weight: bolder;
      @include center-translate(x);
    }
    .workflow-box {
      height: 100%;
      padding: 0 20px;
    }
    .workflow-item {
      position: relative;
      flex: 1;
      padding: 50px 0;
      &:first-of-type {
        margin-left: 20px;
      }
      &:last-of-type {
        margin-right: 20px;
      }
      &.step-1 {
        flex: 1.2;
      }
      &.step-6 {
        flex: 1.4;
      }
      &-title {
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        &-span {
          font-size: 14px;
          border: 1px solid $colorPrimary;
          color: $colorPrimary;
          border-radius: 24px;
          line-height: 1.2;
          padding: 10px 20px;
        }
      }
      &-dot {
        position: absolute;
        top: 212px;
        width: 18px;
        height: 18px;
        background: #ffffff;
        border: 4px solid #4e50b8;
        left: 50%;
        transform: translate3d(-50%, 0, 0) rotateZ(45deg);
      }
      &-bottom {
        flex: 0 0 160px;
      }
      &-role {
        flex-grow: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #999999;
      }
      &-rolename {
        flex-grow: 0;
      }
    }
    .workflow-item-active {
      .workflow-item-title-span {
        color: #ff3668;
        border-color: #ff3668;
      }
      .workflow-item-dot {
        border-color: #ff3668;
      }
      .workflow-item-rolename {
        color: #ff3668;
        text-align: center;
      }
    }

    .other-box {
      position: absolute;
      top: 200px;
      width: 40px;
      height: 60px;
      &--start {
        left: -30px;
      }
      &--end {
        right: -30px;
      }
    }
    .line {
      position: absolute;
      top: 220px;
      width: 100%;
      height: 2px;
      background: #c7c7c7;
    }
    .ball {
      width: 20px;
      height: 20px;
      background: #c7c7c7;
      border-radius: 50%;
      &-inner {
        width: 10px;
        height: 10px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .Warning-80ijas {
    display: flex;
    align-items: center;
    // height: 250px;
    flex-wrap: wrap;
    padding: 25px 10px 0px 0px;
    .warning__item {
      width: 31%;
      padding: 5px 15px;
      // height: 127px;
      background-color: rgba(225, 222, 236, 0.4);
      border-radius: 10px;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #3a3446;
      margin: 10px 10px 0;
      cursor: pointer;
      &--small {
        width: 45%;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:hover {
        background-color: rgba(#e1deec, 0.8);
      }

      &--number {
        font-size: 23px;
        font-weight: bold;
      }

      &--title {
        font-size: 14px;
      }
    }
    .tips-btn {
      color: #6c6d79;
    }
  }

  .warning-list {
    display: flex;
    align-items: center;
    //justify-content: center;
    height: 250px;
    flex-wrap: wrap;
    .warning-item {
      width: 30%;
      padding: 5px 17px;
      margin: 10px 10px 0;
      text-align: center;
      color: $gray3;
      background: rgba(225, 222, 236, 0.4);
      border-radius: 10px;

      &-count {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 23px;
        font-weight: 600;
        &--light {
          color: #ffa246;
        }
        .tip-wrapper {
          position: relative;
          .text {
            font-weight: 500;
            font-size: 14px;
            padding-left: 4px;
            line-height: 35px;
          }
          .tip {
            max-height: 400px;
            overflow: auto;
            display: none;
            text-align: left;
            position: absolute;
            padding: 20px 20px 10px;
            top: 0;
            left: 0;
            margin-top: 32px;
            margin-left: 0px;
            background: #ffffff;
            font-size: 14px;
            color: #3a3446;
            background: #ffffff;
            box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.06);
            border-radius: 4px;
            z-index: 100;
            .item {
              margin: 0 auto;
              // width: 100px;
              overflow: hidden;
              margin-bottom: 10px;
              white-space: nowrap;
            }
          }
          .tip::-webkit-scrollbar {
            display: none; /* Chrome Safari */
          }
        }
        .tip-wrapper:hover {
          .tip {
            display: block;
          }
        }
      }
      &-title {
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
          @include ellipsis-lines(1);
        }
        .Tooltip {
          vertical-align: baseline;
        }
      }
    }
  }
}
.handoverConfirmModal {
  .modal-content {
    padding: 20px !important;
    .confirm-modal-content {
      margin-top: 0;
      text-align: left;
      .handover-return {
        text-align: center;
      }
    }
  }
}
