@import "~@/assets/sass/_function";

.RelateProject-6SA1QTJJ {
  .search-group {
    .wtdc-form-item {
      display: inline-block;
    }
    .wtdc-input {
      height: 36px;

      input {
        padding: 0 12px;
      }

      &:hover {
        .wtdc-input-affix-wrapper {
          border-color: rgba($colorPrimary, 0.5);
        }
      }

      &-focused {
        .wtdc-input-affix-wrapper {
          border-color: rgba($colorPrimary, 0.5);
        }
      }

      &-affix-wrapper {
        background-color: rgba(225, 222, 236, 0.3);
        border-radius: 4px;
        border-color: rgba(225, 222, 236, 0.3);
      }
    }

    button {
      height: 36px;
      background-color: $colorPrimary;
      border: none;
      border-radius: 4px;
      min-width: 60px;
      color: white;

      &:hover {
        background-color: lighten($colorPrimary, 5%);
      }
    }
  }

  .wtdc-pagination {
    text-align: center;
  }

  .wtdc-table {
    height: 455px;
    .batchSwitch {
      display: none;
    }
    .selector-td {
      width: 10%;
    }

    .td-erpNumber {
      width: 25%;
    }

    .td-serialNumber {
      width: 25%;
    }

    .td-publisher {
      width: 1px;
      white-space: nowrap;
      .book-publisher {
        width: 150px;
        white-space: initial;
      }
    }

    .td-bookName {
      width: 35%;
      padding-right: 30px;
    }
  }

  .modal {
    &-content {
      padding: 20px 30px 0;
    }

    &-tips {
      color: $text-color-dark;
      font-size: 15px;
      margin-bottom: 20px;
      font-weight: bold;
    }
  }
}
