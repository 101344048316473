@import "~@/assets/sass/_function";

.SelectBookLabel-i9Lnkkii {
  display: inline-block;
  position: relative;
  vertical-align: top;
  .booklabel--wrapper {
    display: flex;
  }
  .booklabel--title {
    width: 110px;
  }
  .booklabel--select {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .booklabel__item--wrapper {
    display: inline-flex;
    margin-right: 10px;
    margin-bottom: 15px;
    .booklabel__item--title {
      height: 32px;
      line-height: 32px;
      width: 55px;
      text-align: center;
      border: 1px solid #ddd;
      border-right: 0;
      font-size: 14px;
      vertical-align: middle;
      background-color: #f7f7f7;
      .wtdc-select {
        .wtdc-select-selector {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
    }
  }
}
