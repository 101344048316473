@import "~@/assets/sass/_function";

.taskList-content {
  .bookList {
    .book-count {
      color: #4e50b8;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .wtdc-antd-table {
    padding-right: 0px;
  }
}
.sendNotify-nLkSLU8 {
  .modal-content {
    padding: 50px 20px;
    text-align: center;
    .issue-checkBox {
      display: flex;
      align-items: center;
      justify-content: center;
      .wtdc-checkbox {
        font-size: 0px;
      }
      span {
        line-height: 12px;
      }
    }
  }
  .confirm-modal-content-icon {
    display: block;
    font-size: 65px;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 30px;
  }
}
.bookListModal-nLkSLU8 {
  .ant-table-container::after {
    box-shadow: none !important;
  }
  .modal-content {
    min-height: 500px;
  }
  .bookInfo {
    font-size: 15px;
    color: #3a3446;
    .bookName {
      font-weight: bolder;
    }
  }

  .preview {
    cursor: pointer;
    color: #4e50b8;
  }
}

.issue-checkBox {
  margin-top: 20px;
}
.wtdc-antd-table {
  .table-operation-btn-group {
    .table-operation-btn:after {
      height: 14px;
      top: 2px;
    }
  }
}
