@import "~@/assets/sass/_function";

.Breadcrumb {
  .bread-back {
    width: 71px;
    height: 32px;
    background: #f6f5f9;
    border-radius: 4px;
    &-icon {
      transform: rotateZ(90deg);
      transform-origin: 50% 50%;
      position: relative;
      top: 1px;
    }
    &-text {
      vertical-align: middle;
    }
  }

  .breadcrumb-item {
    font-size: 14px;
    line-height: 100%;
    height: 32px;
    color: #333;
    &_separator {
      margin: 0 5px;
    }
  }
}
