@import "~@/assets/sass/_function";
.BatchModifyData-9qVdP999 {
  .warning {
    margin: 20px 7px 10px 0;
  }
  .content-wrap {
    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell:nth-child(2) {
          color: #0f40f5;
          cursor: pointer;
        }
      }
    }
    .SearchBar-Bk8kXbbb {
      margin: 15px 0 15px;
      margin-left: -10px;
      width: 100%;
      .wtdc-form {
        .search-group {
          display: flex;
          justify-content: space-between;
          .wtdc-select-selection-search-input {
            padding-left: 7px;
          }
          .export {
            .export-btn {
              width: 123px;
              height: 30px;
              border-radius: 4px;
              border: 0;
              background-color: #4e50b8;
              color: rgba(255, 255, 255, 1);
              font-size: 14px;
              text-align: center;
              font-family: Microsoft Yahei;
            }
          }
        }
      }
    }
  }
}

.import-data-modal {
  .modal-content {
    padding-left: 24px;
    .warning{
      margin-bottom: 20px;
    }
    .modal-content-download {
      padding-left: 15px;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      .download {
        color: #0f40f5;
        cursor: pointer;
      }
    }
    .modal-content-upload {
      padding-left: 15px;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-bottom: 46px;
      .upload {
        margin-right: 10px;
      }
      .ResourcePickerDialog {
        display: block;
        width: auto;
        .ant-btn {
          padding: 0;
          background-color: #fff;
          color: #0f40f5;
          font-size: 14px;
          border: 0;
          margin-left: 0;
          box-shadow: none;
          .anticon-cloud-upload {
            display: none;
          }
          > .anticon + span {
            margin-left: 0;
          }
        }
      }
    }
  }
}
