.raysAccounts {
  .mr60 {
    margin-right: 60px !important;
  }
  .ant-tabs-tab {
    padding-top: 0px;
  }
  .wtdc-select-selection-search {
    padding-left: 8px;
  }
  .raysAccounts_Receive {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding-left: 30px;
    }
  }
}
