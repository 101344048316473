@import "~@/assets/sass/_function";

.ProjectStatistic-iUKQoFNN {
  .td-number,
  .td-currentTotalCount,
  .td-transferInCount {
    width: 22%;
  }

  .td-number {
    padding-left: 30px !important;
  }
  .th-number {
    padding-left: 30px !important;
  }
  .th-transferOutCount,
  .td-transferOutCount {
    width: 160px !important;
  }
  .th-transferOutCount {
    padding-left: 5px !important;
  }

  .count-num {
    color: #80798d;
    .detail-btn {
      display: inline-block;
      cursor: pointer;
      color: #4e50b8;
      text-decoration: underline;
    }
    .transferIn-btn {
      color: #fd7295;
    }
    .transferOut-btn {
      color: #19bfbd;
    }
  }
  input {
    background-color: transparent;
  }
}
.search-group-iUKQoFNN {
  .wtdc-select {
    height: inherit !important;
  }
}
