@import "~@/assets/sass/_function";

.modify-modal_JKPLjMb {
  width: 190px;
  height: 120px;
  .dealer-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    margin-bottom: 14px;
    .dealer-tips {
      color: #b3b0ba;
      margin-left: 10px;
    }
  }
  .wtdc-select-single {
    width: 180px !important;
  }
  .modify-modal_save-btn {
    margin: 0 auto !important;
    margin-right: 10px !important;
  }
}
