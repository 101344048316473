@import "~@/assets/sass/_function";
.ant-spin-nested-loading {
  height: calc(100% - 60px);
  .ant-spin-container {
    height: 100%;
  }
}
.ant-dropdown-menu-item:hover {
  background: rgb(233 235 247);
  color: #3f419e;
}
.TaskDefinition {
  // position: relative;
  .wtdc-input-number-handler-wrap {
    display: none;
  }
  .wtdc-form-item-error-message {
    display: none;
  }
  .ant-table-placeholder {
    display: none;
  }
  box-sizing: border-box;
  height: calc(100% - 100px);
  margin-top: 20px;
  .scroll,
  .form-content {
    height: 100%;
  }
  .task-wrapper {
    display: flex;
    align-items: flex-start;
    .bTitle {
      font-size: 20px;
      font-weight: bolder;
      width: 120px;
    }
    .right-wrapper {
      flex: 1;
      min-height: 180px;
      .switch-wrapper {
        display: flex;
        align-items: center;
        .title {
          font-size: 16px;
          font-weight: bolder;
        }
        .wtdc-form-item {
          margin-bottom: 0;
          .wtdc-form-item-children-wrapper {
            margin-top: 5px;
            transform: scale(0.8);
          }
        }
      }
      .upload-wrapper {
        margin: 20px 0;
        display: flex;
        align-items: center;
        .file-wrap {
          height: 30px;
          display: flex;
          align-items: center;
          padding: 0 10px 0 10px;
          .wtdc-icon {
            margin-top: 0;
          }
          .fileName {
            flex: 1;
            padding-right: 40px;
          }
          .ic-shanchuguanbi {
            display: none;
          }
        }
        .file-wrap:hover {
          background: #d4d5f4;
          .ic-shanchuguanbi {
            display: block;
          }
        }
        .ResourcePickerDialog {
          cursor: pointer;
          width: auto;
          .upload-flie {
            padding: 4px 10px 5px 10px;
            border: 1px dashed $primary-color;
          }
          .resource-list {
            margin-bottom: 0px;
            .resource-list__item {
              margin-bottom: 0px !important;
            }
          }
        }
        .label {
          width: 200px;
        }
        .file-wrap {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: $primary-color;
          .wtdc-icon {
            margin-top: 0px;
          }
        }
      }
      .award-wrapper {
        display: flex;
        align-items: center;
        .wtdc-form-item {
          margin-bottom: 0;
        }
        .label {
          width: 200px;
        }
        .unit {
          margin-left: 10px;
        }
      }
      .tip-wraper {
        margin: 10px 0 20px;
        font-size: 14px;
        color: #3a3445;
        span:nth-child(2) {
          color: #999999;
        }
      }
      .wtdc-antd-table {
        margin-top: 20px;
        width: 867px;
        thead {
          th {
            color: #3a3446;
            font-weight: bold !important;
            // padding-left: 5px;
          }
        }
        .price-wrapper {
          display: flex;
          align-items: center;
        }
        .wtdc-form-item {
          margin-bottom: 0px;
          margin-right: 10px;
        }
      }
      .add-wrapper {
        padding: 10px 0;
        width: 867px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #e9e9e9;
        border-radius: 4px;
      }
      .total-wrapper {
        padding: 15px 0;
        font-size: 14px;
        color: #3a3445;
        .price {
          font-size: 22px;
          color: $primary-color;
          font-weight: bolder;
        }
      }
    }
  }
  .footer-btn-wrapper {
    position: absolute;
    right: 0;
    bottom: 5px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .save {
      background: #d4d5f4;
      color: $primary-color;
    }
    .last {
      background: linear-gradient(180deg, #ffffff 0%, #f5f5f5 100%);
    }
    .ant-btn {
      min-width: 110px;
      border-radius: 4px;
    }
  }
}
