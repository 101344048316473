@import "~@/assets/sass/_function";

.SearchBar {
  // searchTable搜索框样式加上类名 search-group
  .search-group {
    //输入框自动填充时样式
    input:-webkit-autofill {
      -webkit-animation: autofill-fix 1s infinite !important;
      /* 选择历史记录的文字颜色*/
      // -webkit-text-fill-color: #666;
      -webkit-transition: background-color 50000s ease-in-out 0s !important;
      transition: background-color 50000s ease-in-out 0s !important;
      background-color: transparent !important;
      background-image: none !important;
      /* 选择历史记录的背景颜色 */
      -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    }
    [role="button"],
    a,
    area,
    button,
    input:not([type="range"]),
    label,
    select,
    summary,
    textarea {
      -ms-touch-action: manipulation;
      touch-action: manipulation;
    }
    input[type="number"],
    input[type="password"],
    input[type="text"],
    textarea {
      -webkit-appearance: none;
    }
    .wtdc-input {
      height: 32px;
      // background-color: rgba(225, 222, 236, 0.3)!important;
      input {
        padding: 0 12px;
        // background-color: rgba(225, 222, 236, 0.3)!important;
      }

      &:hover {
        .wtdc-input-affix-wrapper {
          border-color: rgba($colorPrimary, 0.5);
        }
      }

      &-focused {
        .wtdc-input-affix-wrapper {
          border-color: rgba($colorPrimary, 0.5);
        }
      }

      &-affix-wrapper {
        //background-color: rgba(225, 222, 236, 0.3);
        border-radius: 4px;
      }
    }

    .wtdc-select {
      height: 32px;

      .wtdc-select-selection-search {
        height: 100%;
        display: inline-block;
      }
      .wtdc-select-selection-search-input {
        padding: 7px 0px;
      }
      .wtdc-select-selector {
        // background-color: rgba(225, 222, 236, 0.3);
        height: 32px;
        border-radius: 4px;
        // border-color: rgba(225, 222, 236, 0.3);
        .wtdc-select-selection-item {
          height: 25px;
          line-height: 22px;
          margin: 2px 5px 0 0;
        }
      }
      .wtdc-select-selector:hover,
      .wtdc-select-selector:active {
        border-color: rgba($colorPrimary, 0.5);
      }
      input {
        background-color: transparent;
      }
      .wtdc-select-selection-item {
        padding: 2px 12px;
      }
    }

    button {
      //height: 36px;
      background-color: $colorPrimary;
      border: none;
      border-radius: 4px;

      &:hover {
        background-color: lighten($colorPrimary, 5%);
      }
    }
  }

  .AddButton {
    margin-top: 2px;
  }

  .wtdc-select-selector {
    display: flex;
    padding-right: 40px;
    overflow: hidden;
    .wtdc-select-selection-item {
      min-width: 45px;
    }
  }

  .wtdc-form-item-label {
    line-height: 32px;
  }
}
