@import "~@/assets/sass/_function";

.Create-kMhakd,
.Update-SJNwuE {
  min-height: 100%;
  background-color: #fff;
  padding: 20px 30px;

  .GoBack {
    margin-left: 0;
  }
}
