.BatchUpdateDetail-TGq7G7q {
  .modal-content {
    padding: 20px 30px;
    .wtdc-form-item {
      margin-bottom: 0 !important;
    }
    .ImagePicker {
      .tipAndOther {
        width: 240px;
      }
    }
    .firstClassify {
      .AntdCascader {
        width: 300px;
      }
    }
  }
  .change-modal {
    padding: 20px 0;
  }
}
