@import "~@/assets/sass/_function";
.Tag {
  width: 100%;
  height: 100%;
  display: flex;
  &-btn {
    border-radius: 4px;
    &.add-first {
      width: 150px;
    }
  }
  &-head {
    padding: 16px 0 10px 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
    .head-line {
      width: 4px;
      height: 16px;
      background: #4e50b8;
      margin-right: 6px;
    }
    .head-text {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
  }
  &-left {
    width: 30%;
    background: #ffffff;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #e9e9e9;
    flex-shrink: 0;
    &-content {
      height: calc(100% - 49px);
      overflow-y: scroll;
      padding: 30px;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 5px;
      }
      .ant-tree-switcher .ant-tree-switcher-icon,
      .ant-tree-switcher .ant-select-tree-switcher-icon {
        font-size: 14px;
      }

      .ant-tree .ant-tree-treenode {
        .ant-tree-draggable-icon,
        .ant-tree-switcher {
          padding-top: 4px;
        }
        .ant-tree-node-content-wrapper {
          display: flex;
          align-items: center;
          background-color: transparent;
          margin-bottom: 10px;
          &.ant-tree-node-selected,
          &:hover {
            .ant-tree-title {
              background-color: rgba($color: #dddfeb, $alpha: 0.5);
            }
          }
          .ant-tree-title {
            display: inline-block;
            border-radius: 2px;
            padding: 4px;
          }
        }
      }
      .ant-tree-list {
        .ant-tree-indent-unit {
          opacity: 0;
        }
        .ant-tree-draggable-icon,
        .ant-tree-switcher-leaf-line {
          display: none;
        }
        .tree-box {
          display: flex;
          align-items: center;
          &-content {
          }
          &-file {
            margin-top: 3px;
            width: 18px;
            height: 18px;
          }
          &-text {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            max-width: 242px;
          }
          &-img {
            width: 15px;
            height: 15px;
            display: none;
          }
          &:hover {
            .tree-box-img {
              display: block;
            }
          }
        }
        .tree-input {
          display: flex;
          align-items: center;
          &-btn {
            border-radius: 4px;
          }
        }
      }
      .ant-tree-show-line .ant-tree-switcher-line-icon {
        font-size: 17px;
      }
      &-one {
        display: flex;
        align-items: center;
        &-input {
          width: 300px;
        }
      }
    }
    &-footer {
      display: flex;
      justify-content: center;
      &-ic {
        font-size: 15px;
      }
    }
  }
  &-right {
    height: 100%;
    flex-grow: 1;
    background: #ffffff;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #e9e9e9;
  }
  .Trigger-wrapper {
    display: none;
    &.show {
      display: block;
    }
  }
}
