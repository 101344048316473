@import "~@/assets/sass/_function";

.BatchCustomLabelModal-mcqrCtw {
  .modal-content {
    padding: 60px 30px 100px;
  }
  .wtdc-form-item-label {
    padding: 0;
    margin-right: 20px;
  }
  .wtdc-form-item-children-wrapper {
    display: inline-block;
  }
}

.BatchBookLabelModal-NxKahYW {
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
  .modal-content {
    padding: 50px 22px 70px 32px;
  }
  .SelectBookLabel-i9Lnkkii {
    width: calc(100% - 90px);
  }
  .wtdc-form-item-label {
    width: 85px;
    vertical-align: top;
    margin-top: 5px;
  }
  .booklabel--wrapper {
    .batchBookLabel {
      .booklabel__item--wrapper {
        margin-right: 20px;
      }
      .booklabel__item--wrapper:nth-child(even) {
        margin-right: 0;
      }
    }
    .title {
      width: 65px;
      font-size: 15px;
    }
  }
}
