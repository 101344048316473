@import "~@/assets/sass/_function";

.LegendItem-0Un1UH00 {
  display: flex;
  .item {
    display: flex;
    align-items: center;
    height: 20px;
    color: #3a3446;
    .bg {
      border-radius: 4px;
      width: 40px;
      height: 20px;
      margin-right: 10px;
    }
    .wenhao {
      margin-right: 40px;
      margin-left: 5px;
      font-size: 14px;
      color: #b3b0b9;
      cursor: pointer;
      &:hover {
        color: rgb(78, 80, 183);
      }
    }
  }
  @media (max-width: 1300px) {
    .item {
      .wenhao {
        margin-right: 20px;
      }
    }
  }
}
