@import "~@/assets/sass/_function";

.UpdateRelateBookReviewModal {
  .modal-content {
    padding: 20px;

    .page-block-tips {
      white-space: nowrap;
      width: 560px;
      padding: 7px 10px;
      margin-bottom: 50px;
      background-color: rgba(78, 80, 184, 0.1);
      border-radius: 4px;
      .ic-tishi {
        margin-right: 8px;
        color: #4e50b8;
        font-size: 16px;
      }
    }
  }
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
}
