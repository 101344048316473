@import "~@/assets/sass/_function";

.PublicationInfo-WsHY0Xb {
  background-color: $white;

  .public {
    &__button {
      margin-left: 185px;
    }
  }
  .zhi {
    line-height: 30px;
  }
  .wtdc-form-item.active,
  .bookCoverProvince1.active,
  .bookCoverProvince2.active,
  .bookCoverProvince3.active {
    .wtdc-form-item-children-wrapper {
      .wtdc-input-affix-wrapper,
      .wtdc-select-selector {
        border: 1px solid #ff3300;
      }
    }
  }
  .recent-write {
    color: #4e50b8;
    cursor: pointer;
  }
  .expectPrintTime {
    display: inline-block;
  }
  .expectPrintTime-recent {
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
  }
  .firstPublishTime-recent {
    display: inline-block;
    position: relative;
    top: 7px;
  }
  .firstPublishTime.active,
  .expectPrintTime.active,
  .expectStartPublishTime.active,
  .expectEndPublishTime.active {
    .wtdc-calendar-picker-input {
      border: 1px solid #ff3300;
    }
    .wtdc-form-item-children-wrapper {
      border: none !important;
    }
  }
  .expectPublishTime,
  .firstPublishTime-wrap {
    .wtdc-form-item,
    .wtdc-form-item-required {
      display: inline-block;
      .wtdc-form-item-label {
        display: none;
      }
    }
    .expectStartPublishTime,
    .firstPublishTime {
      margin-left: 185px;
    }
  }
  .bookCoverProvince-wrap {
    .wtdc-form-item {
      display: inline-block;
      .wtdc-form-item-label {
        width: 50px;
        padding-right: 10px !important;
        .wtdc-form-item-label-text {
          width: 36px;
        }
      }
      .wtdc-form-item-children-wrapper {
        width: 180px;
      }
    }
    .bookCoverProvince1 {
      margin-left: 185px;
    }
  }
  .topicPlanMaterial-wrap {
    .topicPlanMaterialItem {
      display: inline-block;
      .wtdc-form-item-children-wrapper {
        width: 220px;
      }
    }
    .topicPlanMaterial-upload {
      display: inline-block;
      cursor: pointer;
    }
  }
  .secondEditionSampleDesc-wrap {
    position: relative;
    .tooltip {
      position: absolute;
      width: 330px;
      height: 54px;
      background-color: #ffffff;
      box-shadow: 0px 4px 20px 0px rgba(132, 129, 137, 0.2);
      border-radius: 4px;
      left: 650px;
      top: 100px;
      z-index: 2;
      display: none;
    }
  }
  .secondEditionSampleDesc {
    margin-top: 5px;
  }
  .secondEditionSampleItem-wrap {
    .secondEditionSampleItem {
      margin-bottom: 10px !important;
      display: inline-block;
      .wtdc-form-item-children-wrapper {
        width: 220px;
      }
    }
    .type-select {
      margin-left: 185px;
      margin-bottom: 0;
      margin-right: 20px;
      .wtdc-form-item-label {
        width: 110px;
        padding-right: 0;
        margin-top: 0;
        line-height: 31px;
      }
      .wtdc-form-item-children-wrapper {
        .wtdc-radio-group-item {
          margin-right: 20px;
        }
      }
      &.disableSampleRadio {
        pointer-events: none;
        cursor: default;
        opacity: 0.6;
      }
    }
    .match-sample {
      max-width: 640px;
      min-height: 32px;
      background-color: rgba(225, 222, 236, 0.4);
      border-radius: 2px;
      margin-left: 185px;
      line-height: 32px;
      padding-left: 10px;
      .match-sample-name {
        overflow: hidden;
        display: inline-block;
        width: 413px;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .match-sample-tips {
        color: #ff3300;
      }
      .confirm-box {
        width: 64px;
        height: 23px;
        line-height: 23px;
        background-color: #ffffff;
        border-radius: 10px;
        border: solid 1px #4e50b8;
        color: #4e50b8;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
      }
      .wtdc-icon-wenhao {
        margin-left: 6px;
        color: #b3b0b9;
        vertical-align: text-bottom;
      }
      .wtdc-icon-wenhao:hover {
        color: #4e50b8;
        .tooltip {
          display: block;
        }
      }
    }
    .secondEditionSampleUrl-upload {
      display: block;
      margin-left:295px;
      padding: 0px 8px 6px 0px;
      border-radius: 2px;
      cursor: pointer;
      .choose-sample {
        width: 250px;
        .choose-sample-filePicker {
          width: auto;
        }
      }
      .choose-sample-line {
        margin-left: 10px;
        display: inline-block;
        width: 2px;
        height: 16px;
        background-color: #4e50b8;
        display: inline-block !important;
      }
      .choose-sample-btn {
        display: inline-block;
        color: #4e50b8;
        margin-left: 10px;
        vertical-align: middle;
        .wtdc-icon {
          margin-right: 5px;
        }
      }
    }
    .secondEditionSampleUrl-upload {
      .file-content{
        // display: inline-block;
      }
      .file-wrap {
        position: relative;
        padding: 6px 8px 6px 8px;
        display: inline-block;
        align-items: center;
        .downLoad{
          position: absolute;
          left:0;
          bottom: 0;
          height: 1px;
          width: 100%;
          background: #4e50b8;
        }
      }
      .file-wrap:hover {
        background-color: rgba(225, 222, 236, 0.4);
        .wtdc-icon-shanchuguanbi {
          display: inline-block;
        }
      }
    }
  }

  .supplyResourceDesc-wrap {
    .supplyResourceDesc {
      margin-bottom: 0;
    }
    .supplyResourceUrl-upload {
      width: 150px;
      margin-left: 185px;
      cursor: pointer;
    }
  }

  .topicPlanMaterial-upload,
  .secondEditionSampleUrl-upload,
  .supplyResourceUrl-upload {
    .file-wrap {
      display: inline-block;
      color: #3a3446;
      cursor: pointer;
      .file-icon {
        color: #4e50b8;
        margin-right: 6px;
      }
      .file-name,
      .file-size {
        color: #4e50b8;
      }
      .file-size {
        margin-left: 15px;
      }
      .wtdc-icon-shanchuguanbi {
        display: none;
      }
      .wtdc-icon-shanchuguanbi {
        margin-left: 100px !important;
        color: $gray6 !important;
      }
    }
  }
  
  .wtdc-form-item-children-wrapper {
    .wtdc-calendar-picker {
      width: 320px !important;
      .wtdc-calendar-input {
        width: 320px;
      }
    }
  }

  .supplyResourceDesc,
  .locationCoverGuide,
  .locationTitlePageGuide,
  .locationTextGuide,
  .locationClipPaperGuide {
    .wtdc-form-item-required .wtdc-form-item-label-text:after {
      top: 82%;
    }
  }

  .wtdc-form-button.wtdc-form-button-disabled,
  .wtdc-form-button.wtdc-form-button-disabled:hover {
    background-color: rgba($color: $colorPrimary, $alpha: 0.5);
  }
  .wtdc-input-affix,
  .wtdc-input-counter,
  .wtdc-input-counter-length {
    color: $gray9;
  }

  .wtdc-form-item,
  .wtdc-form-item-required,
  .wtdc-form-item-label {
    padding-left: 0 !important;
  }

  .wtdc-form-item-label {
    width: 185px;
    text-align: left;
    color: #3a3346;
    padding-right: 20px;
    line-height: 1.5;
    margin-top: 5px;
  }
}
.secondEditionSampleItem-tooltip {
  .tool-tip-inner {
    width: 330px !important;
    max-width: 330px !important;
  }
}
