@import "~@/assets/sass/_function";

.MineHome-S1y2S2hh {
  background-color: $white;
  .ant-form-item-label {
    width: 90px;
    text-align: left;
  }

  .password-form,
  .account-form {
    margin-top: 30px;
    padding-left: 25px;

    &__button {
      width: 100px;
      margin-left: 105px;
    }
  }

  .account-form {
    &__head {
      width: 70px;
      height: 70px;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      .image-picker-tip {
        background-color: rgba(0, 0, 0, 0.5);
        text-align: center;
        width: 70px;
        margin-bottom: 0;
        z-index: 10;
        position: absolute;
        bottom: 0;
        color: white;
        line-height: 20px;
        font-size: 12px;
      }
      .image-picker-block {
        border: none;
      }
      .re-upload-btn {
        z-index: 11;
        margin-left: 0 !important;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 70px;
        text-align: center;
        color: white;
        text-decoration: none;
        font-size: 0;
      }
      &--modify {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        color: red;
      }
    }

    &__button {
      width: 100px;
      margin-left: 91px;
    }

    .wtdc-form-item-label {
      width: 90px;
      text-align: left;
    }
  }

  .password-form {
    .wtdc-form-item-label {
      width: 105px;
      text-align: left;
    }
  }
  .Handover {
    padding: 30px 20px;
    .noData {
      padding: 20px 10px;
      width: 50%;
      background-color: rgba(63, 81, 181, 0.03);
      font-size: 16px;
    }
    .workToTransfer {
      width: 30%;
      background-color: rgba(63, 81, 181, 0.03);
      padding: 20px 20px 5px 20px;
      margin-bottom: 30px;
      &-title {
        font-size: 16px;
        color: #3a3446;
        font-weight: bold;
        margin-bottom: 15px;
        line-height: 18px;
      }
      &-projectList {
        display: flex;
        flex-wrap: wrap;
        .projectItem {
          width: 50%;
          margin-bottom: 15px;
        }
      }
    }
    &-btn {
      padding: 5px 8px;
      border: 0;
      outline: 0;
      color: #fff;
      border-color: #4e50b8;
      background: #4e50b8;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      border-radius: 2px;
      &-disabled {
        opacity: 0.7;
      }
    }
    > p {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
