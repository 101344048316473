.QualityTesting-z9y5k7c1 {
  .wtdc-form-item-label {
    width: 90px;
  }
  .form-modal {
    height: 120px;
  }
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
}
