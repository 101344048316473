@import "~@/assets/sass/_function";

.BookLabelModal {
  .table-box {
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      &-img {
        width: 13px;
        height: 13px;
        cursor: pointer;
      }
      &-btn {
        width: 91px;
        height: 31px;
        border-radius: 4px;
        border: 1px solid #dddddd;
        line-height: 31px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .book-label-add {
    width: 150px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3f51b5;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    border: 1px dashed #4e50b8;
    cursor: pointer;
  }
}
.book-label-modal {
  height: 750px;
  .ant-modal-close-x {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .ant-modal-header {
    background: #f6f5f9;
    padding: 0 20px;
    height: 40px;
    display: flex;
    align-items: center;
    color: #3a3446;
  }
  .ant-modal-body {
    padding: 30px;
  }
}

.table-tooltip {
  .ant-tooltip-inner {
    color: rgba(0, 0, 0, 0.85);
  }
}
