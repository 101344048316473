@import "~@/assets/sass/_function";
.HighCharts {
  position: relative;
  cursor: pointer;
  width: 100%;
  .chart {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .chart-download {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0px;
    width: 22px;
    height: 22px;
    border: 1px solid #ddd;
    border-radius: 2px;
    background-color: #f8f8f8;
    font-size: 12px;
    color: #999;
    line-height: 22px;
    text-align: center;
    &:hover {
      color: $colorPrimary;
      border: 1px solid $colorPrimary;
      background-color: rgba($colorPrimary, 0.1);
    }
  }
}
