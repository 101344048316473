@import "~@/assets/sass/_function";

.ToggleCheckType-z9y5u6oP {
  .modal-content {
    height: 180px;
    padding-top: 60px !important;
    margin-left: 15px;
  }
  .modal-content {
    padding: 30px 20px;
    & > div {
      display: flex;
      & > label {
        line-height: 32px;
      }
    }
  }
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
}
