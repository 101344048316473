@import "~@/assets/sass/_function";

.FinalList-w52flHf {
  height: 100%;
  .ant-table-row-cell-break-word {
    position: relative;
  }
  .td-operate {
    position: relative !important;
  }

  .finish-status {
    position: absolute !important;
    bottom: 0 !important;
    right: 0 !important;
  }
  .manager-wrap {
    min-width: 100px;
  }
  .td-erpNumber {
    position: relative;
    .book--isRedTask {
      left: 10px;
    }
  }
}
