@import "~@/assets/sass/_function";

.Manage-7qVdP777 {
  .resource-info {
    display: flex;
    .wtdc-icon {
      font-size: 36px;
      margin-right: 27px;
      height: 36px;
    }
    .resource-content {
      flex: 1;
      position: relative;
      .resource-name {
        color: #3a3446;
        font-weight: bold;
      }
      .resource-type-name {
        color: #80798d;
        // margin-top: 10px;
      }
    }
  }

  .th-resourceId {
    width: 16%;
  }
  .th-convertState {
    width: 16%;
  }
  .th-orderSheetCreateTimes {
    width: 20%;
  }
  .th-operate {
    width: 13%;
  }
  .selector-th {
    // padding: 0 30px 0 30px!important;
    // width: 5%;
  }
}
.import-btn {
  width: 112px;
  height: 36px;
  // background-color: #4e50b8;
  border-radius: 4px;
  cursor: pointer;
  .title,
  .wtdc-icon {
    color: white !important;
  }
}
.formContent {
  width: 100%;
  .keywordsItem {
    .wtdc-input-affix-wrapper {
      border-radius: 4px;
    }
  }
  .wtdc-calendar-picker {
    width: 240px !important;
  }
}
.import-btn {
  height: 32px;
}
