@import "~@/assets/sass/_function";

.AgentProjectTop10-0Un1UH00 {
  height: 860px;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 20px;
    color: #3a3446;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .chart {
    // flex: 1;
    height: 760px;
  }
}
