@import "@/assets/sass/function";

.ResourcePreview {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 101;
  top: 0;
  left: 0;
  display: flex;
justify-content: center;
align-items: center;
  &-layer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &-container {
    position: relative;
    display: flex;
justify-content: center;
align-items: center;
  }
}
