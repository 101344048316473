@import "~@/assets/sass/_function";

.BatchEditTag-TGq7G7q {
  .ic-shanchuguanbi {
  }
  .modal-container {
    // height: 690px;
    max-height: 700px;
    min-height: 350px;
  }
  .modal-content {
    padding-left: 35px;
    height: 100%;
  }
  .modal-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .wtdc-checkbox-title {
    font-size: 14px;
    margin-left: 6px !important;
  }
  .content {
    padding-bottom: 30px;
  }
  .content .addTag-box {
    color: #4e50b8;
    display: inline-block;
    margin-bottom: 30px;
    cursor: pointer;
    .wtdc-icon {
      margin-right: 6px;
      font-size: 12px;
      vertical-align: initial;
    }
    .addTag-btn {
      text-decoration: underline;
    }
  }
  .tag-list {
    // display: flex;
    margin-bottom: 30px;
    .wtdc-checkbox {
      margin-top: 20px;
      width: 185px;
      display: inline-block;
      // flex: 1;
    }
    .wtdc-checkbox:nth-child(1),
    .wtdc-checkbox:nth-child(2),
    .wtdc-checkbox:nth-child(3) {
      margin-top: 0;
    }
    .wtdc-checkbox-title {
      // @include ellipsis-lines(1);
      // height: 100%;
      // width: 140px;
    }
  }
}

.modify-modal_SMhS22UUU {
  .addLabel-btn:focus {
    outline: none !important;
    // border:none!important
  }
  .addLabel-btn {
    margin-left: 75px;
    width: 56px !important;
    height: 28px !important;
    background-color: #edf0f2;
    border-radius: 2px;
    border: solid 1px #dddddd;
    cursor: pointer;
    line-height: 26px;
    text-align: center;
  }
  .wtdc-form-item-error-message {
    visibility: initial !important;
  }
  .wtdc-form-item {
    margin-bottom: 20px !important;
  }
  .wtdc-form {
    margin-top: 10px !important;
  }
  .wtdc-form-item-label {
    margin-right: 10px;
  }
}
// .ic-shanchuguanbi {
//   top: 10px !important;
//   right: 15px !important;
// }
