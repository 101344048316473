@import "~@/assets/sass/_function";

.CreateQrcode-3PKv6K {
  min-height: calc(100vh - 65px);
  .upload {
    padding: 20px 50px;
    background-color: $white;
    &__tips {
      width: 100%;
      background-color: rgba(255, 162, 70, 0.1);
      border-radius: 4px;
      height: 36px;
      line-height: 36px;
      color: #ef8114;
      font-size: 14px;
      margin-bottom: 20px;
      .ic-tishi {
        color: #ffa246;
      }
    }
    &__content {
      &--header {
        color: #3a3346;
        margin-bottom: 20px;
        .upload-template {
          color: #4e50b8;
          vertical-align: middle;
          text-decoration: underline;
          font-size: 14px;
          cursor: pointer;
        }
      }
      &--main {
        display: inline-block;
        width: 500px;
      }
      &--footer {
        font-size: 18px;
        color: #3a3346;
        margin-top: 30px;
      }
      .btn-box {
        .btn {
          &[disabled] {
            cursor: default;
            filter: alpha(opacity=50);
            box-shadow: none;
            opacity: 0.5;
          }
        }
        .btn-default {
          .ic-xiayijishangyijixiazai {
            transform: rotate(90deg);
            vertical-align: middle;
            font-size: 12px;
          }
        }
      }
    }
  }
  .qrcode__table {
    .SearchBar {
      margin: 0;
    }
    &--state {
      .red {
        color: #ff7295;
      }
      .ic-tishi {
        font-size: 11px;
        vertical-align: inherit;
        margin-right: 5px;
      }
      > span {
        color: #80798d;
      }
    }
    &--book-name {
      > p {
        display: flex;
        width: 322px;
        span {
          vertical-align: middle;
          @include ellipsis-lines(2);
        }
      }
    }
  }
}
