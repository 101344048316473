@import "~@/assets/sass/_function";

.ResourceOrder-P0DFcOcc {
  .resourceContent {
    padding: 20px 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .wtdc-icon-dengpaotishi {
      display: block;
      font-size: 60px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin: auto;
    }
    .title {
      font-size: 18px;
      color: #333;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: bold;
      line-height: 1;
    }
    .tips {
      width: 100%;
      font-size: 16px;
      color: #333333;
      margin-bottom: 30px;
      letter-spacing: 0.5px;
      padding-left: 8px;
    }
  }
}
