@import "~@/assets/sass/_function";

.BatchAddSeriesBook-Eblb_To {
  .seriesTips-content {
    width: 520px;
    // height: 60px;
    background-color: rgba(78, 80, 184, 0.1);
    border-radius: 4px;
    display: flex;
    margin-bottom: 30px;
    padding: 15px 18px;
    .wtdc-icon {
      line-height: 24px;
      color: #4e50b8;
      height: 24px;
      margin-right: 12px;
      // margin-top: 3px;
    }
    .text {
      font-size: 14px;
      // line-height: 24px;
      color: #3a3446;
    }
  }
  .wtdc-form-item {
    vertical-align: top;
    display: inline-block;
    margin-left: 40px;
    padding-bottom: 70px;
    .wtdc-form-item-label {
      padding-right: 20px;
    }
  }
  .modal-content {
    padding: 30px 20px;
  }
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
}
