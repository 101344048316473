@import "~@/assets/sass/_function";

.ReportProcess-gqr1ogE {
  min-width: 112px;
  position: relative;
  padding-right: 10px;
  .processStatus-edit {
    margin-left: -6px;
  }
  .status-btn {
    color: #4e50b8;
    cursor: pointer;
    padding: 0 9px;
    border: 1px solid #4e50b8;
    border-radius: 24px;

    &.isMakePlan {
      color: #3a3446;
    }
  }
  .make-plan-btn {
    color: #4e50b8;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 5px;
    .makePlan {
      font-size: 12px;
      margin-left: 4px;
    }
  }
  .state {
    color: $text-color;
    white-space: nowrap;
    &--normal {
      color: #333;
    }
    &--waiting {
      color: #999;
    }
    &--ing {
      color: #666;
    }
    &--finish {
      color: #19bfbd;
    }
    &--warn,
    &--back-ic {
      color: #ff7295 !important;
    }
    &--back-reason {
      color: #80798d;
      text-decoration: underline;
      cursor: default;
    }
  }

  .finish-status {
    position: absolute;
    right: 30px;
  }
  .state--finish {
    position: relative;
    top: 1px;
  }
}
