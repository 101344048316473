@charset "UTF-8";
@import "~@/assets/sass/_function";

.Confirm {
  position: fixed;
  z-index: 11101;
  width: 500px;
  height: 300px;
  border-radius: 5px;
  background-color: $colorBg;
  color: $gray3;
  .ft14 {
    font-size: 14px !important;
  }
  .confirm-top {
    margin: 8px;
    font-size: 14px;
    color: #999;
  }
  .confirm-header {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #f2f2f5;
    font-size: 16px;
    .header-title {
      margin-left: 14px;
      width: calc(100% - 55px);
      text-align: left;
    }
    .ic-close {
      width: 40px;
      line-height: 40px;
      font-size: 12px;
      text-align: center;
      color: #666;
      cursor: pointer;
    }
  }
  .ic-iosbell {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    display: block;
    font-size: 30px;
    border-radius: 50%;
    margin: 40px auto 20px auto;
    color: $white;
    // background-color: $colorWarning;
  }
  .modal-content {
    height: 100%;
    padding-top: 42px;
  }
  .confirm-modal-content-icon {
    display: block;
    font-size: 60px;
    // width: 65px;
    // height: 65px;
    border-radius: 50%;
    margin: 0;
  }
  .confirm-content {
    @include props-dpr(margin-top, $marginBase);
    padding: 0 30px;
    text-align: center;
    font-size: 16px;
    margin-top: 24px;
    color: $text-color;
    font-weight: bold;
  }
  .confirm-footer {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #f2f2f5;
    position: absolute;
    bottom: 0;
    .confirm-text {
      .confirm-yes {
        margin-right: 10px;
      }
      .confirm-no {
        margin-left: 10px;
      }
    }
    .btn {
      min-width: 64px;
      font-size: 14px;
      padding-left: 18px;
      padding-right: 18px;
      margin: 0 7px;
    }
  }
}
