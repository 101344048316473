@import "~@/assets/sass/_function";
.ContentPreview-56V2Glp {
  .modal-content {
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 50px;
  }
  .contentUeditor {
    display: flex;
    flex: 1;
    .wtdc-form-item-label {
      width: 90px;
    }
    .wtdc-form-item-children-wrapper {
      flex: 1;

      .edui-editor {
        height: 500px;
        // overflow: auto;
      }
    }
  }
}
