@import "~@/assets/sass/_function";
.MateResource {
  height: 100%;
  display: flex;
  flex-direction: column;
  .pr20 {
    padding-right: 20px;
  }
  .head {
    text-align: right;
    padding: 0 0 20px 0;
    .customResource {
      margin-right: 10px;
      color: #4e50b8;
      cursor: pointer;
    }
  }
  .content {
    .ant-table-body {
      overflow: auto scroll !important;
      max-height: calc(100vh - 430px) !important;
      .source-content {
        .desc {
          display: flex;
          align-items: center;
          .ant-image {
            margin-right: 5px;
          }
        }
      }
      .ant-image {
        flex-shrink: 0;
      }
    }
    .ant-btn-dashed {
      border-color: $primary-color;
      color: $primary-color;
    }
  }

  .footer {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
  .sourcePreview,
  .preview-btn {
    color: #4e50b8;
    cursor: pointer;
  }
}
