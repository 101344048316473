@import "~@/assets/sass/_function";
.ChangeRecord {
  .operateContentBox {
    > div {
      display: flex;
      align-items: center;
      > div {
        width: 30%;
        flex-shrink: 0;
        margin-right: 4.5% !important;
      }
      .imgPreview {
        margin-right: 2px !important;
      }
    }
  }
  .icon_rays7 {
    width: fit-content;
    background-color: rgba(78, 80, 184, 0.1);
    border-radius: 2px;
    font-size: 12px;
    color: #4e50b8;
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
  }
  .operate-content {
    &-text {
      max-height: 200px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        background-color: #ccc;
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        border-radius: 10px;
        background: transparent;
      }
    }
  }
}
