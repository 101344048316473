@import "~@/assets/sass/_function";

.StatusFilter-8ROOOPy {
  .status-form {
    display: inline-block;
    &__title {
      font-size: 15px;
      padding-right: 10px;
      text-align: right;
    }
    &__content {
      cursor: pointer;
      padding: 0 10px;
      width: 210px;
      height: 32px;
      line-height: 32px;
      border: 1px solid #ddd;
      border-radius: 2px;
      transition: all 0.3s;
      color: #333;
      background-color: white;
      font-size: 14px;
      @include ellipsis-lines(1);
      span {
        color: $placeholder-color;
      }
      &:hover {
        border-color: #4e50b8;
      }
    }
  }
}
.form__status--filter {
  .status-form__title {
    width: 110px;
    padding-right: 10px;
    text-align: left;
  }
  .status-form__content {
    width: 425px;
  }
  .status-form__content {
    span {
      color: #b3b0b9;
    }
  }
}
.StatusFilterLayer-pzG88T7 {
  min-height: 70vh;
  .modal-table-container {
    z-index: 100;
  }
  .wtdc-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    .wtdc-checkbox-group-item {
      width: 196px;
      padding: 0 10px;
      display: inline-block;
      height: 40px;
      line-height: 40px;
      color: #333;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .wtdc-checkbox-title {
        margin-left: 0;
        height: 100%;
        width: 140px;
        @include ellipsis-lines(1);
        padding-left: 5px;
      }
      .wtdc-checkbox-symbol {
        float: left;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:hover {
        background-color: rgba(225, 222, 236, 0.4);
        border-radius: 2px;
      }
    }
  }
  .status-category {
    h5 {
      font-weight: 600;
      margin: 10px 0;
      margin-left: 5px;
      line-height: 30px;
      border-bottom: #ccc 1px solid;
    }
  }
  .allChecked {
    bottom: 17px;
    position: absolute;
  }

  .ant-modal-body{
    height:700px;
    overflow: auto;
  }
}
