@import "~@/assets/sass/_function";

.NetworkDisk-7qVdP777 {
  .ant-table-tbody {
    tr {
      td {
        padding-top: 22px;
      }
    }
  }

  .wtdc-calendar-picker {
    width: 320px !important;
  }
  .row-merchantNameList {
    .row-merchantNameList-seeMore {
      margin-top: 10px;
      font-size: 14px;
      color: #4e50b8;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .resource-info {
    display: flex;
    align-items: center;
    .wtdc-icon {
      font-size: 40px;
      margin-right: 14px;
    }
    .wtdc-icon-pen {
      font-size: 16px;
      margin-right: 14px;
      background-color: #d0d0d0;
      color: white;
      visibility: hidden;
    }
    .resource-content {
      position: relative;
      width: 280px;
      color: #3a3446;
      font-weight: bold;
    }
    .resource-content:hover {
      cursor: pointer;
      .wtdc-icon-pen {
        visibility: initial;
      }
    }
  }
  .td-merchantNameList {
    width: 8%;
  }
  .td-fromOrgan,
  .td-fileSize,
  .td-convertState {
    width: 15%;
  }
  .th-merchantNameList {
    padding-left: 5px !important;
  }
}
.formContent {
  width: 100%;
  .keywordsItem {
    .wtdc-input-affix-wrapper {
      border-radius: 4px;
    }
  }
}
.NetworkDisk-dateRange {
  .wtdc-calendar-picker {
    width: 320px !important;
  }
}
.NetworkDisk-btn {
  position: relative;
  top: 5px;
}
.NetworkDisk-progress {
  background-color: white !important;
  color: #3a3446;
  border: none;
  line-height: 32px;
  height: 32px;
  cursor: pointer;
  text-decoration: underline;
  .wtdc-icon {
    font-size: 14px;
  }
}
.NetworkDisk-progress:hover {
  color: #4e50b8;
}
.UseRange-SLPE3PQQ {
  .modal-container {
    min-height: 320px;
    .modal-content {
      padding: 30px;
    }
    .layer-time-content {
      margin-bottom: 13px;
    }
  }
}
.modify-modal_4rDVBy445 {
  margin-top: 20px;
  .modify-modal_save-btn {
    margin-left: 90px !important;
  }
}
.path-box {
  color: #3a3446;
  margin-bottom: 15px;
  span {
    cursor: pointer;
    display: -webkit-inline-box !important;
    max-width: 160px;
    @include ellipsis-lines(1);
  }
  .path-box-item-symbol {
    vertical-align: top;
    margin: 0 4px 0 4px;
  }
  .path-box-item:last-child {
    color: #4e50b8 !important;
  }
  span:hover {
    color: #4e50b8;
  }
}
