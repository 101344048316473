@import "~@/assets/sass/_function";

.QrcodeDetail-63M3p1JJ {
  background: #f2f2f5;
  width: 100%;
  .baseInfo {
    height: 160px;
    background-color: #ffffff;
    border-radius: 12px;
    border: solid 1px #e9e9e9;
    padding: 30px;
    display: flex;
    .coverImg {
      border-radius: 4px;
      overflow: hidden;
      margin-right: 15px;
      width: 70px;
      height: 100px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: 0 3px 10px 0 rgba(21, 29, 64, 0.24);
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: url("~@/assets/images/book-silder.png") no-repeat;
        background-size: auto 100%;
        width: 6px;
        height: 100px;
      }
      // img {
      //   height: 100px;
      //   width: auto;
      // }
    }
    .detailInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      p {
        display: flex;
      }
      .bookName {
        font-size: 16px;
        color: #3a3446;
        font-weight: bold;
        margin-left: -5px;
      }
      .bookDetail {
        display: flex;
        flex: 1;
        .bookItem {
          color: #80798d;
          display: flex;
          flex-direction: column;
          margin-right: 80px;
          justify-content: space-around;
          span {
            // margin-right: 100px;
            &:first-child {
              // min-width: 300px;
              // padding-right: 80px;
            }
          }
        }
        .bookThird {
          .seize {
            opacity: 0;
          }
        }
      }
    }
  }
  .qrCodeList {
    padding: 20px 20px;
    background-color: #ffffff;
    border-radius: 12px;
    border: solid 1px #e9e9e9;
    margin-top: 20px;
    height: calc(100% - 180px);

    .th {
      // width:13.33%;
      &.th-coverUrl {
        min-width: 300px;
        width: 15%;
      }
    }

    .wechat-img {
      display: flex;
      .bigUrl,
      .smallUrl {
        height: 60px;
        background-clip: content-box;
        background-size: cover;
        img {
          height: 60px;
          width: auto;
        }
      }
      .bigUrl {
        margin-right: 10px;
        width: 140px;
      }
      .smallUrl {
        width: 60px;
      }
    }
    .th-detail {
      width: 35%;
    }
    .th-adviser,
    .th-label,
    .th-dealPrice,
    .th-retailPrice {
      width: 12%;
    }
    .td-adviser {
      .adviser-content {
        padding-right: 30px;
      }
    }
    .th-operation {
      // width: 100px!important;
      padding-right: 20px;
      .th-container {
        justify-content: flex-end;
      }
    }
    .td-operation {
      padding-right: 20px !important;
      .operation-btn-wrap {
        text-align: right;
      }
      .table-operation-btn {
        padding: 0;
      }
    }
    .table-operation-btn {
      color: rgb(63, 65, 158);
      cursor: pointer;
    }
    .td:last-of-type {
      width: 1px !important;
    }
    .detail-conetnt {
      display: flex;
      flex-direction: column;
      padding-right: 40px;
      .serveName {
        font-size: 15px;
        color: #3a3446;
        font-weight: bold;
        line-height: 23px;
      }
      .number-type {
        display: flex;
        align-items: center;
        color: #3a3446;
        font-size: 14px;
        // line-height: 14px;
      }
    }
    .qrcodeDetail4Erp-searchTable {
      margin-top: 24px;
      height: calc(100% - 130px);
    }
  }
  .operate-btns {
    > button {
      border: 0;
      background-color: transparent;
      color: blue;
    }
  }
}
