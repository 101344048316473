@import "~@/assets/sass/_function";

.BookMakePlan-MhlMtN99 {
  padding: 30px 10px;
  .ml80 {
    margin-left: 70px;
  }
  .templateSelet {
    margin-top: 50px;
    .title {
      font-size: 20px;
      color: #3a3446;
      font-weight: bold;
      line-height: 28px;
      margin-bottom: 20px;
    }
    .wtdc-form-item-label {
      padding-right: 10px;
    }
    .mr36 {
      margin-right: 36px;
    }
    .planType {
      .wtdc-form-item-children-wrapper {
        vertical-align: middle !important;
      }
      .wtdc-radio-title {
        font-size: 14px;
      }
    }
  }

  .booklabel__item--wrapper {
    margin-bottom: 0;
  }
  .search-group {
    width: 100%;
  }
  .BookLabel {
    margin-bottom: 30px;
  }
  .templateList-content {
    margin-top: 30px;
    .title-content {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .littleTitle {
        font-size: 15px;
        font-weight: bold;
        line-height: 24px;
        .resultNum {
          font-weight: normal;
          font-size: 14px;
        }
      }
      .addNewTemplate {
        color: #4e50b8;
        line-height: 24px;
        height: 24px;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 20px;
        .wtdc-icon {
          margin-right: 6px;
        }
      }
    }
    .templateTable {
      .th-planClassify {
        width: 20%;
      }
      .th-planNumber {
        width: 20%;
      }
      .td-planClassify {
        padding-right: 30px;
      }
      .td:last-of-type {
        width: 1px !important;
        white-space: nowrap;
        text-align: left;
      }
      .templateNum {
        .templateNum-bottom {
          display: flex;
          margin-top: 2px;
          .new {
            width: 24px;
            height: 20px;
            line-height: 20px;
            background-color: #ff7295;
            border-radius: 2px;
            color: #fff;
            text-align: center;
            line-height: 20px;
          }
          .old {
            width: 24px;
            height: 20px;
            line-height: 20px;
            background-color: #ecebed;
            border-radius: 2px;
            text-align: center;
            line-height: 20px;
          }
          .isSpecial {
            width: 60px;
            height: 20px;
            background-color: #ecebed;
            border-radius: 2px;
            margin-left: 6px;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
          }
        }
      }
      .wtdc-icon {
        font-size: 14px;
        margin-right: 3px;
      }
    }
    .templateListLess {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      &-tips {
        color: #80798d;
        font-size: 16px;
      }
      .lessImg {
        width: 160px;
        height: 160px;
        margin: 20px auto 30px;
      }
      .addNewTemplate {
        width: 182px;
        height: 36px;
        border-radius: 24px;
        border: solid 1px #4e50b8;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #4e50b8;
        cursor: pointer;
        transition: all 0.1s linear;
        .wtdc-icon {
          margin-left: 6px;
        }
        &:hover {
          background-color: #4e50b8;
          color: #fff;
        }
      }
    }
  }
}
