@import "~@/assets/sass/_function";

.MateTemplate {
  height: 100%;
  .cardBox {
    max-height: calc(100vh - 300px);
    // overflow-y: scroll;
    text-align: center;
    // position: relative;
    .templateList {
      text-align: left;
      display: inline-flex;
      flex-direction: column;
    }

    .classify {
      margin: 10px;
      font-size: 16px;
      margin-top: 30px;
      @include ellipsis-lines(2);
    }
    .templateVOS {
      display: inline-flex;
      flex-wrap: wrap;
      max-width: 100%;
    }
    .templateItem {
      width: 430px;
      margin: 10px;
    }
  }
  .noFooter {
    height: auto;
  }
  .footer {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
  }

  .TemplateCard {
    cursor: pointer;
  }
}
