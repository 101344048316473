@import "~@/assets/sass/_function";

.EditableBlock-oZ3IOEO {
  display: inline-block;
  padding: 0 3px;
  &:hover {
    cursor: pointer;
    background-color: rgba(78, 80, 184, 0.1);
  }
}
