@import "~@/assets/sass/_function";

.RelateBook-6SA1QTJJ {
  .modal-container {
    height: 85vh;
  }
  .wtdc-modal .modal-table-cell {
    padding-bottom: 0;
  }
  .wtdc-pagination {
    text-align: right;
  }
  .modal-content {
    padding: 20px 30px;
  }
  .SearchBar {
    margin: 0 0 20px;
  }
  .book {
    max-width: 325px;
    height: 380px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-bottom: 30px;
    border-radius: 4px;
    position: relative;
    &:hover {
      box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.14);
    }
    &__title {
      color: #3a3446;
      font-weight: bold;
      margin: 10px 0 5px;
    }
    &__text {
      display: inline-block;
      text-align: left;
      color: #80798d;
      div {
        margin-bottom: 5px;
        span {
          color: #3a3446;
        }
      }
    }
    &__button {
      margin-top: 10px;
      width: 201px;
      height: 32px;
      border-radius: 2px;
    }
    .icon_rays7 {
      width: fit-content;
      background-color: rgba(78, 80, 184, 0.1);
      border-radius: 2px;
      font-size: 12px;
      color: #4e50b8;
      padding: 0 6px;
      height: 20px;
      line-height: 20px;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
}
.realte-book-QmQ8ePPP {
  .confirm-modal-subtitle {
    font-size: 18px;
    font-weight: bold;
    small {
      font-weight: 400;
      font-size: 14px;
    }
  }
}
.replace-xihjf-modal {
  .modal-container {
    .replace-xihjf-modal-text{
      text-align: left;
      padding: 0 30px;
      display: inline-block;
      .redText {
        color: red;
      }
    }
    .modal-footer {
      .cancel-btn {
        display: none;
      }
    }
  }
}
