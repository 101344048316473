@import "~@/assets/sass/_function";

.BasicInfo-30vgqtnn {
  background-color: $white;
  & .wtdc-form-item {
    margin-bottom: 24px;
  }
  .book-size-box {
    display: flex;
    .book-size-remark {
      line-height: 30px;
      margin-left: 15px;
      &:before {
        content: "*";
      }
    }
  }
  .book {
    &__project-id {
      margin-bottom: 24px;
      color: #3a3346;
      font-size: 14px;
    }
    &__manager {
      margin-bottom: 24px;
      color: #3a3346;
      font-size: 14px;
      .wtdc-form-item {
        display: inline-block;
      }
      .wtdc-form-item-val {
        font-size: 20px;
        font-weight: 600;
        color: #ff3705;
        vertical-align: sub;
      }
    }
    &__service {
      .wtdc-form-item {
        display: inline-block;
      }
    }
    &__button {
      margin-left: 133px;
    }
    .wtdc-form-item.active,
    .readAimExam.active,
    .readAimNotExam.active {
      .wtdc-form-item-children-wrapper {
        // border: 1px solid #ff3300;
        .wtdc-input-affix-wrapper,
        .wtdc-select-selector {
          border: 1px solid #ff3300;
        }
      }
    }
  }
  .book-box {
    display: flex;
    &-text {
      width: 133px;
      padding-top: 5px;
    }
  }
  .serialNumber,
  .bookPrice {
    display: flex;
  }

  .publishType-wrap {
    .bookType,
    .otherBookType {
      display: inline-block;
    }
    .otherBookType {
      .wtdc-form-item-label {
        display: none;
      }
    }
  }

  .readAimExam-wrap {
    .readAimExam,
    .readAimNotExam,
    .readAimOther {
      display: inline-block;
    }
    .readAimNotExam,
    .readAimOther {
      .wtdc-form-item-label {
        display: none;
      }
    }
  }

  .expectPublishNum-wrap {
    .expectPublishNum,
    .proofQuantityUrl-upload {
      display: inline-block;
      .file-wrap {
        display: inline-block;
        .wtdc-icon-shanchuguanbi {
          // display: none;
          visibility: hidden;
        }
      }
      &:hover {
        cursor: pointer;
        .file-wrap {
          .wtdc-icon-shanchuguanbi {
            display: inline-block;
            visibility: visible;
          }
        }
      }
    }
    .commissionSheetUrl-upload {
      display: inline-block;
      .file-wrap {
        display: inline-block;
        .wtdc-icon-shanchuguanbi {
          display: none;
        }
      }
      &:hover {
        cursor: pointer;
        .file-wrap {
          .wtdc-icon-shanchuguanbi {
            display: inline-block;
          }
        }
      }
    }
  }

  .bookSubject {
    .wtdc-form-item-required .wtdc-form-item-label-text:after {
      top: 82%;
    }
  }

  .wtdc-form-button.wtdc-form-button-disabled,
  .wtdc-form-button.wtdc-form-button-disabled:hover {
    background-color: rgba($color: $colorPrimary, $alpha: 0.5);
  }
  .wtdc-input-affix,
  .wtdc-input-counter,
  .wtdc-input-counter-length {
    color: $gray9;
  }
  .wtdc-form-item-label {
    width: 133px;
    text-align: left;
    color: #3a3346;
    padding-right: 0;
  }

  .wtdc-form-item,
  .wtdc-form-item-required,
  .wtdc-form-item-label {
    padding-left: 0 !important;
  }
  .bookCoverImg {
    .image-picker-block {
      background-color: #fafafa;
      border: none;
      box-shadow: 0 3px 10px 0 rgba(21, 29, 64, 0.24);
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      z-index: 1;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 100%;
        background: url("@/assets/images/book-silder.png") no-repeat;
        background-size: auto 100%;
        z-index: 10;
      }
    }
    .image-block-prev {
      align-items: center;
      margin-top: 2px;
    }
    .tipAndOther {
      width: 275px;
      white-space: pre-wrap;
      margin-left: 6px;
      .image-picker-tip {
        color: #b3b0b9;
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 25px;
      }
      .re-upload-btn {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 28px;
        background-color: #ffffff;
        border-radius: 14px;
        border: solid 1px #4e50b8;
        color: #4e50b8;
        font-size: 12px;
      }
    }
  }
}

.grayBg {
  padding: 10px 20px;
  background-color: rgba(63, 81, 181, 0.03);
  display: inline-block;
  border-radius: 4px;
}
