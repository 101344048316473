@import "~@/assets/sass/_function";

.Iphone {
  .iphone-main {
    position: relative;
    width: 280px;
    padding: 51px 14px 0;
    border: 1px solid $grayE;
    border-radius: 40px;
    transition: background 0.3s ease;
    background-color: $white;
    .iphone-head {
      position: absolute;
      top: 32px;
      left: 0;
      width: 100%;
      text-align: center;
      s {
        display: inline-block;
        vertical-align: middle;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #c8c8c8;
      }
      i {
        display: inline-block;
        vertical-align: middle;
        width: 54px;
        height: 4px;
        margin-left: 10px;
        background-color: #c8c8c8;
        border-radius: 2px;
      }
    }
    .iphone-content {
      border: 1px solid $colorBorder;
      height: 452px;
      position: relative;
      overflow: hidden;
      .iphone-header {
        width: 100%;
        padding-top: percentage(54 / 317);
        background-image: url("~@/assets/images/wechat_header.png");
        background-size: 100% 100%;
      }

      .iphone-body {
        width: 100%;
        margin: 0;
        padding: 0;
      }
    }

    .center {
      text-align: center;
      height: 60px;
      width: 100%;
      padding: 9px;
      .iphone-circle {
        width: 40px;
        height: 40px;
        border: 1px solid $grayE;
        border-radius: 50%;
      }
    }
  }
}
