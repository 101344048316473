@import "~@/assets/sass/_function";
.SelectTheme {
  height: calc(100% - 95px);
  &.editTheme {
    height: calc(100% - 30px);
  }

  .search-wrapper {
    display: flex;
    align-items: center;
    .wtdc-form-item {
      margin-bottom: 0px;
    }
  }
  .Theme-content {
    height: calc(100% - 65px);
    margin-top: 20px;
    .inner-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .ant-empty {
      margin: 0 auto;
    }
    .ant-spin-container {
      height: 100%;
    }
    .Theme-item {
      padding-top: 10px;
      min-width: 200px;
      margin-bottom: 15px;
      margin-right: 15px;
      background: #f6f5f9;
      border-radius: 4px;
      &:hover {
        cursor: pointer;
      }
      .img {
        width: 100%;
        height: 200px;
        border-radius: 4px 4px 0 0;
        img {
          object-fit: contain;
        }
      }
      .text-content {
        padding: 8px 10px 12px 8px;
        display: flex;
        flex-direction: column;
        text-align: center;
        .title {
          font-size: 15px;
          @include ellipsis-lines(1);
          font-weight: bold;
        }
        .tag {
          color: #999999;
          padding-top: 5px;
          font-size: 13px;
        }
        .select-btn {
          margin: 10px 0 5px;
          align-self: center;
        }
      }
    }
    .Pagination {
      float: right;
    }
  }
  .fix-bottom {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 50px;
    background: #fff;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}
