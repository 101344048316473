@import "~@/assets/sass/_function";

.RejectPlanModal-2ilWWII {
  .reject-plan {
    margin-top: 40px;
    &__icon {
      display: block;
      font-size: 60px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin: auto;
    }
    &__subtitle {
      margin-top: 24px;
      font-size: 15px;
      color: #3a3446;
      text-align: center;
    }
    &__textarea {
      .wtdc-textarea textarea {
        background-color: #f8f8f8;
      }
    }
  }
}
