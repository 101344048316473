@import "~@/assets/sass/_function";
.SetTimeState-TGq7G7q {
  .wtdc-form-item:last-child {
    .wtdc-form-item-label-text {
      width: 60px;
      display: inline-block;
    }
  }
  .wtdc-form-item-label {
    width: 80px;
    padding-right: 20px !important;
  }
  .wtdc-form-item-children-wrapper {
    width: 425px;
    .wtdc-calendar-picker {
      width: 320px !important;
    }
  }
  .wtdc-form-item:nth-child(2),
  .wtdc-form-item:nth-child(3) {
    display: inline-block;
  }
  .wtdc-form-item:nth-child(2) {
    margin-right: 20px;
  }
  .modal-content {
    padding: 30px 30px 40px 30px;
  }
}
