@import "~@/assets/sass/_function";

.WorkTransfer-TGq7G7q {
  .modal-content {
    min-height: 300px;
  }
  .content {
    padding: 10px 25px 0 10px;
    .workToTransfer {
      background-color: rgba(63, 81, 181, 0.03);
      padding: 20px 20px 5px 20px;
      margin-bottom: 30px;
      &-title {
        font-size: 14px;
        color: #3a3446;
        font-weight: bold;
        margin-bottom: 15px;
        line-height: 18px;
      }
      .projectList {
        display: flex;
        flex-wrap: wrap;
        .projectItem {
          width: 50%;
          margin-bottom: 15px;
        }
      }
    }
  }
  .account {
    &__button {
      width: 110px;
      margin-left: 144px;
    }

    &__button {
      width: 110px;
      height: 36px;
      margin-left: 96px;
    }
    .wtdc-form-item-label {
      min-width: 96px;
    }

    .wtdc-form-item-label-text {
      padding-right: 6px;
    }
  }
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
}
