@import "~@/assets/sass/_function";
.TemplateCard {
  width: 430px;
  height: 380px;
  background: #f6f5f9;
  border-radius: 4px;
  border: 2px solid transparent;
  padding: 20px 25px 30px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  .title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #333333;
    line-height: 22px;
    font-weight: 600;
  }
  .label {
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .tags {
    min-height: 42px;
  }
  .content {
    display: flex;
  }
  .item {
    height: 30px;
    line-height: 28px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #dddddd;
    display: flex;
    justify-content: center;
    align-items: center;
    @include ellipsis-lines(1);
  }
}
.TemplateCardActive {
  border: 2px solid #4e50b8;
}
