@import "~@/assets/sass/_function";

.layout-container {
  .erp-container__content {
    flex: 1;
    border-radius: 12px;
    &.has-padding {
      border: 1px solid #e9e9e9;
      background-color: $white;
      padding: 20px 20px;
    }
    &.no-padding {
      padding: 0;
      background: transparent;
      border: 0;
    }
  }

  .feedback-wrap {
    width: 100px;
    height: 100px;
    position: fixed;
    z-index: 3;
    right: 20px;
    bottom: 100px;
  }
  .feedback-drag {
    width: 100px;
    height: 100px;
    cursor: pointer;
    // background: url('https://file.5rs.me/oss/uploadfe/png/b7b7647aa8b04f7773a1c0a9be0e2ce1.png') no-repeat;
    background: url("../../assets/images/feedback.svg") no-repeat;
    background-size: 100px 100px;
  }
}

// 为解决筛选控件和组件蒙层同时出现时的显示问题
.wtdc-modal .modal-table-container {
  z-index: 4;
}
