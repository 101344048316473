.RelateAdviser-QbNLzmAA {
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
  .adviserAccount {
    .wtdc-form-item-label {
      margin-right: 55px;
      vertical-align: baseline;
    }
  }
  .publish {
    margin-top: 20px;
    .wtdc-form-item-label {
      margin-right: 10px;
      vertical-align: baseline;
    }
  }
  .modal-title {
    font-size: 16px;
  }
  .modal-content {
    padding-left: 30px;
  }
}
