.BatchAssign-ABS6P1 {
  .tips {
    padding-left: 120px;
    margin-top: 10px;
    color: #80798d;
  }
  .modal-content {
    padding-top: 10px;
    // height: 300px;
  }
  .wtdc-form {
    .alert-message {
      margin-bottom: 10px;
    }
    * > div {
      margin-bottom: 10px;
      padding-left: 5px;
    }
    .wtdc-form-item-label {
      padding-right: 20px;
      width: 110px;
    }
    .modal-content-scroll {
      overflow: initial !important;
    }
    .modal-content-scroll > div:first-child {
      overflow: initial !important;
    }
    .wtdc-select {
      width: 200px;
    }
  }
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
}
