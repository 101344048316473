@import "~@/assets/sass/_function";

.myself-todo {
  .content-wrap-config {
    .SearchBar-Bk8kXbbb {
      margin: 20px 0 20px -10px;
      .resetBtn {
        position: absolute;
        right: 6px;
      }
      .wtdc-form-item {
        margin-right: 20px;
      }
    }
  }
}
.return-modal-content {
  .modal-content {
    padding-left: 26px;
    position: relative;
    .modal-content-tips {
      padding-left: 26px;
      width: 627px;
      height: 45px;
      font-size: 14px;
      line-height: 45px;
      color: #101010;
      background-color: #fffbe6;
      border: 1px solid #ffe58f;
      text-align: left;
      margin-bottom: 18px;
    }
    .linkBtn {
      position: absolute;
      right: 10px;
      top: 16px;
    }
  }
  .SelectAdviser {
    min-height: 200px;
    padding-top: 30px;
  }
}
.ant-table-tbody {
  .ant-table-row {
    .order-list {
      margin-top: 5px;
      .order-item {
        display: inline-block;
        padding: 2px 8px;
        border-radius: 4px;
        background-color: #eee;
        margin-bottom: 5px;
        &:first-child {
          margin-right: 5px;
        }
      }
    }
    .table-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .table-content-order {
        cursor: pointer;
        width: 180px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .table-content-operation {
        > button {
          border: 0;
          background-color: transparent;
          color: blue;
        }
      }
    }
    .table-after {
      .true {
        color: red;
      }
    }
    .active {
      color: #a4adb3;
    }
    .operate-btns {
      > button {
        border: 0;
        background-color: transparent;
        color: blue;
      }
    }
    .of-project {
      .work-config {
        color: red;
      }
    }
    // .book-name {
    //   overflow: hidden;
    //   white-space: nowrap;
    //   text-overflow: ellipsis;
    // }
    .config-link {
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .remarks {
      display: flex;
      align-items: center;
      .ic-xiugai {
        margin-left: 10px;
        cursor: pointer;
      }
      .remarkText {
        @include ellipsis-lines(3);
      }
    }
  }
}
