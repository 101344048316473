@import "~@/assets/sass/_function";
.TemplateDetail-56V2Glp {
  .modal-content {
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 50px;
  }
  .modal-table-cell {
    padding-bottom: initial;
  }
  .wtdc-form-item:last-of-type {
    margin-bottom: 20px;
  }
  .contentUeditor {
    display: flex;
    flex: 1;
    .wtdc-form-item-label {
      // width: 90px;
      padding-right: 20px;
    }
    .wtdc-form-item-children-wrapper {
      flex: 1;

      .edui-editor {
        height: 500px;
        // overflow: auto;
      }
    }
  }
  .TemplateDetail {
    .detailItem {
      display: flex;
      margin-bottom: 20px;
      .subtitle {
        font-size: 15px;
        color: #3a3346;
        padding-right: 20px;
      }
    }
    .previewImg {
      width: 90px;
      height: 90px;
    }
  }
}
