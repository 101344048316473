@import "~@/assets/sass/_function";

.layout-container {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 20px;
  overflow: hidden;
  background-color: $page-gray-bg;
  flex-wrap: nowrap;
  border-radius: 0 0 0 30px;
  transition: left 0.3s;
  overflow-x: auto;
  .erp-container__content {
    min-width: 1200px;
  }
  @include tablet {
    padding: 54px 14px 14px;
  }
}
