@import "~@/assets/sass/_function";

.QrcodeSelect-TOUOgXmm {
  position: relative;
  width: 100%;
  height: 130px;
  .arrow {
    position: absolute;
    font-size: 30px;
    color: #ddd;
    z-index: 1;
    cursor: pointer;
    &.unabled {
      // cursor: default;
      color: rgba(221, 221, 221, 0.5);
      cursor: not-allowed;
      &.unabled:hover {
        color: rgba(221, 221, 221, 0.5);
      }
    }
    &:hover {
      color: rgba(78, 80, 184, 0.5);
    }
    &.left_icon {
      left: 0;
      top: 50px;
    }
    &.right_icon {
      right: 0;
      top: 50px;
      transform: rotate(180deg);
    }
  }
  .scrool-wrap {
    margin-left: 45px;
    width: calc(100% - 90px);
    overflow: hidden;
  }
  .Qrcode-scroll {
    height: 130px;
    transition: all 0.3s ease-in-out;
    // display: flex;
    display: inline-block;
    white-space: nowrap;
    // flex-wrap: nowrap;
    .QrcodeSelectItem-wrap {
      display: inline-block;
      margin-right: 20px;
    }
  }
}
