@import "~@/assets/sass/_function";
.ThemeManage {
  height: calc(100% - 50px);

  .search-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-wrapper {
      display: flex;
      align-items: center;
    }
    .wtdc-form-item {
      margin-bottom: 0px;
    }
  }
  .Theme-content {
    height: calc(100% - 5px);
    margin-top: 20px;
    .inner-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .ant-empty {
      margin: 0 auto;
    }
    .Theme-item {
      position: relative;
      padding-top: 10px;
      margin-bottom: 15px;
      margin-right: 15px;
      background: #f6f5f9;
      border-radius: 4px;
      &:hover {
        cursor: pointer;
        .ic-shanchuguanbi {
          display: block;
        }
      }
      .ic-shanchuguanbi {
        position: absolute;
        top: 10px;
        right: 10px;
        display: none;
        color: #999;
        font-size: 18px;
      }
      .img {
        width: 100%;
        height: 200px;
        border-radius: 4px 4px 0 0;
        img {
          object-fit: contain;
        }
      }
      .text-content {
        padding: 8px 10px 12px 8px;
        display: flex;
        flex-direction: column;
        text-align: center;
        .title {
          font-size: 16px;
          @include ellipsis-lines(1);
          font-weight: bold;
        }
        .tag {
          color: #999999;
          padding-top: 5px;
          font-size: 13px;
        }
        .select-btn {
          margin: 10px 0 5px;
          align-self: center;
        }
      }
    }
    .Pagination {
      float: right;
    }
    .ant-spin-container {
      height: 100%;
    }
  }
}
.ThemeModal-TGq7G7q {
  .Theme-form {
    min-height: 300px;
    .form-item {
      display: flex;
      align-items: flex-start;
      .wtdc-form-item-label {
        height: 32px;
        line-height: 32px;
        margin-right: 10px;
      }
    }
    .modal-upload {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .label {
        margin-right: 15px;
        width: 40px;
      }
      .ResourcePickerDialog {
        flex: 0 0 300px;
      }
    }
    .classify {
      .label {
        margin-right: 25px;
      }
    }
    .file-content {
      .file-wrap {
        position: relative;
        padding: 6px 8px 6px 0px;
        display: flex;
        align-items: center;
        .downLoad {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
          width: 100%;
          background: #4e50b8;
        }
        .file-name {
          padding-left: 5px;
          max-width: 300px;
        }
      }
      .file-wrap:hover {
        background-color: rgba(225, 222, 236, 0.4);
        .wtdc-icon-shanchuguanbi {
          display: inline-block;
        }
      }
    }
  }
}
