@import "~@/assets/sass/_function";

.PermissionCreate-Kxwn9n,
.PermissionUpdate-CPxzgz {
  height: 100%;
  background-color: #fff;
  padding: 20px 30px;
  padding-left: 4px;
  .GoBack {
    margin-left: 0;
  }
}
