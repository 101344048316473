@import "~@/assets/sass/_function";
.ImportBook {
  display: inline-block;
}
.search-group {
  display: flex;
}

.bookInfo {
  padding-right: 10px;
  .bookName {
    font-size: 15px;
    font-weight: bolder;
  }
  .tag {
    margin-top: 6px;
    display: inline-block;
    color: #333333;
    font-size: 12px;
    padding: 2px 4px;
    background: #edf0f2;
    border-radius: 2px;
  }
}
.ChooseSample-6SA1QTJQ {
  .modal-content {
    min-height: 600px;
    .SearchTable {
      position: relative;
      .SearchBar {
        margin-bottom: 20px;
      }
      .select-count {
        position: absolute;
        bottom: 6px;
        left: 10px;
        color: #333333;
        font-size: 14px;
        span {
          color: $primary-color;
          font-weight: bolder;
        }
      }
    }
  }
}
