@import "~@/assets/sass/_function";

.TemplateCopy-kNK8Kz99 {
  .modal-content {
    height: 200px;
    padding: 0 40px;
    .copyForm {
      // margin-top: 50px;
      height: 100%;
      display: flex;
      align-items: center;
      .wtdc-form-item-label {
        font-size: 14px;
        margin-bottom: 8px;
      }
    }
  }
}
