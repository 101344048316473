@import "~@/assets/sass/_function";

.PlanDraft-7qVdP777 {
  height: 100%;
  .resource-info {
    display: flex;
    .wtdc-icon {
      font-size: 60px;
      margin-right: 14px;
      height: 50px;
      line-height: 50px;
    }
    .resource-content {
      position: relative;
      .resource-name {
        width: 280px;
        color: #3a3446;
        font-weight: bold;
      }
      .resource-type-name {
        color: #80798d;
        // margin-top: 10px;
      }
    }
  }

  .th-resourceId {
    width: 16%;
  }
  .th-erpNumber {
    min-width: 180px;
  }
  .th-convertState {
    width: 16%;
  }
  .th-orderSheetCreateTimes {
    width: 20%;
  }
  .th-operate {
    width: 13%;
  }
  .td:last-of-type {
    width: 1px !important;
    white-space: nowrap;
    text-align: left;
  }
}
.formContent {
  width: 100%;
  .keywordsItem {
    .wtdc-input-affix-wrapper {
      border-radius: 4px;
    }
  }
  .wtdc-calendar-picker {
    width: 240px !important;
  }
}
.import-btn {
  height: 32px;
}
