@import "~@/assets/sass/_function";

.ContentPreview {
  .Iphone {
    position: fixed;
    @extend %center-translate;
    z-index: 11001;
    .media-content {
      padding: 14px;
      height: 100%;
      * {
        box-sizing: border-box !important;
        -webkit-box-sizing: border-box !important;
      }
      p {
        clear: both;
        min-height: 1em;
        white-space: pre-wrap;
      }
      em {
        font-style: italic;
      }
    }
    .templateBtn {
      position: fixed;
      bottom: -50px;
      left: 32%;
      border-radius: 15px;
      background-color: $white;
      color: $colorPrimary;
      padding: 5px 20px;
    }
  }
}
