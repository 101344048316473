@import "~@/assets/sass/_function";

.SubmitLayer-56V2Glp {
  .modal-content {
    height: 200px;
  }
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
  .modal-footer {
    .tips-btn {
      margin-top: -2px;
      .wtdc-icon {
        color: rgba(255, 255, 255, 0.5);
        &:hover {
          color: #fff;
        }
      }
    }
    .btn-primary {
      line-height: 32px;
    }
  }

  .makePlanSubmit {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;

    .subTitle {
      position: relative;
      margin-bottom: 15px;
      padding-left: 10px;
      margin-top: 40px;
      &::before {
        content: "*";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #ff3668;
        font-size: 15px;
        line-height: 1;
        left: 0px;
      }
    }
  }
}
.Tooltip-xPOW11gg {
  z-index: 11;
  .tool-tip-inner {
    padding: 20px;
  }
  .tips-title {
    font-weight: bold;
  }
  .tips-content {
    line-height: 24px;
  }
}
