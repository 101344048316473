.ResourceList-1VUbooL {
  .resourceDTO {
    .file-handle {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      color: #4e50b8;
      > span {
        cursor: pointer;
        margin-right: 20px;
      }
    }
  }
  .operate {
    > div {
      cursor: pointer;
      color: #4e50b8;
    }
  }
}
