@charset "UTF-8";
@import "~@/assets/sass/_function";

.EchartsMap-1abd8e3b {
  width: 100%;
  height: 100%;
  position: relative;
  background: #f5f6f7;
  border-radius: 4px;
  .map-content {
    width: 100%;
    height: 100%;
  }
  .back {
    width: 76px;
    height: 32px;
    position: absolute;
    left: 20px;
    top: 20px;
    background-color: #ffffff;
    border-radius: 2px;
    border: solid 1px #dddddd;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1em;
    z-index: 9;
    // margin-right: 20px;
    i {
      font-size: 14px;
      color: $gray9;
      margin-right: 3px;
    }
  }
  .jsonLoading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    background: #f5f6f7;
    display: flex;
    align-items: center;
    justify-content: center;
    .tips {
      font-size: 16px;
    }
    .Pacman {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
}
