@import "~@/assets/sass/_function";

.SurveyOrder-5AXnnC {
  margin: 20px 10px;

  .pointerNo {
    pointer-events: none;
  }

  .survey-text {
    color: #80798d;
  }

  .surveyOrder-title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    color: $gray3;
  }

  .surveyOrder-info-item {
    margin-right: 60px;
    font-size: 14px;
    color: #80798d;
  }

  .survey-image {
    margin: 12px 40px 16px 0;
    background-color: #fafafa;
    border: none;
    box-shadow: 0 3px 10px 0 rgb(21 29 64 / 24%);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    img {
      object-fit: contain;
    }
  }

  .surveyOrder-location {
    margin-bottom: 32px;
    .surveyOrder-input {
      .wtdc-form-item-label {
        display: none;
      }
    }
  }

  .surveyOrder-item-other {
    align-items: center;
    .other-input {
      .wtdc-form-item-label {
        width: 85px;
        white-space: nowrap;
        margin-right: 20px;
      }
    }
  }
}

.surveyResource {
  .modal-content {
    max-height: 65vh !important;
    .wtdc-img {
      height: 620px !important;
    }
    .wtdc-img-inner {
      object-fit: contain;
    }
  }
}
