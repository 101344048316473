@import "~@/assets/sass/_function";

.ProjectFile-GAG44lQ {
  .project-file {
    margin-bottom: 5px;
    display: flex;
    white-space: nowrap;
  }
  .file {
    &-type {
      width: 84px;
      vertical-align: top;
      display: inline-block;
    }
    &-info {
      width: 100%;
    }
    &-wrap {
      min-width: 180px;
      display: inline-flex !important;
      white-space: initial;
      &--upload {
        color: #4e50b8;
        .ic {
          margin-right: 3px;
        }
      }
    }
    &-ic {
      font-size: 17px;
    }
    &-name {
      cursor: pointer;
      text-decoration: underline;
      .ic-download {
        margin-left: 8px;
        display: none;
      }
      &:hover {
        color: #4e50b8;
        .ic-download {
          display: inline-block;
        }
      }
    }
    &-preview {
      white-space: nowrap;
      cursor: pointer;
      font-size: 14px;
      color: #666;
      margin-left: 10px;
      &:hover {
        color: #4e50b8;
      }
    }
  }
}

.confirm-content-ASK {
  .confirm-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
    .confirm-modal-content-icon {
      display: block;
      font-size: 65px;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      margin: auto;
    }
    .title {
      margin-top: 24px;
      font-size: 15px;
      color: #3a3446;
      text-align: center;
    }
  }
}
