@import "~@/assets/sass/_function";

.StateSelectList-TGq7G7q {
  .state-tabList {
    display: flex;
    height: 38px;
    .state-tabItem {
      margin-right: 35px;
      height: 38px;
      line-height: 38px;
      font-size: 14px;
      color: #80798d;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      white-space: nowrap;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        border-color: #4e50b8;
        color: #4e50b8;
        font-weight: bold;
      }
    }
  }
  .modal-container {
    height: 640px;
  }
  .StateSelectList-SearchTable {
    height: 500px;
  }
}
