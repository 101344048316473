.Participants-P235S322 {
  width: 200px;
  .searchAll {
    color: #4e50b8;
    text-decoration: underline;
    cursor: pointer;
  }
  .market-content {
    display: flex;
    align-items: center;
  }
  .time-content {
    display: flex;
    // margin: 6px 0;
    margin-bottom: 2px;
    &:last-child {
      margin-bottom: 0;
    }
    .time-value {
      padding: 0 6px;
      flex: 1;
    }
  }
  .om-user-name {
    display: flex;
    align-items: center;
  }
}

.ParticipantsModal-SLPE3PQQ {
  .layer-time-content {
    margin-bottom: 15px;
    display: flex;
  }
  .layer-item-title {
    font-weight: bold;
    width: 45px !important;
  }
  .layer-item-value {
    flex: 1;
  }
  .modal-content {
    padding: 30px 30px 25px 30px;
  }
}
