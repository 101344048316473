@import "~@/assets/sass/_function";

.SelectProgramTypeInfo {
  padding-top: 15px;
  margin-bottom: -5px;

  &:empty {
    display: none;
  }
}
