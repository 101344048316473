.TeamSet-xaAPxxA {
  .teamInfo {
    .preview {
      cursor: pointer;
      margin-left: 10px;
      color: #4e50b8;
    }
  }
  .TeamSet-operate {
    > div {
      cursor: pointer;
      color: #4e50b8;
      margin-bottom: 5px;
    }
  }
}
.teamListModal {
  .teamList {
    &-item {
      display: flex;
      align-items: center;
      > div {
        flex: 1;
        margin-right: 20px;
      }
    }
  }
}

.creatTeamModal {
  .modal-content {
    padding-top: 30px;
    .wtdc-form-item {
      position: relative;
    }
    .wtdc-form-item-label {
      min-width: 120px;
    }
  }
}

.updateTeamModal {
  .updateForm {
    display: flex;
    align-items: center;
  }
}
