@import "~@/assets/sass/_function";

.EditAccount-3U3Rth77 {
  .account {
    &__button {
      width: 110px;
      margin-left: 144px;
    }

    &__button {
      width: 110px;
      height: 36px;
      margin-left: 96px;
    }
    .wtdc-form-item-label {
      min-width: 96px;
    }

    .wtdc-form-item-label-text {
      padding-right: 6px;
    }
  }
}
