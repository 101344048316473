@import "~@/assets/sass/_function";

.FreezeThaw-z9y5k7c0 {
  .time-wrap {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .time {
      color: $primary-color;
    }
  }
  .wtdc-form-horizontal-layout {
    .wtdc-form-item {
      margin-bottom: 15px;
    }
  }
  .wtdc-form-item-label {
    width: 115px;
  }
  .freeze-tips {
    margin-left: 115px;
    margin-top: -15px;
    color: #999;
  }
}
