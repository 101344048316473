@import "~@/assets/sass/_function";

.AddResourceModal {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3a3446;
  .modal-content {
    padding: 0px;
  }
  .ant-form {
    .ant-row {
      margin-bottom: 30px;
      .ant-form-item-label {
        text-align: left;
      }
    }
  }
  .ant-radio-button-wrapper:first-child {
    margin-right: 20px;
  }
  .formRadio {
    text-align: left;
  }
  .modal-content {
    height: 600px;
  }
  &-from-box {
    padding: 30px 30px 0 30px;
    // height: 100%;
    height: 600px;
  }
  &.modal-min-height {
    .modal-content {
      min-height: 500px;
    }
  }
}
