@import "~@/assets/sass/_function";

.FinishStatistic {
  border: solid 1px #eeeeee;
  border-radius: 12px;
  &-header {
    border-bottom: solid 1px #eeeeee;
    color: #3a3446;
    line-height: 42px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
  }
  &-filter {
    display: flex;
    margin: 0 20px;
    &-legend {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      span {
        margin-right: 10px;
        height: 32px;
        display: flex;
        white-space: nowrap;
        align-items: center;
        width: 100px;
        i {
          flex: 0 0 30px;
          height: 20px;
          border-radius: 5px;
          margin-right: 10px;
        }
      }
    }
  }

  &-Charts {
    padding: 10px;
  }
}
