@import "~@/assets/sass/_function";

.CreateRaysBook-pfVfIVI {
  .BookLabel {
    margin-bottom: 15px !important;
  }
  .typeCode {
    margin-bottom: 15px !important;
  }
  .modal-container {
    margin-top: 40px !important;
  }
  .isbn {
    margin-left: 60px;
    .wtdc-form-item-label-text:after {
      display: none;
    }
  }
  .typeCode {
    .wtdc-form-item-label {
      margin-right: 18px !important;
    }
  }
  .serialNumber {
    .wtdc-form-item-label {
      margin-right: 60px !important;
    }
  }
  .publish {
    .wtdc-form-item-label {
      margin-right: 15px !important;
    }
  }
  .previewImg {
    .wtdc-form-item-label {
      margin-right: 45px !important;
    }
  }
  .BookLabel > .wtdc-form-item-children-wrapper {
    width: 660px !important;
    .SelectBookLabel-i9Lnkkii .wtdc-select {
      width: 150px !important;
    }
  }

  // .modal-content-scroll,.modal-content-scroll>div:first-child{
  //   height: 900px!important;
  // }
  .add-image-block {
    width: 100px;
    height: 28px;
    background-color: #ffffff;
    border-radius: 14px;
    border: solid 1px #4e50b8;
    .add-image-block_content {
      .wtdc-icon {
        display: none;
      }
      .add-image-block_content-tip {
        margin-top: 0 !important;
        color: #4e50b8 !important;
      }
    }
  }
  .wtdc-img {
    width: 116px !important;
    height: 174px !important;
  }
  .wtdc-form-item-label {
    margin-right: 30px;
  }
  .image-picker-tip {
    position: relative;
    top: 3px;
  }
  .ImagePicker:not(.disabled) .add-image-block:hover {
    background-color: white;
    border-color: #4e50b8;
  }
  @media (max-width: 1360px) {
    .modal-container {
      margin-top: 40px !important;
    }
    .modal-content-scroll,
    .modal-content-scroll > div:first-child {
      max-height: 600px !important;
    }
  }
}
