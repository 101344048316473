.serial-table {
  .ant-table-fixed {
    tr {
      th,
      td {
        text-align: center;
      }
    }
  }
}
