@charset "UTF-8";
// @import "@/assets/sass/function";

.PDFView {
  width: 1150px;
  height: 816px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  @media (-webkit-min-device-pixel-ratio: 1.2), (min-resolution: 120dpi) {
    transform: scale(0.8);
  }
  &.fullscreen {
    transform: scale(1);
    width: 100vw;
    height: 100vh;
  }
  &-header {
    border-radius: 8px 8px 0 0;
    flex: 0 0 64px;
    line-height: 64px;
    background: #f9f9f9;
    padding: 0 10px 0 20px;
    position: relative;
    .ic {
      font-size: 20px;
      line-height: 20px;
      padding: 2px;
      position: absolute;
      right: 10px;
      top: 10px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .empty-box {
    min-height: 300px;
    background-color: #fff;
  }

  .empty-preview {
    width: 100px;
    height: 100px;
    border: solid 1px #eee;
    margin-left: 5px;
  }

  &-container {
    height: 100%;
    padding: 10px;
    border-radius: 20px;
    display: flex;
    flex: 1;
    overflow: hidden;

    &.fullscreen {
      position: fixed;
      background-color: #fff;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border: 0;
      z-index: 9999;
      transform: scale(1);
    }
  }

  .preview {
    height: 100%;
    flex: 0 0 146px;
    border: 1px solid #dbdbdb;
    position: relative;
    transition: width 0.3s;
    .toggle {
      display: block;
      background: #f1f1f1;
      border: 1px solid #dbdbdb;
      width: 16px;
      height: 44px;
      line-height: 42px;
      font-size: 16px;
      position: absolute;
      top: 50%;
      margin-top: -20px;
      color: #ccc;
      cursor: pointer;
      z-index: 1;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      .ic-back {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s transform;
      }
    }

    &.open {
      width: 150px;
      .toggle {
        left: auto;
        right: 0;
      }
    }
    &.close {
      width: 26px;
      flex: 0 0 24px;
      .toggle {
        right: auto;
        left: 0;
        transform: scale(-1);
      }
    }

    ul {
      width: 98%;
      padding: 14px 0;
      li {
        width: 90px;
        margin: 0 auto;
        padding-bottom: 10px;
        text-align: center;
        cursor: pointer;
        .img-contianer {
          border: 1px solid #d8d8d8;
          padding: 3px;
          margin-bottom: 2px;
        }
        .preview-img {
          width: 82px;
        }
        p {
          color: rgba(0, 0, 0, 0.6);
          line-height: 24px;
          font-size: 14px;
        }

        &.current {
          .img-contianer {
            border-color: #006aff;
          }
          p {
            border-color: #f2f3ff;
            color: #006aff;
          }
        }
      }
    }
  }

  .viewer {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    position: relative;
    border-right: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    .viewer-hd {
      font-size: 14px;
      height: 34px;
      flex: 0 0 34px;
      border: 1px #dbdbdb;
      border-style: solid none;
      padding: 0 20px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      .file-meta {
        flex: 0 0 30%;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
      }

      .actions {
        font-size: 16px;
        line-height: 24px;
        flex: 0 0 30%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        span {
          margin-left: 14px;
          cursor: pointer;
          width: 24px;
          height: 24px;
          i {
            font-size: 24px;
            color: #777;
          }
        }

        .disabled {
          cursor: not-allowed;
          .ic {
            color: #dcdcdc;
          }
        }
      }

      .file-status {
        flex: 1;
        text-align: center;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        strong {
          font-size: 14px;
          color: #333;
        }
      }
    }
    .view-list {
      flex: 1;
      overflow: hidden;
      .warn {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.5);
        height: 100%;
        label {
          margin-top: 20px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
      ul {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        li {
          padding-bottom: 20px;
        }
      }
    }

    .origin-img {
      max-width: 100%;
      width: 100%;
    }
  }
}
