@import "~@/assets/sass/_function";

.DepartmentStatistic-OnNP4Ytt {
  .pie {
    &-wrapper {
      background-color: white;
      border-radius: 12px;
      border: solid 1px #e9e9e9;
      padding-bottom: 10px;
      position: relative;
      .export-depart-btn {
        background-color: white;
        border-color: #ddd;
        color: #3a3446;
        position: absolute;
        right: 20px;
        top: 63px;
        &:hover {
          border-color: #3f51b5 !important;
          color: #3f51b5 !important;
        }
      }
    }

    &-header {
      color: #3a3446;
      line-height: 42px;
      font-size: 16px;
      border-bottom: solid 1px #eeeeee;
      text-align: center;
      margin-bottom: 20px;
    }

    &-selector {
      position: absolute;
      top: 62px;
      left: 20px;
    }

    &-chart {
      // margin: auto;
    }
  }
  .chart-wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    .lengendScroll {
      display: flex;
      flex-direction: column;
      background-color: #f6f5f9;
      width: 200px;
      padding: 15px;
      padding-right: 0;
      height: 288px;
      margin-left: -90px;
      position: relative;
      .lengend-item {
        color: #3a3446;
        font-size: 15px;
        line-height: 1;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 32px;
        padding-right: 15px;

        .legend-diot {
          width: 13px;
          height: 13px;
          border-radius: 2px;
          margin-right: 8px;
        }
        .legend-text {
          @include ellipsis-lines(1);
        }
      }
    }
  }
  .list {
    &-wrapper {
      height: calc(100vh - 480px);
      margin-top: 20px;
      padding: 20px;
      background-color: white;
      border-radius: 12px;
      border: solid 1px #e9e9e9;
    }

    &-table {
      width: 100%;

      thead {
        background-color: #f6f5f9;
        border-radius: 4px;

        th {
          color: $text-color-dark;
          font-weight: bold !important;
          font-size: 15px;
          text-align: left;
          height: 44px;
          padding-left: 25%;
        }
      }

      tbody {
        tr {
          &.empty {
            font-size: 15px;
            color: #999;
            text-align: center;
            td {
              padding-top: 30px;
            }
          }

          &:not(.empty) {
            transition: all 0.5s;
            border-bottom: 1px solid #eee;

            &:hover {
              background-color: rgba(78, 80, 184, 0.05);
            }
          }

          &:last-child {
            font-weight: bold;
            .count-num {
              color: #333;
            }
          }
        }

        td {
          width: 50%;
          padding: 23px 0 23px 25%;
          text-align: left;
        }
      }

      .count-num {
        color: #80798d;
        .detail-btn {
          display: inline-block;
          cursor: pointer;
          color: #4e50b8;
          text-decoration: underline;
        }
      }
    }
  }
}
