.batch-changeStatus-modal {
  .modal-content {
    .wtdc-radio-group {
      display: flex;
      flex-wrap: wrap;
      .wtdc-radio-group-item {
        width: 170px;
        margin: 0 30px 20px 0;
      }
    }
  }
}
