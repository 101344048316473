@import "~@/assets/sass/_function";

.RepeatRelateBook-CffDDND {
  .modal-content-scroll {
    min-height: 400px !important;
  }
  .modal-content-scroll > div:first-child {
    overflow-x: initial !important;
  }
  .wtdc-pagination {
    text-align: center;
  }
  .modal {
    &-content {
      padding: 20px 30px 20px;
    }

    &-tips {
      color: $text-color-dark;
      font-size: 15px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    &-table {
      .td-booName {
        div {
          width: 80%;
          @include ellipsis-lines(2);
        }
      }
      .td-adviserName {
        white-space: nowrap;
        width: 1px !important;
      }
    }
  }
}
