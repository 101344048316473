.ShareTask-f7_PW75a {
  .process_status-title {
    display: flex;
    align-items: center;
  }
  .operate {
    > div {
      cursor: pointer;
      color: #4e50b8;
    }
  }
}
.processModal {
  .modal-content {
    text-align: center;
    padding: 40px 0;
  }
}
