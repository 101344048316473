.OrderStatus {
  .searchAll {
    color: #4e50b8;
    text-decoration: underline;
    cursor: pointer;
  }
  .order-item {
    .green {
      color: green;
    }
    .red {
      color: red;
    }
  }
}
