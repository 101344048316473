@import "~@/assets/sass/_function";
.SelectBookLayer-56V2Glp {
  .modal-content {
    min-height: 500px;
  }
  .search-group {
    width: 100%;
  }
  .batchSwitch {
    display: none;
  }
  .th-erpNumber {
    width: 180px;
  }
  .th-status {
    // width:230px;
    // width:18%;
    .th-container {
      // display: flex;
      // justify-content: flex-end;
    }
  }
  .td-status {
    padding-right: 30px !important;
    padding-left: 11px !important;
    width: 1px !important;
    white-space: nowrap;
    text-align: left;
    // text-align: right;
  }
  .searchBtn {
    min-width: 60px;
  }
}
