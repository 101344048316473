@import "~@/assets/sass/_function";

.RepeatBook-CffDDND {
  .modal-content {
  }
  .wtdc-pagination {
    text-align: center;
  }

  .modal {
    &-content {
      padding: 30px 30px 40px;
      min-height: 500px;
    }

    &-tips {
      color: $text-color-dark;
      font-size: 15px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    &-table {
      .td-booName {
        .bookName-content {
          width: 80%;
          @include ellipsis-lines(2);
        }
      }
      .td-adviserName {
        white-space: nowrap;
        width: 1px !important;
      }
      .td:last-of-type {
        width: 1px !important;
        white-space: nowrap;
        text-align: left;
        .table-operation-btn-group {
          min-width: initial;
        }
      }
      .operate-none {
        padding-left: 6px;
      }
      .bookName-content {
        display: flex !important;
        // align-items: center;
        .nowBook {
          display: flex;
          align-items: center;
          color: #ff7295;
          font-size: 12px;
          min-width: 75px;
          .wtdc-icon {
            font-size: 11px;
            color: #ff7295;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
.delteRepeatModal-Y9jbmdBB {
  .Confirm {
    width: 500px;
    .modal-content {
      padding-top: 54px;
    }
  }
  .tips {
    text-align: center;
    margin-top: 18px;
    font-size: 18px;
    font-weight: bold;
  }
}
