@import "~@/assets/sass/_function";
.MakePlanByTemplate-5lE7yldd {
  .modal-content {
    min-height: 520px;
    padding: 30px;
  }
  .modal-table-cell {
    padding-bottom: initial;
  }
  .modal-footer {
    padding-top: 0;
    height: 146px;
    .wtdc-select-selection-item {
      text-align: left;
    }
    .btn {
      margin-top: 9px;
    }
    .btn-primary {
      line-height: 32px;
    }
    .tips-btn {
      margin-top: -2px;
      .wtdc-icon {
        color: rgba(255, 255, 255, 0.5);
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .table-operation-btn-group {
    .wtdc-icon {
      margin-right: 5px;
    }
    .table-operation-btn {
      padding: 0 10px !important;
    }
  }
  .td:last-of-type {
    padding-right: 30px !important;
    width: 1px !important;
    white-space: noWrap;
    text-align: left;
  }
  .th-erpNumber,
  .td-erpNumber {
    padding-left: 30px !important;
    .erpNumber-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .changeStatus {
        height: 20px;
        background-color: rgba(49, 178, 146, 0.05);
        border-radius: 2px;
        line-height: 20px;
        color: #31b292;
        font-size: 12px;
        width: 72px;
        text-align: center;
        margin-top: 6px;
      }
    }
  }
  .makePlanSubmit {
    position: relative;
    width: 800px;
    height: 96px;
    display: flex;
    align-items: center;
    bottom: 0;
    left: 0;
    padding-left: 70px;
    background-color: #fff;
    .subTitle {
      font-weight: bold;
      &::before {
        content: "*";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: #ff3668;
        font-size: 15px;
        line-height: 1;
        left: 60px;
      }
    }
    .wtdc-form-item {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }
}
.Tooltip-0tHH77uu {
  z-index: 11;
  .tool-tip-inner {
    padding: 20px;
  }
  .tips-title {
    font-weight: bold;
  }
  .tips-content {
    line-height: 24px;
  }
}
