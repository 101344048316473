@import "~@/assets/sass/_function";

.TemplateMakeLayer-lIR4q6ll {
  .modal-container {
    height: 800px;
    max-width: 1000px;
    min-width: 900px;
  }
  .modal-table-cell {
    padding-bottom: initial;
  }
  .modal-content {
    padding-left: 30px;
    padding-top: 30px;
  }
  .modal-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
  .TemplateMakeContent {
    .wtdc-form-item-label {
      // width: initial;
      padding-right: 20px;
    }
    .TemplateMakeForm {
      width: 100%;
    }
    .BookLabel {
      display: flex;
      margin-bottom: 10px;
      .wtdc-form-item-label {
        // width: 100px !important;
      }
      .wtdc-form-item-children-wrapper {
        flex: 1;
      }
      .booklabel__item--wrapper {
        margin-bottom: 15px;
      }
    }
    .previewImg {
      .wtdc-form-item-label {
        line-height: 17px;
      }
      .ImagePicker {
        display: flex;
        flex-flow: column-reverse;
        .image-picker-tip {
          position: relative;
          height: 20px;
          margin-left: 0;
          color: #80798d;
          font-size: 14px;
        }
        .image-block {
          width: 90px !important;
        }
      }
    }
    .ml80 {
      margin-left: 80px;
    }
    .w80 {
      width: 80px;
      min-width: 80px !important;
    }

    .secondStepContent {
      .wtdc-form-item-label {
        // width: 100px;
        padding-right: 20px;
      }
      .ueditorContent {
        display: flex;
        padding-right: 30px;
        .contentUeditor {
          display: flex;
          flex: 1;
          .wtdc-form-item-children-wrapper {
            flex: 1;

            .edui-editor {
              // height: 550px;
              // height: 100%;
              // overflow: auto;
              .edui-editor-iframeholder {
                // height:100%!important;
              }
            }
          }
        }
        .slotContent {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          .slot-btn {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            span {
              width: 162px;
              height: 36px;
              border-radius: 18px;
              border: solid 1px #d9d9d9;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              margin-right: 5px;
              &:hover {
                border: solid 1px #4e50b8;
                color: #4e50b8;
              }
            }
            .wtdc-icon {
              color: #b3b0b9;
              &:hover {
                color: #4e50b8;
              }
            }
          }
        }
      }
    }
  }
}
.Tooltip-tzVtt0DD {
  z-index: 5;
}
