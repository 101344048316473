@import "~@/assets/sass/_function";

.TemplateDetailNew-7qVdP777 {
  min-height: 100%;
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  .basicInfo {
    margin-bottom: 50px;
  }
  .contentInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    .contentUeditor {
      flex: 1;
      width: 900px;
      display: flex;
      margin-bottom: 0;
      .wtdc-form-item-children-wrapper {
        flex: 1;
        .UEditor {
          height: 100%;
        }
      }
    }
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .basic-content {
    .basic-first,
    .basic-second {
      display: flex;
      margin-bottom: 20px;
    }
    .detailItem {
      display: flex;
      margin-right: 100px;
      .subtitle {
      }
      .previewImg {
        width: 100px;
        height: 100px;
        cursor: pointer;
        border-radius: 4px;
        overflow: hidden;
      }
    }
  }
}
