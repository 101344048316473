@import "@/assets/sass/function";

.VideoPlayer {
  width: 714px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  &.fullScreen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #000;
    video {
      max-height: calc(100vh - 50px);
    }
  }
  video {
    width: 100%;
    flex: 1;
    max-height: 80vh;
    background: #000;
  }
  .ant-slider {
    &-handle {
      background-color: #006aff;
      border-style: none;
      width: 11px;
      height: 11px;
      margin-top: -4px;
    }
    &-rail {
      background: $white;
    }
    &-track {
      background: #006aff !important;
    }
  }
  .close-btn {
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    right: -32px;
    top: -32px;
    color: $white;
    cursor: pointer;
    background: url('https://oss.5rs.me/oss/uploadfe/png/eff7bbbfdb3bc2f52803706b0c2daf89.png') no-repeat 0 0/ 100% 100%;
  }
  .cover {
    flex: 0 0 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    img {
      width: 89px;
      height: 89px;
      object-fit: cover;
      border-radius: 50px;
    }
    &.playing {
      img {
        animation: rotate 10s linear infinite;
      }

      @keyframes rotate {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
  .controls {
    flex: 1;
    flex: 0 0 50px;
    border-radius: 0 0 10px 10px;
    padding: 0 20px;
    @include center-flex(y);
    background: #474747;
    .audio-title {
      line-height: 24px;
      color: $text-color;
      margin-bottom: 16px;
    }
    .slider {
      height: 18px;
      @include center-flex(y);
      .ant-slider {
        flex: 1;
      }
    }
    .ic {
      font-size: 24px;
      cursor: pointer;
      color: $white;
      margin-right: 14px;
    }
    .btn-left {
      position: relative;
      flex: 1;
      @include center-flex(y);
      .sound {
        &-slider {
          transform: scale(0);
          position: absolute;
          background-color: rgba(0, 0, 0, 0.7);
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 25px;
          height: 87px;
          padding: 3px 0;
          border-radius: 2px;
          bottom: 56px;
          &:after {
            content: "";
            width: 100%;
            height: 20px;
            position: absolute;
            bottom: -20px;
          }
          label {
            color: $white;
            font-size: 10px;
            line-height: 18px;
          }
          .ant-slider {
            &-handle {
              margin-top: 0;
              margin-left: -4px;
            }
          }
          &.volumeVisible {
            transform: scale(1);
          }
        }
      }
      .time {
        margin-left: 16px;
        font-size: 10px;
        margin-top: 2px;
      }
      .progress {
        flex: 1;
        @include center-flex(y);
        color: $white;
        .ant-slider {
          flex: 1;
          width: 60px;
          margin-left: 9px;
          &-handle {
            background-color: $white;
          }
          &-rail {
            background: rgb(255, 255, 255, 0.2) !important;
          }
          &-track {
            background: #e5e7f1 !important;
          }
        }
      }
    }
    .btn-right {
      @include center-flex(y);
      .ic {
        margin-right: 0;
      }
    }
  }
}
