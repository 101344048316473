@import "~@/assets/sass/_function";

.AddLabel {
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  .label {
    width: 105px;
    margin-right: 10px;
    flex-shrink: 0;
  }
  .input-box {
    display: flex;
    margin-bottom: 12px;
    &-input {
      width: 220px;
    }
  }
  &-tips {
    height: 32px;
    background: #ededf8;
    border-radius: 4px;
    line-height: 32px;
    padding-left: 10px;
    margin-bottom: 35px;
  }
  &-book {
    display: flex;
    align-items: center;
    font-size: 15px;
    span {
      color: #000000;
    }
  }
  &-name {
    display: flex;
    align-items: center;
    &-input.ant-input-affix-wrapper {
      width: 220px;
    }
  }
  &-list {
    display: flex;
    min-height: 580px;
    .list-box {
      flex-grow: 1;
      &-add {
        width: 220px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #4e50b8;
        border: 1px dashed #4e50b8;
        margin-bottom: 12px;
        cursor: pointer;
        &-ic {
          font-size: 15px;
          margin-right: 4px;
        }
      }
      .labeldtos-list {
        .labeldtos-item {
          width: 220px;
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          &-left {
            width: 12px;
            height: 12px;
            flex-shrink: 0;
            margin-right: 10px;
            background: url("https://oss.5rs.me/oss/uploadfe/png/ee942a2cbf9dc5c2253a006e209936c7.png") no-repeat center
              center;
            background-size: 100%;
            cursor: move;
          }
          &-box {
            flex-grow: 1;
            height: 32px;
            background: #f5f7fa;
            border-radius: 2px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #4e50b8;
            &-img {
              width: 10px;
              height: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  &-footer {
    background-color: #ffffff;
    position: sticky;
    bottom: 0;
    left: 0;
    padding-bottom: 20px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.LABELDTOS_LIST {
  // user-select: none;
  // pointer-events: none;
  // cursor: all-scroll;
  box-shadow: 3px 3px 16px 0 rgba(0, 0, 0, 0.1);
  // background-color: #fff;
  display: flex;
  align-items: center;
  .labeldtos-item {
    width: 220px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    &-left {
      width: 12px;
      height: 12px;
      flex-shrink: 0;
      margin-right: 10px;
      background: url("https://oss.5rs.me/oss/uploadfe/png/ee942a2cbf9dc5c2253a006e209936c7.png") no-repeat center
        center;
      background-size: 100%;
      cursor: col-resize;
    }
    &-box {
      flex-grow: 1;
      height: 32px;
      background: #f5f7fa;
      border-radius: 2px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #4e50b8;
      &-img {
        width: 10px;
        height: 10px;
        cursor: pointer;
      }
    }
  }
}
