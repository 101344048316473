@import "~@/assets/sass/_function";

.StateChange-TGq7G7q {
  .modal-footer {
    position: relative;
    .wtdc-checkbox {
      position: absolute;
      left: 30px;
      top: 15px;
    }
  }
  .modal-container {
    max-width: 1350px;
  }
  .change-modal {
    padding: 10px;
    position: relative;
    min-height: 690px;
    //height: 690px;
    .top-dealuser {
      display: flex;
      justify-content: space-between;
      position: relative;
      .fill-text {
        color: $primary-color;
        cursor: pointer;
        position: absolute;
        right: 0;
      }
    }
    .dealUserId {
      .wtdc-form-item-label {
        margin-right: 25px;
      }
      // display: inline-block;
    }

    .state {
      color: #3a3346;
      &-title {
        font-size: 15px;
        margin-bottom: 10px;
        &--required {
          &:after {
            content: "*";
            color: #ff3668;
          }
        }
      }
      &-list {
        width: 100%;
        padding: 20px 0 0 20px;
        background-color: rgba(63, 81, 181, 0.03);
        &__group {
          margin-bottom: 10px;
          display: flex;
          align-items: flex-start;
          &-name {
            font-size: 14px;
            margin-right: 20px;
            margin-top: 5px;
            white-space: nowrap;
            font-weight: bold;
          }
        }
        &__item {
          min-width: 180px;
          height: 32px;
          background-color: #fff;
          border-radius: 4px;
          border: solid 1px #ddd;
          display: inline-flex;
          align-items: center;
          margin: 0 20px 20px 0;
          cursor: pointer;
          padding-right: 10px;
          &-radio {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 16px;
            height: 16px;
            padding: 4px;
            margin-right: 8px;
            margin-left: 10px;
            border: 1px solid #ddd;
            background-color: #fff;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            -webkit-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
            z-index: 1;
          }
          &-icon {
            display: block;
            width: 6px;
            height: 6px;
            background-color: white;
            border-radius: 50%;
            transform: scale(0);
            transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6);
          }
          &-name {
            font-size: 14px;
            color: #333;
          }
          &:hover {
            border: solid 1px rgba(63, 81, 181, 0.5);
            .state-list__item-radio {
              border-color: #4e50b8;
            }
            .state-list__item-name {
              color: #3f51b5;
            }
          }
          &--checked {
            border: solid 1px rgba(63, 81, 181, 0.5);
            .state-list__item-radio {
              background-color: #4e50b8;
              border: 1px solid #4e50b8;
            }
            .state-list__item-icon {
              transform: scale(1);
            }
            .state-list__item-name {
              color: #3f51b5;
            }
          }
          &--disabled {
            cursor: not-allowed;
            background-color: #f9f9fd;
            border-color: #ddd !important;
            .state-list__item-radio {
              background: #eee !important;
              border-color: #ddd !important;
              color: #999 !important;
            }
            .state-list__item-name {
              color: #666 !important;
            }
          }
        }
      }
    }

    .qualitylist {
      margin-bottom: 10px;
      .list-item {
        width: 100%;
        padding: 10px 0;
        // border-bottom: 1px solid #ddd;
        .list-item-radio {
          width: 100%;
          display: flex;
          height: 40px;
          align-items: center;
          .radio-select {
            min-width: 50% !important;
            margin-bottom: 0 !important;
            .wtdc-form-item-label {
              width: 60%;
            }
          }
        }
      }
    }
    .downloads {
      display: flex;
      align-items: center;
      &__title {
        font-size: 15px;
        color: #3a3346;
        margin-right: 20px;
        display: inline-block;
        vertical-align: top;
      }
      &__main {
        flex: 1;
        display: flex;
        align-items: center;
        .text-color-down {
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
    .diff-warning {
      margin-top: 20px;
      padding-left: 10px;
      height: 40px;
      line-height: 40px;
      background-color: #fffbe6;
      border: 1px solid #ffe58f;
    }
    .upload {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      .text-color-down {
        color: $primary-color;
        cursor: pointer;
      }
      &__title {
        font-size: 15px;
        color: #3a3346;
        margin-right: 20px;
        display: inline-block;
        vertical-align: top;
      }
      &__main {
        flex: 1;
        display: flex;
        align-items: center;
        .ResourcePickerDialog {
          width: auto !important;
          margin-right: 20px;
          .upload-flie {
            padding: 0;
          }
        }
      }
      .download-btn {
        color: #4e50b8;
        margin-left: 20px;
        cursor: pointer;
        margin-top: 40px;
      }
    }

    .wtdc-form-item-label {
      padding: 0;
      margin-right: 20px;
    }
    .wtdc-form-item-children-wrapper {
      display: inline-block;
    }

    .text-area {
      margin-top: 30px;
      .wtdc-form-item-label {
        vertical-align: top;
        padding-top: 8px;
        padding-right: 0;
        margin-right: 20px;
      }
    }
  }
  .wtdc-form-item.ccEmail {
    margin-top: 10px;
    margin-left: 75px;
  }
  .ccEmail-tips {
    // padding-left: 85px;
    // margin-top: 30px;
    color: $gray9;
    :global {
      .ant-select-dropdown-menu {
        max-height: 100px;
      }
    }
  }
  .ccEmail-tips-title {
    color: $gray3;
    margin-right: 30px;
  }
  .book-tips {
    margin-top: 30px;
    color: $gray9;
    display: flex;
    align-items: center;
  }
  .book-tips-title {
    color: $gray3;
    margin-right: 30px;
  }
  .book-tips-main {
    .Tooltip {
      position: relative;
      top: -3px;
      margin-right: 3px;
    }
    .book-tips-main-title {
      color: $primary-color;
      cursor: pointer;
    }
  }

  // 项目摘牌设置
  .delist-project {
    .wtdc-form-item-label {
      width: 100px;
    }
  }
  .serviceBook-label {
    display: flex;
    align-items: center;
    position: relative;
    .Tooltip {
      margin-top: -2px;
    }
    &::after {
      position: absolute;
      right: 5px;
      content: "*";
      font-size: 15px;
      line-height: 1;
      color: #ff3668;
    }
  }
}
.continueModal-TGq7G7q {
  .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .confirm-modal-content-icon {
      font-size: 50px;
    }
    .confirm-modal-subtitle {
      font-size: 14px;
      margin-top: 20px;
    }
  }
}

// 项目申报
.project-declaration {
  display: flex;
  font-size: 15px;
  .project-declaration-lable {
    width: 120px;
    margin-right: 20px;
    > span:last-child {
      color: #ff3668;
    }
  }
}

// 资源配置
.resource-config {
  .modal-upload {
    .ResourcePickerDialog-lwUXrNw {
      margin-left: 20px;
    }
  }
  .subform-custom-item {
    margin-top: 30px;
  }
}

//出版社资源配置人
.config-user {
  display: flex;
  align-items: center;
  margin-top: 15px;
  .label {
    line-height: 32.5px;
    .label-required {
      color: red;
    }
  }
  .mt10 {
    margin-top: 0 !important;
    margin-left: 10px;
  }
}

.grayBg {
  padding: 10px 20px;
  background-color: rgba(63, 81, 181, 0.03);
  display: inline-block;
}
.BookPdcLabel {
  .wtdc-form-item-error-message {
    display: none;
  }
}
