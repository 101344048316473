@import "~@/assets/sass/_function";

.MultiEdition {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-select {
    .ant-select-arrow {
      span {
        width: 11px;
        height: 11px;
        background: url("https://oss.5rs.me/oss/uploadfe/png/e38190ab5fba873497c0f3d786470086.png") no-repeat center
          center;
        background-size: 100% auto;
        svg {
          display: none;
        }
      }
    }
  }
}
