@import "~@/assets/sass/_function";
.Steps {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background: #f6f5f9;
  border-radius: 4px;
  margin-bottom: 20px;
  .Step-item {
    padding: 0 4px;
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 32px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #80798d;
    cursor: pointer;
    .count {
      width: 18px;
      height: 18px;
      border: 1px solid #80798d;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
    .text {
      margin-left: 8px;
    }
  }
  .split {
    height: 5px;
    width: 385px;
    color: #b3b0b9;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .split-active {
    color: $primary-color;
  }
  .Step-item-active {
    color: $primary-color;
    .count {
      color: $primary-color;
      border: 1px solid $primary-color;
    }
  }
}
