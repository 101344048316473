@import "~@/assets/sass/_function";

.FieldSelect-AfaRN6cc {
  display: inline-block;
  position: relative;
  .select__button {
    display: flex;
    align-items: center;
    cursor: pointer;
    .select-title {
      display: inline-block;
    }
    &:hover {
      color: #4e50b8;
    }
  }
}
