@import "~@/assets/sass/_function";

.ImprintingList {
  display: flex !important;
  > .wtdc-form-item-children-wrapper {
    .uploadTips {
      font-size: 14px;
      color: #b3b0b9;
      line-height: 1.5;
      margin-bottom: 15px;
      display: inline-block;
    }
    .uploadContent {
      display: flex;
      .wtdc-form-item {
        margin-right: 12px;
        margin-bottom: 0;
        .image-block-prev {
          align-items: center;
          margin-top: 2px;
        }
      }
    }
  }
}
