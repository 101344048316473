.Tools-okajs1 {
  padding: 5px 16px;
  .tools-header {
    font-size: 16px;
    font-weight: bold;
    line-height: 27px;
    color: #333333;
  }

  .tools-box {
    margin-top: 20px;
  }
  .tools-item {
    width: 266px;
    height: 80px;
    background: #f9f9f9;
    border-radius: 4px;
    margin-right: 15px;
    justify-content: start;
    align-items: center;
    transition: 200ms;
    transform: scale(1);
    &:hover {
      transform: scale(1.05);
    }
    &-icon {
      width: 40px;
      height: 40px;
      background: #4e50b8;
      border-radius: 4px;
      margin-left: 20px;
      margin-right: 10px;
      font-size: 16px;
      color: white;
      .wtdc-icon-woshou {
        font-size: 20px;
      }
    }
    .wtdc-icon-qiyeweixin {
      font-size: 20px;
    }
    &-title {
      font-size: 15px;
      font-weight: 400;
      line-height: 26px;
      color: #333333;
    }
    &-desc {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      color: #999999;
    }
  }
}
