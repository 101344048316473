@import "~@/assets/sass/_function";
.layout-container {
  // .erp-container__content {
  //   &.has-padding {
  //     padding: 20px 20px 80px;
  //   }
  // }
}
.taskHome-5AXnnC {
  height: calc(100% - 40px);
}
