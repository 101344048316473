@import "~@/assets/sass/_function";

.ChangeRecord-7qVdP777 {
  height: 100%;
  padding-top: 22px;
  .contentPreview {
    color: #4e50b8;
    cursor: pointer;
    text-decoration: underline;
  }
  .th-operateContentBefore,
  .th-operateContent {
    width: 20%;
  }
  .td:last-of-type {
    padding-right: 30px !important;
    padding-left: 11px !important;
    width: 1px !important;
    white-space: pre-wrap;
    text-align: left;
  }
  .contentUeditor {
    display: flex;
    flex: 1;
    .wtdc-form-item-label {
      width: 90px;
    }
    .wtdc-form-item-children-wrapper {
      flex: 1;

      .edui-editor {
        height: 500px;
        // overflow: auto;
      }
    }
  }
  .recoredImg {
    width: 90px;
    height: 90px;
  }
}
