@import "~@/assets/sass/_function";

.Application-RPIUCPAZAG {
  .modal-content {
    height: 370px;
    .application-box {
      padding-top: 60px;
      margin-left: 35px;
      .wtdc-form-item-label {
        width: 90px;
      }
      .adviser-select {
        margin-bottom: 15px;
      }
      .adviser-phone {
        margin-left: 90px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
}
