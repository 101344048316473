@import "~@/assets/sass/_function";

.raysAccounts_Search {
  .a-line {
    @include ellipsis-lines(1);
  }
  .account-gutter {
    &--row {
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      margin-bottom: 18px;
      background-color: #f9f9f9;
      &__detail {
        flex: 1 1;
        padding: 20px 15px 18px;
        color: #333;
        .detail {
          display: flex;
          margin-bottom: 10px;
          &-headUrl {
            width: 50px;
            height: 50px;
            border-radius: 5px;
            display: block;
            margin-right: 10px;
            background-color: #c0c0c0;
          }
          &-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1 1;
            min-width: 0;
            &--name {
              flex: 1 1;
              min-width: 0;
              font-weight: bold;
              font-size: 16px;
              line-height: normal;
              .name-tag {
                font-weight: 400;
                font-size: 13px;
                color: #fff;
                padding: 1px 6px;
                border-radius: 2px;
                margin-right: 4px;
                &.is-adviser {
                  background-color: rgb(255, 107, 107);
                }
              }
            }
            &--phone {
              color: #666;
            }
          }
        }
      }
      &__actions {
        display: flex;
        border-top: 1px solid #eee;
        color: #4e50b8;
        height: 40px;
        line-height: 40px;
        > span {
          cursor: pointer;
          width: 100%;
          text-align: center;
          // &:first-child {
          //   border-right: 1px solid #eee;
          // }
        }
      }
    }
  }
  .account-pagination {
    text-align: right;
    margin-top: 10px;
  }
}
