@import "~@/assets/sass/_function";

.LabelFilter-8ROOOPy {
  .filter-end {
    display: inline-block;
    background-color: #f6f6fc;
    border-radius: 15px;
    padding: 4px 11px 4px 10px;
    color: #3a3446;
    cursor: pointer;
    margin-left: 10px;
    .filter-num {
      color: #f44e23;
      margin: 0 2px;
    }
    .wtdc-icon {
      font-size: 11px;
      margin-left: 6px;
      vertical-align: baseline;
    }
  }
  .status-form {
    display: inline-block;
    &__title {
      font-size: 15px;
      padding-right: 10px;
      text-align: right;
    }
    &__content {
      cursor: pointer;
      padding: 0 10px;
      width: 300px;
      height: 36px;
      line-height: 36px;
      border: 1px solid #ddd;
      border-radius: 2px;
      transition: all 0.3s;
      color: #333;
      background-color: white;
      font-size: 14px;
      @include ellipsis-lines(1);
      span {
        color: $placeholder-color;
      }
      &:hover {
        border-color: #4e50b8;
      }
    }
  }
}
.form__status--filter {
  .status-form__title {
    width: 110px;
    padding-right: 10px;
    text-align: left;
  }
  .status-form__content {
    width: 425px;
  }
  .status-form__content {
    span {
      color: #b3b0b9;
    }
  }
}
.StatusFilterLayer-pzG88T7 {
  .modal-table-container {
    z-index: 100;
  }
  .wtdc-checkbox-group {
    .wtdc-checkbox-group-item {
      width: 196px;
      padding: 0 10px;
      display: inline-block;
      height: 40px;
      line-height: 40px;
      color: #333;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .wtdc-checkbox-title {
        margin-left: 0;
        height: 100%;
        width: 140px;
        @include ellipsis-lines(1);
      }
      .wtdc-checkbox-symbol {
        float: right;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:hover {
        background-color: rgba(225, 222, 236, 0.4);
        border-radius: 2px;
      }
    }
  }
}
