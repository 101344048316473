@import "~@/assets/sass/_function";

.ProductList,
.ToolsList {
  .description {
    width: 300px;
    .des-text {
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      cursor: pointer;
    }
    .more-btn {
      display: block;
      // text-align: center;
      cursor: pointer;
      color: $colorPrimary;
    }
  }
  .head {
    padding: 0 0 20px 0;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    justify-content: space-between;
    .ant-input-affix-wrapper {
      width: 300px;
    }
    .ant-input-group-addon {
      display: none;
    }
  }
  .ant-table-header {
    display: none;
  }
  .ant-table-body {
    max-height: calc(100vh - 375px) !important;
  }
  .bookInfo {
    .ant-image {
      flex-shrink: 0;
    }
  }
  .footer {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
  .radioItem {
    margin-bottom: 30px;
    width: 220px;
    margin-right: 50px;
    margin-left: 20px;
    // @include ellipsis-lines(1)
  }
  .radioBox {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    align-items: flex-start;
    max-height: calc(100vh - 350px) !important;
  }
  .hidden {
    width: 0;
    height: 0;
    visibility: hidden;
    overflow: hidden;
    display: none;
  }
  .preview-btn {
    color: #4e50b8;
    cursor: pointer;
  }
}
.shortContent {
  .ant-table-body {
    max-height: calc(100vh - 460px) !important;
  }
  .ant-table-body {
    max-height: calc(100vh - 460px) !important;
  }
}

.preview-modal-tdWEd {
  .modal-content {
    padding: 20px 30px 40px;
    .preview-wrap {
      width: 440px;
      height: 450px;
      background: $white;
      .preview-text {
        padding: 0 20px;
      }
      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }
}
