@import "~@/assets/sass/_function";

.QrcodeLayer-QbNLzmAA {
  .modal-content {
    padding: 10px 30px 20px;
    height: 760px;
    position: relative;
  }
  .loading-container {
    width: 100%;
    height: 100%;
    padding-bottom: 50%;
  }
  //.wtdc-pagination {
  //  position: absolute;
  //  bottom: 0;
  //  @include center-translate(x);
  //}
  .qrcode {
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &__info {
      width: calc(100% - 100px);
      color: #3a3446;
    }

    &__resource {
      &.no-resource {
        span {
          color: $warning-color;
        }
      }
    }
    &__isXiaoRui {
      width: fit-content;
      background-color: rgba(253, 51, 28, 0.1);
      border-radius: 2px;
      font-size: 12px;
      color: #fd331c;
      padding: 0 6px;
      height: 20px;
      line-height: 20px;
      margin-bottom: 4px;
    }

    &__rays7 {
      width: fit-content;
      background-color: rgba(78, 80, 184, 0.1);
      border-radius: 2px;
      font-size: 12px;
      color: #4e50b8;
      padding: 0 6px;
      height: 20px;
      line-height: 20px;
      margin-bottom: 4px;
    }
    .add-btn {
      position: absolute;
      cursor: pointer;
      font-size: 14px;
      color: #3f51b5;
      right: 20px;
      bottom: 10px;
      text-decoration: underline;
    }

    .statistics-btn {
      cursor: pointer;
      font-size: 14px;
      bottom: 10px;
      color: #3f51b5;
      left: 30px;
      position: absolute;
      text-decoration: underline;
    }
  }

}
