@import "~@/assets/sass/_function";
@import "~@wtdc/table/sass/table.scss";

$inputPrefixCls: "ant-table";

.erpStyle {
  .#{$inputPrefixCls} {
    table {
      thead {
        background-color: #f6f5f9;

        th {
          color: $text-color-dark;
          font-weight: bold !important;
          padding-left: 5px;
          &:first-of-type {
            padding-left: 10px;
            margin: 0;
          }
          &:last-of-type {
            padding-left: 11px;
          }
          .selector-operations-wrapper {
            padding-left: 0 !important;
            // left: 40px !important;
          }
          &.selector-th .wtdc-checkbox {
            margin: 0;
          }
          .batchSwitch {
            width: initial;
            .selector-operations {
              padding-right: 30px !important;
            }
            .selector-operation-button {
              white-space: nowrap;
            }
          }
        }
      }

      colgroup {
        .selector-col {
          width: 50px;
          min-width: 50px;
        }
      }

      tbody {
        tr {
          transition: all 0.5s;

          &:hover {
            background-color: rgba(78, 80, 184, 0.05);
          }
        }

        td {
          padding: 10px 5px;

          &:first-of-type {
            padding: 15px 10px 15px 10px;
          }
          &:last-of-type {
            padding-right: 30px;
          }
        }
      }
    }

    .table-operation-btn-group {
      min-width: 110px;
      .table-operation-btn {
        position: relative;
        color: $primary-color;
        background: none;
        border: none;
        outline: none;
        font-size: 14px;
        padding: 0 6px;
        cursor: pointer;
        transition: all 0.3s;

        > .Link {
          color: $primary-color;

          &:hover {
            color: darken($primary-color, 8%);
          }
        }

        &:hover {
          color: darken($primary-color, 8%);
        }

        &:after {
          content: "";
          position: absolute;
          display: inline-block;
          width: 1px;
          height: 16px;
          background-color: $primary-color;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        &:last-of-type:after {
          content: none;
        }
      }
    }
  }
}
