@import "~@/assets/sass/_function";

.erpNumber_p {
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
}
.erpNumber_lable {
  display: inline-block;
}
.erpNumber_status {
  display: inline-block;
  .status-btn {
    text-decoration: none;
    display: inline-block;
    border: 1px solid #4e50b8;
    border-radius: 16px;
    padding: 0 8px;
  }
  .mt5 {
    margin-left: -42px;
  }
}
.erpNumber_people {
  display: inline-block;
  .MarketerManage-lEbgaJa {
    width: 100%;
    .EditableBlock-oZ3IOEO {
      text-decoration: underline;
      color: #4e50b8;
    }
  }
}

.canceled-reason-box {
  max-height: 200px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: #ccc;
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
    background: transparent;
  }
}
