@import "~@/assets/sass/_function";

.qrcodeqc {
  .mr60 {
    margin-right: 60px;
  }
  .ant-table-tbody {
    .qrcode-img {
      width: 90px !important;
      height: 90px !important;
    }
  }
}
