@import "~@/assets/sass/_function";
.LabelItem {
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding-bottom: 20px;
  position: relative;
  border-radius: 12px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
  }
  &-btn {
    display: flex;
    justify-content: flex-end;
  }
  .Tag-head {
    border-radius: 12px 12px 0 0;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 10;
  }
  &-box {
    padding: 20px 20px 0;
    height: calc(100% - 49px);
    overflow-y: scroll;
    .edition-list {
      display: flex;
      // height: 100%;
      .edition-item {
        background: #f6f5f9;
        border-radius: 4px;
        width: 33%;
        margin-right: 2%;
        padding-top: 20px;
        padding-bottom: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        &:last-child {
          margin-right: 0;
        }
        &-head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 30px;
          span {
            font-size: 16px;
          }
          &-update {
            display: none;
            cursor: pointer;
            img {
              width: 15px;
              height: 15px;
            }
          }
        }
        &-container {
          width: 100%;
        }
        &-box {
          padding-left: 30px;
          &-name {
            display: inline-block;
            padding: 5px 25px;
            border-radius: 4px;
            border: 1px solid #dddddd;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
        &:hover {
          .edition-item-head-update {
            display: block;
          }
        }
      }
    }
  }
}
