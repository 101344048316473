@import "~@/assets/sass/_function";

.ResourcePickerDialog {
  display: inline-block;
  width: 100%;
  .upload-flie {
    width: 100%;
    &__content {
      width: 100%;
      height: 150px;
      background-color: #fcfcfc;
      border: 2px dashed #e2e2e4;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-top: 36px;
      margin-bottom: 20px;
      &:hover {
        border-color: $colorPrimary;
      }
      .ic-wenjianshangchuan {
        font-size: 33px;
        color: $colorPrimary;
      }
      .title {
        font-size: 15px;
        color: $gray3;
        margin-bottom: 10px;
      }
      .tips {
        font-size: 14px;
        color: #b3b0b9;
      }
    }
  }
  .resource-list {
    &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 8px;
      &:hover {
        background-color: rgba(225, 222, 236, 0.4);
        .delete-btn {
          display: inline-block;
        }
        .rigth-icon {
          display: none;
        }
      }
      > .ic {
        font-size: 18px;
        margin-right: 8px;
      }
      &--progress {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        &--title {
          font-size: 14px;
        }
        &--bar {
          position: relative;
          width: 100%;
          height: 3px;
          background-color: $grayE;
          border-radius: 2px;
          .progress-value {
            position: absolute;
            left: 0;
            top: 0;
            height: 3px;
            border-radius: 2px;
            background-color: $colorPrimary;
          }
        }
      }
    }
    .rigth-icon {
      width: 16px;
      height: 16px;
      background-color: $colorPrimary;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      .ic-gou {
        font-size: 8px;
        color: $white;
      }
    }
    .delete-btn {
      display: none;
      .ic-shanchuguanbi {
        font-size: 12px;
        color: $gray6;
      }
    }
  }
}
