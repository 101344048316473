@import "~@/assets/sass/_function";

.UploadExample-TGq7G7q {
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
  .modal-container {
    height: 360px;
  }
  .modal-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .exampleFilePicker {
    .wtdc-form-item-error-message {
      margin-top: 4px;
    }
  }
}
