@import "~@/assets/sass/_function";

.ExpectTime-wmpwjNo {
  width: 155px;
  .print-time,
  .publish-time {
    &--delay {
      height: 24px;
      text-align: center;
      background-color: rgba(255, 114, 149, 0.1);
      border-radius: 4px;
      font-size: 13px;
      line-height: 24px;
      color: #ff7295;
      width: 86px;
    }
    &--close-to {
      height: 24px;
      text-align: center;
      background-color: rgba(255, 162, 70, 0.1);
      border-radius: 4px;
      font-size: 13px;
      line-height: 24px;
      color: #ffa246;
      width: 86px;
    }
  }
}
