@import "~@/assets/sass/_function";

.config-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  iframe{
    flex: 1;
  }
  .config-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .config-content-title {
      display: flex;
      justify-content: space-between;
      .relation-book-box {
        display: flex;
        .relation-book-info {
          display: flex;
          align-items: center;
          >div, >span {
            flex-shrink: 0
          }
          .relateBookRefuseReason {
            flex-shrink: 1;
            display: inline-block;
            flex: 1;
            @include ellipsis-lines(1);

          }
        }
      }
      .relation-book{
        font-weight: 600;
        flex-shrink: 0;
      }
      button {
        background-color: transparent;
        border: 0;
        color: blue;
        cursor: pointer;
        font-weight: 600;
 
      }
    }
    > button {
      border: 0;
      color: #fff;
      background-color: #4e50b8;
      padding: 5px 10px;
      border-radius: 5px;
      margin-left: 50px;
      flex-shrink: 0;
    }
  }
}
.return-modal-content {
  .tips {
    > button {
      background-color: transparent;
      border: 0;
      color: blue;
      cursor: pointer;
    }
  }
}

.config-user {
  display: flex;
  align-items: center;
  padding: 20px 0 40px;
  .label {
    line-height: 32.5px;
    .label-required {
      color: red;
    }
  }
  .mt10 {
    margin-top: 0 !important;
    margin-left: 10px;
  }
}

.erp-content_scroll-view{
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  overflow: auto !important;
}
