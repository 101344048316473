@import "~@/assets/sass/_function";

.RelatedRaysBookInfo-B7M3McVV {
  .relateAdviser-content,
  .relateAdviser {
    display: inline-block;
  }
  .relateAdviser-content {
    margin-left: 16px;
    color: #4e50b8;
    cursor: pointer;
    .ic-guanlianerweima {
      margin-right: 6px;
    }
  }
  .book {
    &__text {
      color: #80798d !important;
      p {
        margin-bottom: 2px;
        line-height: 21px;
      }
      &--name {
        color: #333;
        font-weight: bold;
        margin-bottom: 4px;
      }

      &--qrcode {
        width: 128px;
        &.mayNotCode {
          width: 100%;
        }
        &.has-qrcode {
          span {
            color: $primary-color;
            cursor: pointer;
            text-decoration: underline;
          }
        }
        &.no-qrcode {
          span {
            color: $warning-color;
          }
        }
      }

      &--not-link {
        color: $primary-color;
        cursor: pointer;
        text-decoration: underline;
      }

      &--resource {
        &.no-resource {
          span {
            color: $warning-color;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

    }
    &--isImportantProject {
      background-color: rgba(253, 51, 28, 0.1);
      border-radius: 2px;
      font-size: 12px;
      color: #fd331c;
      padding: 0 6px;
      height: 20px;
      line-height: 20px;
      margin-bottom: 4px;
    }
  }
  .relateBookRefuseReason {
    cursor: pointer;
    @include ellipsis-lines
  }
}
.modify-modal_4rDVBy44 {
  margin-top: 24px;
  .wtdc-form-item-label {
    margin-right: 10px;
  }
  .level-item {
    display: inline-flex;
    color: white;
    border-radius: 2px;
    width: 120px;
    height: 30px;
    padding: 0 10px 0 15px;
    transition: width 0.1s;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .ic-gou {
      display: none;
    }

    &:first-child {
      color: #3a3446;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      width: 130px;
    }

    &.active {
      width: 150px;
      .ic-gou {
        display: block;
      }
    }
  }
  .wtdc-form-item-error-message {
    visibility: hidden;
  }
  .modify-modal_save-btn {
    margin-left: 100px !important;
  }
}
