@charset "UTF-8";
@import "~@/assets/sass/_function";

.Modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: $zIndexOverlay;
  .real-modal {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
