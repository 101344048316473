@import "~@/assets/sass/_function";

.RelateRaysBook-7qVdP777 {
  display: flex;
  justify-content: space-between;
  .batchSwitch {
    display: none;
  }
  .content-wrap {
    &--erp {
      width: 49%;
    }
    &--rays {
      width: 47%;
    }
  }

  .book {
    &__text {
      &--name {
        font-weight: bold;
        margin-bottom: 4px;
      }
      &--ray-edu {
        display: inline-block;
        background-color: rgba(253, 51, 28, 0.1);
        border-radius: 2px;
        font-size: 12px;
        color: #fd331c;
        padding: 0 6px;
        height: 20px;
      }

      &--isbn,
      &--publisher,
      &--user-count,
      &--scan-count {
        margin-bottom: 2px;
        color: #80798d !important;
        line-height: 21px;
      }

      &--link {
        color: $text-color-dark;
        cursor: pointer;
        text-decoration: underline;
      }

      &--not-link {
        color: $primary-color;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .state {
    color: $text-color;
    white-space: nowrap;
    &--normal {
      color: #333;
    }
    &--waiting {
      color: #999;
    }
    &--ing {
      color: #666;
    }
    &--finish {
      color: #19bfbd;
    }
    &--warn,
    &--back-ic {
      color: #ff7295 !important;
    }
    &--back-reason {
      color: #80798d;
      text-decoration: underline;
      cursor: default;
    }
  }
  .icon_rays7 {
    width: fit-content;
    background-color: rgba(78, 80, 184, 0.1);
    border-radius: 2px;
    font-size: 12px;
    color: #4e50b8;
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
  }
  
}

.SearchBar-Bk8kXbbb,
.SearchBar-mAHP8PPP {
  &.erp {
    // width: 49%;
  }
  &.rays {
    width: 100%;
  }
  display: inline-block;
  .list-type {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
    display: inline-block;
  }
  .search-tips {
    color: #80798d;
    margin-left: 10px;
    display: inline-block;
  }

  .search-group {
    .wtdc-form-item,
    .btn-primary {
      margin-bottom: 15px;
    }
  }
  .exportData {
    background-color: white !important;
    border: 1px solid #ddd !important;
    color: #3a3446 !important;
    margin-bottom: 3px !important;
    margin-left: 30px;
  }
  .exportData:hover {
    color: #4e50b8 !important;
    border: 1px solid #4e50b8 !important;
  }
  .agentSelect {
    .wtdc-form-item-label {
      line-height: 32px;
    }
  }
}
.replace-xihjf-modal {
  .modal-container {
    .replace-xihjf-modal-text{
      text-align: left;
      padding: 0 30px;
      display: inline-block;
      .redText {
        color: red;
      }
    }
    .modal-footer {
      .cancel-btn {
        display: none;
      }
    }
  }
}
