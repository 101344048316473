@import "~@/assets/sass/_function";

.EditPermission-jgLnsuFF {
  height: calc(100% - 60px);
  font-size: 15px;
  color: #3a3346;
  .wtdc-form {
    height: 100%;
    .wtdc-form-item {
      .wtdc-form-item-label {
        padding-left: 10px !important;
      }
    }
    .ChangeRecordWrap {
      height: calc(100% - 32px);
    }
  }
  .account {
    &__button {
      width: 110px;
      margin-left: 144px;
    }

    &__button {
      width: 110px;
      height: 36px;
      margin-left: 96px;
    }

    .wtdc-form-item-label-text {
      padding-right: 6px;
    }

    .menu {
      &__label {
        min-width: 120px;
        padding: 0 25px 0 10px;
      }

      &__table {
        width: 642px;
        border-radius: 4px;
        border: solid 1px #ddd;
        border-bottom: none;
        text-align: left;
        font-weight: normal;
        border-collapse: separate;

        .wtdc-checkbox {
          margin-right: 30px;
          line-height: 36px;
          height: 36px;
          display: inline-block;
          vertical-align: middle;
        }
        .operate_title {
          margin-right: 0;
        }
        thead {
          tr {
            background-color: rgba($colorPrimary, 0.1);
            height: 50px;

            th {
              font-weight: normal;
              padding: 0 20px;
              border-bottom: 1px solid #ddd;
            }
          }
        }

        tbody {
          tr {
            min-height: 60px;

            td {
              border-left: 1px solid #ddd;
              border-bottom: 1px solid #ddd;
              padding: 11px 20px;
              .publish {
                color: #4e50b8;
                vertical-align: sub;
                cursor: pointer;
              }
              &:first-child {
                border-left: 0;
                width: 155px;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
    .projectStatus {
      .menu__table {
        border-bottom: solid 1px #ddd;
      }
      .wtdc-checkbox-group {
        padding: 10px 0 10px 20px;
        .wtdc-checkbox {
          min-width: 28%;
        }
      }
    }
  }
}

.AgentSettingModal {
  .agent-setting {
    &-table {
    }
  }
  .agent-setting-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 480px;
    border-bottom: 1px solid #ddd;

    &-thead {
      border-radius: 4px;
      text-align: center;
      background-color: rgba(78, 80, 184, 0.1);
      .checked-all,
      .checked-publish {
        flex: 1;
        padding: 5px;
      }
      .checked-all {
        border-right: 1px solid #ddd;
      }
    }

    &-tbody {
      flex: 1;
      &-left,
      &-right {
        flex: 1;
        padding: 0 5px;
        .agent-setting-cell {
          padding: 0 10px;
          height: 40px;
          display: flex;
          align-items: center;
          .wtdc-checkbox {
            display: flex;
            align-items: center;
            .wtdc-checkbox-title {
              @include ellipsis-lines(1);
            }
          }

          .agent-setting-publish {
            @include ellipsis-lines(1);
          }

          .anticon-delete {
            margin-left: 10px;
            font-size: 16px;
            cursor: pointer;
          }
          &:hover {
            color: $colorPrimary;
          }
        }
      }
      &-left {
        position: relative;
        border-right: 1px solid #ddd;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .wtdc-pagination {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          text-align: center;
        }
      }
    }
  }
}
