@import "~@/assets/sass/_function";

.AddNewSeries-Eblb_To {
  .modal-content {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 50px;
    .wtdc-form-item-label {
      padding-right: 24px;
    }
  }
}
