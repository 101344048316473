@import "~@/assets/sass/_function";

.RelateBookReview {
  height: 100%;

  .reason {
    cursor: pointer;
    @include ellipsis-lines;
  }
}
