@charset "UTF-8";
@import "~@/assets/sass/_function";

.Tooltip {
  display: inline-block;
  vertical-align: middle;
  .ic-question,
  .ic-questionfill {
    color: #999;
    font-size: 14px;
    vertical-align: baseline;
  }
  &:hover {
    .ic,
    .ic-question,
    .ic-questionfill {
      color: rgba($secondary-color, 0.5);
    }
  }
}

.ToolTipContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10000;
  .tool-tip-inner {
    position: absolute;
    padding: 12px 15px 12px 18px;
    border-radius: 3px;
    box-shadow: 1px 1px 6px 0 rgba(102, 102, 102, 0.4);
    background-color: $white;
    white-space: pre-line;
    color: #333;
  }

  &.HIDDEN {
    overflow: hidden;
  }

  &:not(.HIDDEN) {
    .tool-tip-inner {
      transition: transform 0.3s;
    }
  }
}
