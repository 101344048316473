@import "~@/assets/sass/_function";

.QrcodeSelectItem-EHD2QH55 {
  width: 510px;
  height: 130px;
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 1px #dddddd;
  padding: 20px;
  // flex:1;
  display: inline-block;
  // display: flex;
  &.active {
    background-color: rgba(78, 80, 184, 0.05);
    border: solid 1px rgba(78, 80, 184, 0.5);
  }
  .item-wrap {
    display: flex;
  }
  .QrcodeSelectItem-right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 368px;
  }
  .groupQrcodeUrl {
    width: 90px;
    height: 90px;
    border-radius: 4px;
    margin-right: 10px;
    overflow: hidden;
  }
  .title {
    display: flex;
    align-items: center;
    .qrcodeType {
      padding: 0 3px;
      height: 20px;
      line-height: 18px;
      font-size: 14px;
      border: 1px solid #fd6b6b;
      border-radius: 2px;
      margin-right: 10px;
      &.type1 {
        color: red;
        border-color: red;
      }
      &.type2 {
        color: #fd6b6b;
        border-color: #fd6b6b;
      }
      &.type4 {
        color: #4989ff;
        border-color: #4989ff;
      }
      &.type5 {
        color: #26db6f;
        border-color: #26db6f;
      }
    }
    .groupQrcodeName {
      color: #3a3446;
      font-size: 16px;
      font-weight: bold;
      @include ellipsis-lines(1);
    }
  }
}
