@import "~@/assets/sass/_function";

.ProjectFileLayer-VP9Iccg {
  .modal-container {
    min-width: 425px;
  }
  .modal-content {
    padding: 30px 20px;
  }
  .wtdc-radio-group-item {
    width: 156px;
    padding: 0 10px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    color: #333;
    margin-right: 90px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .wtdc-radio-title {
      margin-left: 0;
      height: 100%;
      width: 120px;
      @include ellipsis-lines(1);
    }
    .wtdc-radio-symbol {
      float: right;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      background-color: rgba(225, 222, 236, 0.4);
      border-radius: 2px;
    }
  }
}
