@import "~@/assets/sass/_function";

.MayNotQrcodeLayer-QbNLzmAA {
  .modal-content {
    padding: 10px 30px 20px;
    height: 695px;
    position: relative;
  }
  .wtdc-pagination {
    position: absolute;
    bottom: 20px;
    @include center-translate(x);
  }
  .qrcode {
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    &__name {
      color: #3a3446;
      font-weight: bold;
    }
    &__info {
      width: calc(100% - 100px);
      color: #3a3446;
    }

    &__resource {
      &.no-resource {
        span {
          color: $warning-color;
        }
      }
    }
  }
}
