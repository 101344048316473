.FundData-TGq7G7q {
  .searchAll {
    color: #4e50b8;
    text-decoration: underline;
    cursor: pointer;
  }
  .layer-time-content {
    margin-bottom: 15px;
  }

  .layer-item-title {
    font-weight: bold;
  }

  .modal-content {
    padding: 30px 30px 25px 30px;
  }
}
