@import "~@/assets/sass/_function";

.PlanTemplate-7qVdP777 {
  height: 100%;
  .planNumber {
    .planNumber-bottom {
      display: flex;
      margin-top: 2px;
      .new {
        width: 24px;
        height: 20px;
        line-height: 20px;
        background-color: #ff7295;
        border-radius: 2px;
        color: #fff;
        text-align: center;
        line-height: 20px;
        margin-right: 6px;
      }
      .old {
        width: 24px;
        height: 20px;
        line-height: 20px;
        background-color: #ecebed;
        border-radius: 2px;
        text-align: center;
        line-height: 20px;
        margin-right: 6px;
      }
      .isSpecial {
        width: 60px;
        height: 20px;
        background-color: #ecebed;
        border-radius: 2px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
      }
    }
  }
  .previewImg {
    width: 80px;
    height: 80px;
  }
  .th-planClassify {
    width: 20%;
  }
  .th-planNumber {
    width: 20%;
  }
  .th-previewImg {
    width: 20%;
  }
  // .th {
  //   width: 20%;
  // }
  .th-previewImg,
  .td-previewImg {
    padding-left: 30px !important;
  }
  .td:last-of-type {
    width: 1px !important;
    white-space: nowrap;
    text-align: left;
  }
  .td-planClassify {
    padding-right: 30px !important;
  }
  .resource-info {
    display: flex;
    .wtdc-icon {
      font-size: 60px;
      margin-right: 14px;
      height: 50px;
      line-height: 50px;
    }
    .resource-content {
      position: relative;
      .resource-name {
        width: 280px;
        color: #3a3446;
        font-weight: bold;
      }
      .resource-type-name {
        color: #80798d;
        // margin-top: 10px;
      }
    }
  }
  .th:first-child,
  .td:first-child {
    padding-left: 40px !important;
  }
}
.tooltip-zTG4i8KK {
  .tool-tip-inner {
    padding: 20px;
  }
  .more-btn {
    cursor: pointer;
    font-size: 14px;
    color: #666;
    display: block;
    background: none;
    border: none;
    &:hover {
      color: #3f51b5;
    }
    &:nth-child(2) {
      margin-top: 10px;
    }
    &:nth-child(3) {
      margin-top: 10px;
    }
  }
}
.templateDelete-confirm {
  .confirm-modal-subtitle {
    color: #333;
    font-size: 18px;
    font-weight: bold;
  }
  .confirm-modal-content {
    color: #333;
    font-size: 16px;
  }
}
// .formContent-left{
//   display: inline-block;
//   width:calc( 100% - 140px);
//   .mb20{
//     margin-bottom: 15px!important;
//   }
// }
.PlanTemplate-searchForm {
  display: flex;
  align-items: flex-start;
  .formContent-left {
    flex: 1;
    .mb20 {
      margin-bottom: 15px !important;
    }
    .BookLabel {
      .booklabel__item--wrapper {
        margin-bottom: 0 !important;
        .wtdc-input-affix-wrapper {
          overflow: hidden;
        }
      }
    }
  }
}
