@import "~@/assets/sass/_function";
.TaskStep {
  display: flex;
  align-items: center;
  justify-content: center;
  .task-item {
    padding: 0 4px;
    height: 32px;
    background: #ededed;
    display: flex;
    align-items: center;
    border-radius: 32px;
    color: #999999;
    .count {
      font-weight: bolder;
      width: 26px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      border-radius: 50%;
      background: #fff;
    }
    .text {
      line-height: 28px;
      font-size: 16px;
      padding: 0 10px;
    }
  }
  .split {
    height: 5px;
    width: 80px;
    background: #ededed;
  }
  .split-active {
    background: $primary-color;
  }
  .task-item-active {
    background: $primary-color;
    color: #fff;
    .count {
      color: $primary-color;
    }
  }
}
