@import "~@/assets/sass/_function";

.TemplateItem {
  // width: 340px;
  width: 19%;
  min-width: 300px;
  background: #f6f5f9;
  border-radius: 4px;
  padding: 20px 30px 30px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-right: 1%;
  position: relative;

  border: 2px solid transparent;
  .item-delelte {
    font-size: 24px;
    position: absolute;
    top: 6px;
    right: 6px;
    color: #666;
    cursor: pointer;
    display: none;
  }
  .item-head {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    &-name {
      font-size: 16px;
      font-weight: 600;
    }
    &-update {
      flex-shrink: 0;
      margin-right: -10px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      height: 0;
      opacity: 0;
      cursor: pointer;
      img {
        width: 15px;
        height: 15px;
      }
    }
  }
  .item-type {
    margin-bottom: 25px;
  }
  .item-content {
    &-box {
      // display: flex;
      // flex-wrap: wrap;
      &-text {
        border-radius: 4px;
        border: 1px solid #dddddd;
        text-align: center;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        @include ellipsis-lines(1);
      }
      &-update {
        width: 150px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #3f51b5;
        border: 1px dashed #3f51b5;
        margin-top: 30px;
        margin-left: 60px;
        margin-right: -10px;
        cursor: pointer;
        .update-ic {
          font-size: 15px;
        }
      }
    }
  }
  &:hover {
    border: 2px solid #3f51b5;
    .item-head-update {
      height: 24px;
      opacity: 1;
    }
  }
}
