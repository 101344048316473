@import "~@/assets/sass/_function";

.DataOverview-LJqKw2yy {
  padding: 30px 10px;
  margin-right: -30px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .data-title {
    font-size: 20px;
    font-weight: bold;
    color: #3a3446;
    margin-bottom: 30px;
  }

  .warning {
    &__item {
      width: 310px;
      height: 140px;
      background-color: rgba(225, 222, 236, 0.4);
      border-radius: 10px;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #3a3446;
      margin-right: 28px;
      margin-bottom: 30px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: rgba(#e1deec, 0.8);
      }

      &--number {
        font-size: 50px;
        line-height: 58px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      &--title {
        font-size: 16px;
      }
    }
  }
  .overview-bottom {
    flex: 1;
    display: flex;
  }
  .summary {
    width: 40%;
    .data-title {
      display: flex;
      margin-bottom: 15px;
      flex-wrap: wrap;
      .title {
        margin-bottom: 15px;
      }
    }
    &__content {
      height: 500px;
    }
    .tips-btn {
      margin-right: 30px;
    }

    &__state {
      font-weight: normal;
      // margin-left: 30px;
    }
  }

  .near {
    width: 60%;

    &__content {
      height: 500px;
    }
    .data-title {
      padding-left: 75px;
    }
  }

  .tips-btn {
    font-size: 14px;
    color: #b3b0b9;
    margin-left: 5px;
    cursor: pointer;
    margin-top: -2px;
    &:hover {
      color: rgb(78, 80, 183);
    }
  }
}

.Tooltip-rGNosCSS {
  .tips-title {
    font-size: 14px;
    color: #3a3446;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .tips-content {
    font-size: 14px;
    color: #3a3446;
  }
}
@media (max-width: 1360px) {
  .summary .wtdc-radio {
    margin-right: 38px;
  }
}
@media (max-width: 1280px) {
  .summary .wtdc-radio {
    margin-right: 33px;
  }
}
