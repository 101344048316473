@import "~@/assets/sass/_function";

.BatchAddUploader-Eblb_To {
  .uploader-wrapper {
    display: flex;
    flex-wrap: wrap;
    .uploader-checkbox {
      width: 33%;
    }
  }
  .modal-content {
    min-height: 170px;
  }
}
