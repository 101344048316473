@import "~@/assets/sass/_function";

.ServiceOrder-NYHESHSS {
  .resourceContent {
    display: flex;
    flex-direction: column;

    .upload {
      display: flex;
      margin-bottom: 30px;
      &__title {
        font-size: 15px;
        color: #3a3346;
        margin-right: 20px;
        display: inline-flex;
        flex-shrink: 0;
      }
      .ResourcePickerDialog-lwUXrNw {
        display: inline-flex;
        height: auto;
        width: 100% !important;
        .resource-list,
        .resource-list__item-title {
          width: 100% !important;
        }
      }
      &:first-child {
        height: auto;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
