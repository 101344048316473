@import "~@/assets/sass/_function";

.RebateOrder-5AXnnC {
  .mb24 {
    margin-bottom: 24px;
  }

  .rebate-input {
    margin: 0 10px;

    .wtdc-form-item-label,
    .wtdc-input-counter {
      display: none;
    }
  }

  .wtdc-input-disabled,
  .wtdc-select-disabled,
  .wtdc-textarea-disabled {
    background-color: transparent !important;
    .wtdc-select-selector,
    textarea {
      background-color: transparent !important;
    }
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-just {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex1 {
    flex: 1;
  }
  .flex2 {
    flex: 2;
  }

  .rebate-title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
  }
  .rebate-subtitle {
    margin: 15px 0;
    font-size: 15px;
    font-weight: 600;
  }
  .rebate-label {
    display: inline-flex;
    width: 150px;
    margin-right: 20px;
    white-space: nowrap;
  }
  .rebate-report {
    &-item {
      margin: 15px 0;
      .wtdc-form-item-label {
        width: 150px;
        margin-right: 20px;
        white-space: nowrap;
      }
    }
  }
  .rebate-item {
    margin-bottom: 20px;
    .FormItem {
      width: 104px;
      margin: 0 10px;

      .subtitle {
        display: none;
      }
      .form-control {
        width: 100%;
      }
    }

    .rebate-table {
      max-width: 65%;
      .rth {
        &-item {
          padding: 10px 30px;
          background: #f6f5f9;
          font-weight: 600;
        }
      }
      .rtc {
        border-bottom: 1px solid $grayE;
        &-save {
        }
        &-item {
          height: 140px;
          padding: 16px 20px;
          .wtdc-textarea,
          .wtdc-form-item-children-wrapper {
            height: 100%;
            width: 100% !important;
            textarea {
              height: 100% !important;
            }
          }
          .btn-delete {
            color: $colorPrimary;
            cursor: pointer;
            text-decoration: underline;
          }
        }
        &-add {
          padding: 15px 30px;
          border-bottom: 1px solid $grayE;
          .underline {
            color: $colorPrimary;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .report-detail {
    .wtdc-form-item-label {
      display: none;
    }
  }
  .difference-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    button {
      margin: 0 10px;
    }
  }
}
.save-and-check {
  .wtdc-form-item-label {
    width: 120px;
    text-indent: 10px;
  }
  .ant-alert.ant-alert-no-icon {
    padding: 4px 10px;
    margin-bottom: 15px;
  }
}

.wtdc-select-dropdown {
  z-index: 9999;
}
