@import "~@/assets/sass/_function";

.BookInfo-mglnIrg {
  .tips-box {
    flex-wrap: wrap;
    .book-tips {
      margin-right: 10px;
      margin-bottom: 5px;
    }
  }

  .book-origin {
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 4px;
    background-color: #eee;
    border-radius: 2px;
    font-size: 12px;
    color: #333;
  }
  .book {
    &--isBeaconBook {
      background: #ffa246;
      color: white;
    }
    &--shelve {
      background-color: $primary-color;
      color: $white;
      padding: 0 6px;
      border-radius: 2px;
      font-size: 12px;
      height: 20px;
      line-height: 20px;
      margin-bottom: 4px;
    }
    &--isInformationLoss {
      background-color: #ff3300;
      border-radius: 2px;
      font-size: 12px;
      color: #fff;
      padding: 0 6px;
      height: 20px;
      line-height: 20px;
      margin-bottom: 4px;
    }
    &--isRepeat {
      vertical-align: text-bottom;
      cursor: pointer;
      color: #fd7295;
      font-size: 12px;
      height: 21px;
      line-height: 22px;
      margin-right: 8px;
      span {
        margin-left: 4px;
        text-decoration: underline;
      }
    }
    &--hasSample {
      background-color: #fa3;
      height: 20px;
      line-height: 20px;
      color: #fff;
      font-size: 12px;
      padding: 0 6px;
      border-radius: 2px;
    }

    &--name {
      font-weight: bold;
      margin-bottom: 4px;
    }

    &--isbn,
    &--publisher {
      margin-bottom: 2px;
      color: #80798d !important;
      line-height: 21px;
    }

    &--link {
      color: $text-color-dark;
      cursor: pointer;
      text-decoration: underline;
    }

    &--not-link {
      color: $primary-color;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .EditableBlock-oZ3IOEO {
    display: block;
    padding-bottom: 0px;
  }
}

.tooltip-wT72Ac7w {
  .tool-tip-inner {
    width: auto !important;
  }
  .book {
    min-width: 200px;
    &__img {
      vertical-align: top;
      .wtdc-img-inner {
        border-radius: 4px;
        border: solid 1px #ddd;
      }
    }
    &__text {
      width: calc(100% - 70px);
      &--name {
        font-weight: bold;
      }
      &--isbn,
      &--publisher {
        color: $text-color;
      }
    }
  }
}

.modify-modal_1ZmZOUZZ {
  min-height: 155px;
  .wtdc-form-item-label {
    min-width: 45px;
  }
  .tips {
    color: #80798d;
    margin-left: 45px;
    margin-top: -15px;
  }
  .modify-modal_save-btn {
    margin-top: 15px !important;
    margin-left: 0 !important;
  }
}
