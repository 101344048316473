@import "~@/assets/sass/_function";

.AccountList-mqLLILEE {
  .user {
    min-width: 200px;
    .WTDCImg-EkIN2Gdd {
      display: inline-block;
    }
    &__img {
      img {
        border-radius: 30px;
      }
    }
    .disable-login {
      background-color: rgba(253, 51, 28, 0.1);
      border-radius: 2px;
      font-size: 12px;
      color: #fd331c;
      padding: 0 6px;
      height: 20px;
      line-height: 20px;
      margin-bottom: 4px;
    }
  }
  .td:last-of-type {
    width: 1px !important;
    white-space: nowrap;
    text-align: left;
  }
}
.disabledLogin-modal {
  .confirm-modal-subtitle {
    font-size: 18px;
    color: #333;
    font-weight: bold;
    margin-top: 20px;
  }
  .confirm-modal-content {
    font-size: 16px;
    color: #333;
    line-height: 26px;
  }
}
