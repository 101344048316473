@import "~@/assets/sass/_function";
.BookScheme {
  .bookInfo {
    .tagsBox {
      display: flex;
      flex-wrap: nowrap;
      > div:first-child {
        flex-shrink: 0;
      }
    }
    .primaryText {
      color: $primary-color;
    }
  }
  .ProcessStatus-gqr1ogE {
    min-width: 60px;
    padding-right: 0;
  }
  .status-btn {
    text-decoration: none;
    display: inline-block;
    border: 1px solid $primary-color;
    border-radius: 16px;
    padding: 0 8px;
    color: $primary-color !important;
  }
  .search-group {
    margin-bottom: 10px;
    margin-left: -10px;
  }
  .projectFileVOList {
    a {
      display: block;
      width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .schemePlanTime {
    margin: 10px 0 10px 10px;
  }
}
