@import "~@/assets/sass/_function";

.CancelProject-6SA1QTJJ {
  .modal-content {
    height: 420px;
    .wtdc-form-item-label {
      vertical-align: top;
    }
  }
  .cancelContent {
    padding: 20px 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .wtdc-icon-dengpaotishi {
      display: block;
      font-size: 60px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin: auto;
    }
    .title {
      font-size: 18px;
      color: #333;
      margin-top: 20px;
      margin-bottom: 20px;
      font-weight: bold;
      line-height: 1;
    }
    .tips {
      width: 100%;
      font-size: 16px;
      color: #333333;
      margin-bottom: 30px;
      letter-spacing: 0.5px;
      padding-left: 8px;
    }
    .wtdc-form-item {
      align-self: start;
      margin-bottom: 0px;
      &.text-area {
        .wtdc-form-item-label {
          width: 72px;
        }
      }
      &.cancelContent-select {
        .wtdc-form-item-label {
          margin-top: 0px;
          vertical-align: middle;
        }
      }
      .wtdc-form-item-label {
        margin-top: 8px;
      }
      .wtdc-form-item-error-message {
        display: none;
      }
    }
    .imagePicker-content {
      width: 460px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}
