@import "~@/assets/sass/_function";
.EmailNotice-qQnIN_ {
  .book__button {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .wtdc-form-item-label {
    padding-right: 10px;
  }
  .text-area {
    margin-bottom: 0px !important;
    .wtdc-form-item-children-wrapper {
      margin-right: 0px !important;
    }
  }
  .form-content {
    display: flex;
    flex-wrap: wrap;

    .form-item {
      width: 35%;
      margin-top: 30px;
      .form-item-title {
        font-size: 16px;
        font-weight: bold;
        color: #3a3446;
        margin-bottom: 10px;
      }
      .form-item-content {
        background-color: #f6f5f9;
        border-radius: 4px;
        padding: 20px 0px 20px 20px;
      }
      .itemDay-title,
      .itemSend-email {
        display: inline-block;
        vertical-align: -webkit-baseline-middle;
      }
      .wtdc-form-item {
        display: inline-block;
      }
      .wtdc-form-item-label {
        line-height: 30px !important;
      }
      .text-area {
        width: calc(100% - 20px);
        .wtdc-form-item-label {
          vertical-align: top;
        }
        .wtdc-form-item-children-wrapper {
          width: 100%;
          .wtdc-textarea {
            width: 100% !important;
          }
        }
      }
      .wtdc-form-item-children-wrapper {
        margin-right: 10px;
      }
      .wtdc-form-item-label {
        font-size: 14px;
      }
    }
    .form-item:nth-child(1),
    .form-item:nth-child(3),
    .form-item:nth-child(5),
    .form-item:nth-child(7),
    .form-item:nth-child(9) {
      margin-right: 30px;
    }
  }
}
