@import "~@/assets/sass/_function";

.differenceOrder-5AXnnC {
  position: relative;

  .wtdc-input-disabled,
  .wtdc-calendar-input-disabled,
  .wtdc-select-disabled,
  .wtdc-select-selector {
    background-color: transparent !important;
  }

  .wtdc-form-item-error-message {
    display: none;
  }

  .difference-title {
    text-align: center;
  }

  .differenceOrder-item {
    display: flex;
    .wtdc-form-item {
      margin-right: 30px;
    }
    .wtdc-form-item-label {
      width: 150px;
      margin-right: 23px;
      white-space: nowrap;
    }
  }

  .padding-item {
    padding: 15px 20px;
  }

  .difference-table {
    width: 100%;
    border: 1px solid #000;

    .cell-item {
      padding: 15px 20px;
      line-height: normal;
      text-align: left;
    }

    .wtdc-form-item {
      height: 54px;
      margin: 0;
      .wtdc-form-item-label {
        display: none;
      }
      .wtdc-form-item-children-wrapper,
      .wtdc-input,
      .wtdc-input-number-input-wrap,
      .wtdc-input-number,
      .wtdc-calendar-picker,
      .wtdc-select {
        width: 100% !important;
        height: 100%;
      }
      .wtdc-calendar-input {
        height: 54px;
      }
      .wtdc-input-number-handler-wrap {
        display: none;
      }
      .wtdc-select-selection-item,
      .wtdc-radio-group,
      .wtdc-select-selection-placeholder {
        line-height: 54px;
        text-align: center;
        width: 100%;
      }
      .wtdc-input-affix-wrapper,
      .wtdc-input-number,
      .wtdc-select-selector {
        border-color: transparent !important;
      }
      input {
        height: 100%;
        width: 100%;
        border: transparent;
        box-shadow: none;
        text-align: center;
        background-color: transparent;
      }
    }
  }

  .border-none {
    border: transparent;
  }

  td {
    border: 1px solid #000;
    background-color: #fff;
  }

  .difference-bottom {
    padding: 8px 0;
    background-color: $white;
    text-align: center;
    transition: all 250ms cubic-bezier(0.1, 0.57, 0.1, 1);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.06);
  }
}
