@import "~@/assets/sass/_function";

.PlanChangeLayer-G99WXIuu {
  .modal-container {
    height: 750px;
    min-width: 1000px;
  }
  .modal-content {
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 50px;
  }
  .modal-table-cell {
    padding-bottom: initial;
  }
  .modal-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
  .subtitle {
    font-size: 15px;
    font-weight: bold;
    color: #3a3346;
    margin-bottom: 20px;
  }
  .contentUeditor {
    display: flex;
    flex: 1;
    .wtdc-form-item-children-wrapper {
      flex: 1;

      .edui-editor {
        // height: 600px;
        // overflow: auto;
      }
    }
  }
}
