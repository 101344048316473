@import "~@/assets/sass/_function";

.ProjectMap-0Un1UH00 {
  // height:910px;
  padding-bottom: 50px;
  // margin-top:30px;
  .title-content {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .title {
      font-size: 20px;
      color: #3a3446;
      font-weight: bold;
      margin-right: 30px;
    }
    .wtdc-form-item {
      margin-bottom: 0;
      .wtdc-form-item-label {
        color: #3a3346;
        padding-right: 10px;
      }
      .wtdc-select {
        width: 200px !important;
      }
    }
  }
  .map-content {
    height: 800px;
  }
}
