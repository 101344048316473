@import "~@/assets/sass/_function";

.SummaryDetail-QbNLzmAA {
  .modal-container {
    height: 560px;
    .modal-content {
      height: 520px;
      padding: 30px 0;
      .chart-content {
        height: 432px;
      }
    }
    .switch-tab {
      display: flex;
      width: 120px;
      margin-left: 30px;
      .item {
        color: #80798d;
        border-radius: 4px 0 0 4px;
        height: 28px;
        width: 60px;
        line-height: 26px;
        text-align: center;
        border: 1px solid #ddd;
        cursor: pointer;
        &:last-child {
          border-radius: 0 4px 4px 0;
          border-left: 1px solid #ddd;
        }
        &.active {
          background: #4e50b8;
          color: #fff;
          border-color: #4e50b8;
        }
      }
    }
  }
}
