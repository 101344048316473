@import "~@/assets/sass/_function";

.ReportList-mqLLILEE {
  .inspect-qrcode {
    .wtdc-img-inner,
    .load-error {
      display: none;
    }
  }
  .wtdc-select {
    input {
      background-color: transparent;
      height: 32px;
    }
    .wtdc-select-selector {
      height: 32px;
      line-height: 32px;
    }
    .wtdc-select-selector {
      .wtdc-select-selection-item {
        height: 25px;
        line-height: 25px;
        margin: 2px 5px 0 0;
      }
    }
  }
  .screen-margin {
    @media (max-width: 1540px) {
      margin-top: 20px;
    }
  }

  .book-origin {
    display: inline-block;
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 4px;
    margin-top: 4px;
    background-color: #eee;
    border-radius: 2px;
    font-size: 12px;
    color: #333;
  }

  .book-check-type {
    display: inline-block;
    padding: 0 6px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 4px;
    margin-left: 10px;
    margin-top: 4px;
    background-color: #eee;
    border-radius: 2px;
    font-size: 12px;
    color: #fff;
    &.normal {
      background-color: #a0a0e3;
    }
    &.market {
      background-color: #84ddfd;
    }
  }
}
