@import "~@/assets/sass/_function";

.BatchParticipantsChange-TGq7G7q {
  .tips {
    padding-left: 120px;
    margin-top: 10px;
    color: #80798d;
  }
  .receiveBooker {
    .wtdc-form-item-label {
      width: 95px;
    }
  }
  .modal-content {
    padding-top: 30px;
    // height: 422px;
    padding-left: 60px;
  }
  .wtdc-form-item-label {
    padding-right: 20px;
  }
  .modal-content-scroll {
    overflow: initial !important;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
  .uploader-list {
    .wtdc-select-selection-search-input {
      background: transparent;
    }
  }
}
