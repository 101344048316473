@import "~@/assets/sass/_function";

.ProjectDetail-QNjwqTYY.active {
  .iconBox {
    .wtdc-icon-fullscreen {
      margin-right: 24px !important;
      cursor: pointer;
    }
    .wtdc-icon-shanchuguanbi {
      cursor: pointer;
    }
  }
}
.ProjectDetail-QNjwqTYY {
  height: 100%;

  .project-back {
    transform-origin: 50% 50%;
    transform: rotate(90deg);
    margin-right: 5px;
    cursor: pointer;
  }

  .BasicInfo-30vgqtnn,
  .PublicationInfo-WsHY0Xb {
    padding: 0 50px;
    padding-left: 24px;
  }
  .lostInfo-box {
    background-color: rgba(255, 51, 0, 0.1);
    border-radius: 4px;
    color: #ff3300;
    padding: 8px 0 8px 10px;
    .wtdc-icon {
      color: #ff3300;
    }
  }
  &__head {
    padding: 20px 30px 30px;
    padding-left: 4px;
    width: 100%;
    background-color: $white;

    .title {
      font-size: 20px;
      color: #3a3346;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  .book {
    padding-bottom: 30px;
    color: $text-color;
    .book-info-box {
      width: 400px;
    }
    &-wrap {
      display: flex;
      justify-content: space-between;
    }
    &__img {
      width: 60px;
      height: 90px;
      img {
        border-radius: 4px;
      }
    }
    &__text {
      flex: 1;
      margin-right: 15px;
      color: #000000;
      flex-wrap: wrap;
      &--name {
        color: #3a3446;
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 10px;
      }
      > div {
        width: 300px;
        display: inline-block;
        margin-bottom: 10px;
      }
    }
    .wtdc-form-item-label {
      white-space: initial;
    }
  }
  .publish {
    padding-bottom: 30px;
  }

  .ChangeRecord {
    padding: 0 30px;
    tr {
      td:nth-child(1) {
        width: 10%;
      }
      td:nth-child(2) {
        width: 15%;
        padding: 25px 50px 25px 10px;
      }
      td:nth-child(3) {
        width: 10%;
      }
      td:nth-child(4) {
        width: 15%;
      }
      td:nth-child(5) {
        width: 25%;
      }
      td:nth-child(6) {
        width: 25%;
      }
    }
    .project-file {
      margin-bottom: 5px;
      display: flex;
      white-space: nowrap;
    }
    .file {
      &-type {
        width: 84px;
        vertical-align: top;
        display: inline-block;
      }
      &-info {
        width: 100%;
      }
      &-wrap {
        vertical-align: middle;
        margin-right: 5px;
        display: inline-flex !important;
        white-space: initial;
        &--upload {
          color: #4e50b8;
          .ic {
            margin-right: 3px;
          }
        }
      }
      &-ic {
        font-size: 17px;
      }
      &-name {
        cursor: pointer;
        text-decoration: underline;
        .ic-download {
          margin-left: 8px;
          display: none;
        }
        &:hover {
          color: #4e50b8;
          .ic-download {
            display: inline-block;
          }
        }
      }
      &-preview {
        white-space: nowrap;
        cursor: pointer;
        font-size: 14px;
        color: #666;
        margin-left: 10px;
        &:hover {
          color: #4e50b8;
        }
      }
    }
    .operate-content {
      line-height: 1.8;
    }
    .imgPreview {
      vertical-align: middle;
      position: relative;
      display: inline-block;
      margin-right: 10px;
      border-radius: 4px;
      border: 1px dotted #ddd;
      .WTDCImg-EkIN2Gdd {
        float: left;
        height: 45px;
      }
      .mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 45px;
        height: 45px;
        background-color: rgba(0, 0, 0, 0.4);
        color: #ddd;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0;
      }
      &:hover {
        .mask {
          opacity: 1;
        }
      }
    }
  }

  .TimeTable {
    background-color: $white;
    padding-left: 30px;
    min-height: calc(100vh - 326px);
    .time {
      position: relative;
      &__axis {
        position: absolute;
        top: 6px;
        left: 123px;
        width: 2px;
        height: calc(100% - 10px);
        background-color: #e1deec;
        border-radius: 2px;
        z-index: 0;
      }
      &__point {
        z-index: 1;
        position: relative;
        margin-bottom: 42px;
        &--time {
          font-size: 13px;
          color: $text-color;
        }
        &--spot {
          width: 15px;
          height: 15px;
          border-radius: 7px;
          background: #e1deec;
          margin: 0 14px;
        }
        &--event {
          font-size: 14px;
          color: $text-color-dark;
        }
        &.pass {
          .time__point--time,
          .time__point--event {
            color: $primary-color;
          }
          .time__point--spot {
            background: $primary-color;
          }
        }
        &.upload {
          .time__point--time,
          .time__point--event {
            color: $warning-color;
          }
          .time__point--spot {
            background: $warning-color;
          }
        }
      }
    }
  }

  .ProjectFile {
    min-height: calc(100vh - 326px);
    background-color: $white;
    padding-left: 54px;
    padding-bottom: 30px;
    .type {
      margin-bottom: 50px;
      color: $text-color;
      &__title {
        font-size: 16px;
        color: #3a3346;
        font-weight: bold;
        margin-bottom: 10px;
      }
      &__item {
        cursor: pointer;
        font-size: 14px;
        margin-bottom: 20px;
        &--name {
          color: #333;
          margin: 0 15px 0 3px;
          text-decoration: underline;
        }
        &--size {
          color: #999;
        }
      }
    }
  }
}
