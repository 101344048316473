@import "~@/assets/sass/_function";

.AddNetworkDisk-TGq7G7q {
  .content {
    .content-title {
      margin-bottom: 25px;
    }
  }
  .keywordsItem {
    .wtdc-form-item-label {
      margin-right: 30px;
    }
  }
  .content-textarea {
    .wtdc-form-item-label {
      vertical-align: top;
      margin-right: 30px;
    }
  }
  .code {
    .wtdc-form-item-label {
      margin-right: 15px;
    }
  }
  .use-range {
    .more-range {
      margin-top: 25px;
      color: #4e50b8;
      font-size: 15px;
      width: 100px;
      height: 20px;
      margin-bottom: 25px;
      cursor: pointer;
      .wtdc-icon {
        font-size: 18px;
        margin-left: 8px;
        font-weight: bold;
      }
      .filter-label-second {
        display: inline-block;
        margin-bottom: 25px;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .use-range-title {
      font-size: 15px;
      color: #3a3346;
    }
    .tag-list {
      margin-bottom: 30px;
      margin-top: 20px;
      .wtdc-checkbox {
        margin-top: 20px;
        width: 255px;
        display: inline-block;
      }
      .wtdc-checkbox:nth-child(1),
      .wtdc-checkbox:nth-child(2),
      .wtdc-checkbox:nth-child(3) {
        margin-top: 0;
      }
    }
  }
}
