@import "~@/assets/sass/_function";
.AddTemplate {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  &-btn {
    width: 150px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #4e50b8;
    color: #4e50b8;
    cursor: pointer;
    &-ic {
      color: #3f51b5;
      font-size: 16px;
      margin-right: 5px;
    }
    &.center-btn {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .add-template {
    &-title {
      height: 32px;
      background: #ededf8;
      border-radius: 4px;
      line-height: 32px;
      padding-left: 10px;
    }
    &-box {
      display: flex;
      align-items: center;
      &-text {
        padding-left: 11px;
        // width: 70px;
        flex-shrink: 0;
        &.isRequired {
          position: relative;
          &::before {
            content: "*";
            transform: translateY(-50%);
            color: #ff3668;
            font-size: 15px;
            line-height: 1;
            position: absolute;
            left: 0;
            top: 12px;
            z-index: 10;
          }
        }
      }
    }
    &-content {
      // min-height: 663px;
      min-height: calc(100vh - 400px);
      position: relative;
    }
    &-footer {
      display: flex;
      justify-content: center;
      position: sticky;
      left: 0;
      bottom: 0;
    }
  }
  .ant-table {
    .typeCodeVOS-box {
      &-list {
        span {
          &::after {
            content: "，";
          }
          &:last-child {
            &::after {
              content: "";
            }
          }
        }
      }
    }
    .update-primary {
      color: #4e50b8;
      span {
        cursor: pointer;
      }
    }
  }
}
