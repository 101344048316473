@import "~@/assets/sass/_function";

.StaleData-iUKQoFNN {
  .table-btn {
    color: $colorPrimary;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
