@import "~@/assets/sass/_function";

@import "~@/assets/sass/_function";

.ChooseSample-6SA1QTJJ {
  .modal-content-scroll > div {
    overflow: initial !important;
  }
  .td-orderSheetCreateTimes {
    width: 20%;
  }
  .wtdc-pagination {
    margin-bottom: 40px;
  }
  .modal-content-scroll {
    height: 630px !important;
    max-height: 630px !important;
  }
  .modal-content-scroll > div {
    max-height: 652px !important;
  }
  .table-empty-tip {
    margin-bottom: 30px;
  }
  .resource-info {
    display: flex;
    .wtdc-icon {
      font-size: 60px;
      margin-right: 14px;
      height: 50px;
      line-height: 50px;
    }
    .resource-content {
      position: relative;
      .resource-name {
        //width: 280px;
        color: #3a3446;
        font-weight: bold;
      }
      .resource-type-name {
        color: #80798d;
      }
    }
  }
  .search-group {
    .wtdc-form-item {
      display: inline-block;
    }
    .wtdc-input {
      height: 36px;

      input {
        padding: 0 12px;
      }

      &:hover {
        .wtdc-input-affix-wrapper {
          border-color: rgba($colorPrimary, 0.5);
        }
      }

      &-focused {
        .wtdc-input-affix-wrapper {
          border-color: rgba($colorPrimary, 0.5);
        }
      }

      &-affix-wrapper {
        background-color: rgba(225, 222, 236, 0.3);
        border-radius: 4px;
        border-color: rgba(225, 222, 236, 0.3);
      }
    }

    button {
      height: 36px;
      background-color: $colorPrimary;
      border: none;
      border-radius: 4px;
      min-width: 60px;
      color: white;
      outline: none;
      &:hover {
        background-color: lighten($colorPrimary, 5%);
      }
    }
  }

  .wtdc-pagination {
    text-align: center;
  }

  .wtdc-table {
    height: 455px;
    .batchSwitch {
      display: none;
    }
  }

  .modal {
    &-content {
      padding: 20px 30px 0;
    }

    &-tips {
      color: $text-color-dark;
      font-size: 15px;
      margin-bottom: 20px;
      font-weight: bold;
    }
  }
}
