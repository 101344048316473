@import "~@/assets/sass/_function";

.TaskDetail {
  height: 100%;
  position: relative;
  .next-warpper {
    position: relative;
    display: flex;
    align-items: center;
    .next-btn {
      position: absolute;
      bottom: -10px;
      z-index: 100;
    }
  }
}
