@import "~@/assets/sass/_function";

.FieldSelectModal-TGq7G7q {
  .ant-modal-body {
    height: 500px;
    min-height: 70vh;
  }
  .modal-table-container {
    z-index: 9999;
    .modal-content-scroll {
      width: 98%;
    }
  }
  .isUnfold {
    cursor: pointer;
    font-size: 18px;
    color: #b3b0b9;
  }
  .filter-label,
  .filter-label-second {
    display: block;
    margin-bottom: 10px;
  }
  .filter-label {
    font-size: 16px;
    color: #3a3446;
  }
  .filter-label-second {
    font-size: 16px;
    color: #3a3446;
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
  }
  .wtdc-checkbox {
    width: 200px;
    display: inline-block;
    margin-bottom: 15px;
  }
  .basicFilter {
    padding: 0 15px 15px 15px;
  }
  .selectedFilter {
    .checkBox-content {
      background-color: rgba(63, 81, 181, 0.03);
      padding: 15px 15px 0 15px;
      .wtdc-checkbox {
        margin-bottom: 15px;
      }
    }
  }
  @media (max-width: 1600px) {
    .modal-container {
      width: 52% !important;
    }
  }
}
