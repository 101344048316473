@import "~@/assets/sass/_function";

.common__filter-wrappers_box:hover {
  color: $colorPrimary;
}
.filter-item {
  .StatusFilter-8ROOOPy {
    color: #333;
  }
}
.common__filter-wrappers {
  .wtdc-select-selection-search {
    color: $gray3;
  }
  .filter-end {
    color: #3a3446;
    margin-left: 10px;
    .wtdc-icon {
      cursor: pointer;
    }
  }
  .common__filter-wrappers_box {
    display: inline-block;
    // margin-right: 10px;
  }
  .shanchuguanbi {
    cursor: pointer;
  }
  .filter-contentList {
    .booklabel--wrapper {
      color: #333;
      margin-top: 15px;
      .booklabel--title {
        margin-bottom: 10px;
      }
      .SelectBookLabel-i9Lnkkii .booklabel__item--wrapper:last-child {
        margin-bottom: 5px;
      }
    }
    .filter-item {
      position: relative;
      margin-bottom: 10px !important;
      .shanchuguanbi {
        color: #80798d;
        cursor: pointer;
        margin-right: 20px !important;
        position: absolute;
        right: 0;
        top: 30px;
        font-size: 10px;
        display: none;
      }
      .wtdc-form-item-label {
        line-height: 40px !important;
      }
    }
    .filter-item:hover {
      .shanchuguanbi {
        display: block;
      }
    }

    .BookPdcLabel {
      display: flex;
      flex-direction: column;
      .wtdc-form-item-label {
        line-height: 40px;
      }
      .ant-cascader {
        width: 340px;
      }
    }
  }
  .raysAdviserId-wrapper {
    margin-right: 0px !important;
    .wtdc-form-item-label {
      width: 150px;
      height: 32px;
    }
  }

  .wtdc-calendar-picker-container {
    position: fixed !important;
    left: -190px !important;
  }
  .StatusFilter-8ROOOPy {
    .status-form__content {
      width: 340px !important;
    }
    .status-form__title {
      line-height: 40px !important;
    }
  }
  .modal-content-scroll > div:nth-child(2) {
    display: none;
  }

  .shaixuan {
    position: relative;
    top: 1px;
  }
  .shaixuan.active {
    color: #4e50b8;
  }
  .filter__button {
    cursor: pointer;
    .filter-title.active {
      color: #4e50b8;
    }
  }
  .submit {
    width: 60px;
  }
  .filter-content:hover {
    .modal-content-scroll > div:nth-child(3) {
      opacity: 1 !important;
    }
  }
  .filter-content {
    position: fixed;
    width: 400px;
    background-color: white;
    z-index: 4;
    right: -625px;
    top: 145px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px 0 20px 20px;
    min-height: 300px;
    .filter-content-title {
      font-size: 16px;
      color: #3a3446;
      font-weight: bold;
      margin-bottom: 10px;
      .shanchuguanbi {
        color: #80798d;
        margin-right: 20px !important;
      }
    }
    .searchMoreFilter {
      color: #4e50b8;
      cursor: pointer;
      position: absolute;
      bottom: 80px;
    }
    .filter-footer {
      height: 63px;
      position: absolute;
      bottom: 0;
      width: 90%;
      left: 20px;
      line-height: 63px;
      border-top: 1px solid #e9e9e9;
      padding: 0 20px 0 20px;
      .btn-primary {
        margin: 0 auto;
        display: block;
        margin-top: 15px;
      }
    }
  }
  .SelectProgramTypeInfo {
    padding-top: 0;
    .grayBg {
      width: 340px;
      margin-top: 10px;
      padding-bottom: 20px;
      margin-bottom: 0 !important;
    }
    .wtdc-input,
    .wtdc-select {
      width: 300px !important;
    }
  }
  @keyframes show {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(-648px);
    }
  }
  @keyframes hiddenLayer {
    from {
      transform: translateX(-648px);
    }
    to {
      transform: translateX(0px);
    }
  }
  .filter-content.show {
    display: block;
    animation: show 0.5s;
    animation-fill-mode: forwards;
  }
  .filter-content.hiddenLayer {
    animation: hiddenLayer 0.5s;
    animation-fill-mode: forwards;
  }
  @media (max-width: 1600px) {
    .modal-container {
      width: 52% !important;
    }
    .filter-contentList {
      padding-bottom: 30px;
    }
    .searchMoreFilter {
      bottom: 20px !important;
      z-index: 99;
    }
  }
}

.simpleBook-box {
  &-label {
    color: #333;
    font-size: 15px;
    line-height: 40px;
    display: flex;
    .Tooltip {
      line-height: 37px;
      .wtdc-icon-wenhao {
        margin-left: 6px !important;
      }
    }
  }
  .wtdc-form-item {
    &-label {
      display: none;
    }
  }
}
