@import "~@/assets/sass/_function";

.DetailList-03Duburr {
  padding-top: 20px;
  .wtdc-table table thead th,
  .wtdc-table table tbody td {
  }

  .td-id {
    min-width: 230px;
  }
  .th:last-child {
    padding-right: 24px !important;
  }
  .table-sync-btn {
    color: #4e50b8;
    cursor: pointer;
  }
  .count-num {
    color: #80798d;
    .detail-btn {
      display: inline-block;
      cursor: pointer;
      color: #4e50b8;
      text-decoration: underline;
    }
  }
}
.warnExport {
  position: relative;
}
