@import "~@/assets/sass/_function";
.chooseBooks {
  position: relative;
  box-sizing: border-box;
  height: calc(100% - 55px);
  .wtdc-antd-table {
    padding-right: 0px;
  }
  .search-group {
    width: 100%;
    .search-wrapper {
      display: flex;
      align-items: flex-start;
    }
    .book-head {
      display: flex;
      align-items: center;
      height: 32px;
      padding-left: 10px;
      border-radius: 6px;
      background-color: rgba(#4e50b8, 0.1);

      .ic-close {
        line-height: 1;
        margin-top: 2px;
      }

      &-item {
        cursor: pointer;
      }

      &--last {
        position: relative;
        margin-left: 12px;
        padding-left: 12px;

        &::before {
          position: absolute;
          content: "";
          width: 1px;
          height: 50%;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          background-color: rgba(#4e50b8, 0.5);
        }
      }
    }

    .selectedSeries-content {
      margin-bottom: 20px;

      .series-title {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          color: #4e50b8;
        }
      }

      .groupName {
        // color: #4e50b8;
        margin-left: 5px;
      }
    }

    .search-wrapper {
      display: flex;

      &-left {
        flex: 1;
      }
      .select-wrapper {
        display: inline-block;
      }
      .search-wrapper-left {
        flex-wrap: wrap;
        .wtdc-form-item {
          margin-bottom: 20px;
        }

        .project-status {
          .status-form {
            display: flex;
            &__content {
              width: 200px;
            }
            &__title {
              width: 70px;
              line-height: 32px;
            }
          }
        }
      }
      .search-right-box {
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        .ManageList-CommonFilter {
          margin-top: 5px;
        }
        .FieldSelect-AfaRN6cc {
          margin-top: 5px;
        }
        .view-port {
          align-items: center;
        }
        .ant-btn {
          min-width: 124px;
        }
      }
    }

    .export-btn {
      background-color: #fff;
      border: 1px solid #ddd;

      &:hover {
        color: #4e50b8;
        background-color: #fff;
        border-color: #4e50b8;
      }
    }
    .ChooseBooks-CommonFilter {
      margin: 0 10px;
    }
  }
  .SearchTable {
    height: calc(100% - 50px);
    margin-top: 20px;
    .dec-wrapper {
      .dec {
        max-height: 63px;
        overflow: hidden;
      }
      .all {
        cursor: pointer;
        color: #4e50b8;
        text-decoration: underline;
      }
    }
    .bookInfo {
      font-size: 15px;
      color: #3a3446;
      .bookName {
        font-weight: bolder;
      }
    }
    .preview {
      cursor: pointer;
      color: #4e50b8;
    }
    .Tooltip {
      position: relative;
    }
    // .Tooltip:after {
    //   content: "";
    //   position: absolute;
    //   display: inline-block;
    //   width: 1px;
    //   height: 16px;
    //   background-color: $primary-color;
    //   right: 0;
    //   top: 0;
    //   bottom: 0;
    //   margin: auto;
    // }
  }
  .next-btn-wrapper {
    position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    .ant-btn {
      min-width: 110px;
    }
  }
}

.more-btn {
  cursor: pointer;
  font-size: 14px;
  color: #666;
  margin-top: 10px;
  &:nth-child(1) {
    margin-top: 0px;
  }
  &:hover {
    color: #3f51b5;
  }
}

.footer-text-wrapper {
  padding-left: 5px;
  font-size: 14px;
  color: #333333;
  display: inline-block;
  .count {
    color: $primary-color;
    font-weight: bolder;
  }
}
