@import "~@/assets/sass/_function";

.ReportState-TGq7G7q {
  .modal-content {
    min-height: 60vh;
  }
  .wtdc-form-item-label,
  .item-label {
    margin-top: 5px;
    margin-right: 20px;
    width: 98px;
    white-space: nowrap;
    font-size: 15px;
    vertical-align: top;
  }
  .item-label {
    align-self: flex-start;
  }
  .state-list {
    display: flex;
    flex-wrap: wrap;
  }
  .state-item {
    margin-right: 20px;
    margin-bottom: 10px;
    padding: 6px 18px;
    background: #ffffff;
    border-radius: 4px;
    line-height: normal;
    border: 1px solid $grayD;
    &--active {
      color: $white;
      border-color: $white;
      background-color: $colorPrimary;
    }
    &:hover {
      color: $white;
      border-color: $white;
      background-color: $colorPrimary;
    }
  }
}
